import React, { Fragment, useState, useEffect, useCallback } from 'react'
import async from 'async'
import { useDropzone } from 'react-dropzone'
import filesize from 'filesize'

// graphql
import gql from 'graphql-tag'
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks'

// images
import shortParagraph from '../../../../images/blankslate/short-paragraph.png'

// semantic-ui
import { Container, Header, Icon, Segment, Dimmer, Loader, Image, Card, Pagination, Form, Input, Placeholder, Button, Progress, List, Dropdown } from 'semantic-ui-react'

// styles
import './index.css'

const MEDIA_QUERY = gql`
query Media($organization: Slug!, $search: String = "", $page: Int = 1) {
  me {
    id
    email
    name
    organization(slug: $organization) {
      id
      slug
      name
      medias(search: $search, limit: 18, page: $page) {
        total_count
        total_pages
        edges {
          node {
            id
            url
            thumbnail_url
            name
            width
            height
            mime
            size
            meta
            duration
            processed
            created_at
            variant {
              default {
                id
                url
              }
              thumbnail {
                id
                url
              }
            }
          }
        }
      }
    }
  }
}`

const CREATE_MEDIA_MUTATION = gql`
mutation CreateMedia($input: CreateMediaInput!) {
  create_media: create_media(input: $input) {
    id
  }
}`

const UPDATE_MEDIA_MUTATION = gql`
mutation UpdateMedia($input: UpdateMediaInput!) {
  update_media: update_media(input: $input) {
    id
  }
}`

const DELETE_MEDIA_MUTATION = gql`
mutation DeleteMedia($input: DeleteMediaInput!) {
  delete_media: delete_media(input: $input) {
    id
  }
}`

const JOB_PROGRESS = gql`
subscription($organization: ID!){
  job_progress(organization: $organization) {
    id
    tick
    ticks
    state
    queue
    payload
    organization_id
  }
}`

function Media (props) {
  // set state
  let [medias, setMedias] = useState(null)
  let [uploads, setUploads] = useState([])
  let [page, setPage] = useState(1)
  let search = ''
  let [mediaThumbPlaying, setMediaThumbPlaying] = useState(null)

  // media query
  const { loading, error, data, refetch } = useQuery(MEDIA_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      organization: props.organization.slug,
      page,
      search,
      mime: props.mime
    }
  })

  // reset page if necessary
  useEffect(() => {
    if (data) {
      setMedias(data.me.organization.medias)
    }

    // handle cases where page is greater than total pages
    if (data && page > 1 && page > data.me.organization.medias.total_pages) {
      setPage(data.me.organization.medias.total_pages)
    }
  }, [data])

  // media create mutation
  const [ createMedia ] = useMutation(CREATE_MEDIA_MUTATION)

  // media update mutation
  const [ updateMedia ] = useMutation(UPDATE_MEDIA_MUTATION)

  // media delete mutation
  const [ deleteMedia ] = useMutation(DELETE_MEDIA_MUTATION)

  // job subscription
  useSubscription(JOB_PROGRESS, {
    variables: { organization: props.organization.id },
    onSubscriptionData: ({ subscriptionData }) => {
      // check if medias loaded
      if (!medias) return
      // accept only image, video and audio queues
      let data = subscriptionData.data.job_progress
      if (['image', 'video', 'audio'].indexOf(data.queue) === -1) return
      // validate payload
      if (!data.payload || !data.payload.media) return
      // find media
      let found = medias.edges.find(edge => {
        return data.payload.media === edge.node.id
      })
      let updated = false
      if (found) {
        updated = true
        found.node.state = data.state
        found.node.tick = data.tick
        found.node.ticks = data.ticks
      }
      if (found && data.state === 'completed') {
        updated = true
        found.node.processed = true
        refetch()
      }
      if (updated) setMedias({ ...medias })
    }
  })

  // upload function (assumes files are already validated)
  const upload = acceptedFiles => {
    let u = [...acceptedFiles, ...uploads]
    setUploads(u)

    async.eachLimit(acceptedFiles, 2, (file, done) => {
      return createMedia({
        variables: {
          input: {
            organization_id: props.organization.id,
            media: file
          }
        }
      }).then(() => {
        u.splice(u.indexOf(file), 1)
        setUploads(u)
        done()
      }).catch(done)
    }, () => {
      refetch()
    })
  }

  // dropzone support
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useCallback(upload, [])
  })

  // mime type to icon
  const mimeIcon = mime => {
    if (mime.indexOf('image/') === 0) {
      return 'image'
    } else if (mime.indexOf('video/') === 0) {
      return 'video'
    } else if (mime.indexOf('audio/') === 0) {
      return 'sound'
    } else {
      return 'question circle'
    }
  }

  return (
    <div {...getRootProps({
      onClick: e => {
        // console.log(e.target, e.currentTarget)
        e.stopPropagation()
      }
    })} className='Media'>
      <Container>
        <Header as='h2'>
          <Icon name='image' />
          <Header.Content>
            Media List
            <Header.Subheader>Drag your media files anywhere on the page to upload</Header.Subheader>
          </Header.Content>
        </Header>

        {/* upload form */}
        <Segment className='upload' basic textAlign='right'>
          Or click to select file(s) &nbsp;
          <Button icon='upload' label={{
              as: 'label',
              htmlFor: 'upload',
              basic: true,
              content: 'Browse'
            }}
            labelPosition='right'
          />
          <input
            id='upload'
            multiple
            hidden
            type='file'
            onChange={({ target: { validity, files } }) => {
              if (!validity.valid) {
                return window.alert('Invalid file(s)')
              } else {
                upload(files)
              }
            }}
          />
        </Segment>

        {/* search form */}
        {medias && (
          <Form className='search' onSubmit={e => refetch({ search })}>
            <Form.Field>
              <Input
                icon='search'
                iconPosition='left'
                name='query'
                action={{ color: 'blue', labelPosition: 'right', icon: 'search', content: 'Search' }}
                defaultValue={search}
                onChange={(e, { target }) => search = e.target.value}
              />
            </Form.Field>
          </Form>
        )}

        {loading && (
          <Segment>
            <div className='blankslate'>
              <Dimmer active inverted>
                <Loader inverted>Loading medias</Loader>
              </Dimmer>

              <Image src={shortParagraph} width='100%' height='66' />
            </div>
            </Segment>
        )}

        {/* uploads */}
        {uploads.length > 0 && (
          <Fragment>
            <Header attached='top'>
              Upload progress
              <Header.Subheader>
                Do not close this tab during upload
              </Header.Subheader>
            </Header>
            <Segment attached='bottom'>
              <List divided relaxed>
                {uploads.map((upload, index) => (
                  <List.Item key={`upload-${index}`}>
                    <List.Icon name={mimeIcon(upload.type)} size='large' verticalAlign='middle' />
                      <List.Content>
                        <List.Header>{upload.name}</List.Header>
                        <List.Description>{filesize(upload.size)}</List.Description>
                      </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>
          </Fragment>
        )}

        {/* no medias (search) */}
        {medias && search && medias.edges.length === 0 && (
          <Segment className='blankslate'>
            <Header as='h4' icon>
              <Icon name='search' />
              No results found
              <Header.Subheader>Your search query did not return any results</Header.Subheader>
            </Header>
          </Segment>
        )}

        {/* no medias (non-search) */}
        {medias && !search && medias.edges.length === 0 && (
          <Segment className='blankslate'>
            <Header as='h4' icon>
              <Icon name='x' />
              No media file(s) found
              <Header.Subheader>Upload a new file to get started</Header.Subheader>
            </Header>
          </Segment>
        )}

        {medias && medias.edges.length > 0 && (
          <Fragment>
            <Card.Group centered>
              {medias.edges.map(edge => {
                if (props.mimeTypes && props.mimeTypes.length > 0) {
                  let found = props.mimeTypes.map(m => edge.node.mime.indexOf(m)).find(m => m === 0)
                  if (!found && found === undefined) return null
                }
                return (
                  <Card
                    as='div'
                    key={`media-${edge.node.id}`}
                    onClick={props.onSelect ? e => {
                      props.onSelect(edge.node)
                    } : undefined}
                  >
                    {!edge.node.processed && (
                      <Placeholder>
                        <Placeholder.Image rectangular />
                        <Progress
                          percent={!edge.node.tick && !edge.node.ticks ? 0 : undefined}
                          value={edge.node.tick || undefined}
                          total={edge.node.ticks || undefined}
                          autoSuccess
                        >
                          {edge.node.tick && edge.node.ticks ? (
                            <span>{edge.node.tick}/{edge.node.ticks}</span>
                          ) : (
                            <span>{edge.node.state || 'pending'}</span>
                          )}
                        </Progress>
                      </Placeholder>
                    )}
                    {edge.node.processed && (
                      <Card.Content
                        extra
                        style={{textAlign: 'center', padding: '0'}}
                        onMouseEnter = {e => {
                          e.stopPropagation()
                          if (edge.node.mime.indexOf('video') > -1) {
                            // show video thumb
                            setMediaThumbPlaying(edge.node.id)
                          }
                        }}
                        onMouseLeave = {e => {
                          e.stopPropagation()
                          if (edge.node.mime.indexOf('video') > -1) {
                            // hide video thumb
                            setMediaThumbPlaying(null)
                          }
                        }}
                      >
                        <Image
                          label={{
                            as: 'a',
                            color: 'red',
                            corner: 'right',
                            icon: 'trash',
                            onMouseEnter: e => {
                              e.stopPropagation()
                            },
                            onClick: e => {
                              e.stopPropagation()
                              if (window.confirm('Are you sure that you want to delete this media item?')) {
                                deleteMedia({
                                  variables: {
                                    input: {
                                      id: edge.node.id
                                    }
                                  }
                                }).then(() => refetch())
                              }
                            }
                          }}
                          src={edge.node.thumbnail_url}
                          wrapped
                          ui={false}
                          className='thumbnail-holder'
                        />
                        {/* video thumbnail */}
                        {(edge.node.mime.indexOf('video') > -1) && mediaThumbPlaying && (mediaThumbPlaying === edge.node.id) && (
                          <video
                            src={edge.node.url + '/preview'}
                            controls={false}
                            autoPlay
                            loop
                            className='thumb-video'
                            muted
                          />
                        )}
                      </Card.Content>
                    )}
                    <Card.Content>
                      <Card.Header title={edge.node.name}>{edge.node.name}</Card.Header>
                      <Card.Meta>{edge.node.mime}</Card.Meta>
                    </Card.Content>
                    {(edge.node.mime.indexOf('video') === 0 || edge.node.mime.indexOf('image') === 0) && (
                      <Card.Content extra>
                        <Icon name='crop' />
                        {edge.node.width}x{edge.node.height}px
                      </Card.Content>
                    )}
                    {(edge.node.mime.indexOf('video') === 0 || edge.node.mime.indexOf('audio') === 0) && (
                      <Card.Content extra>
                        <Icon name='clock' />
                        {Math.round(edge.node.duration / 1000)} seconds
                      </Card.Content>
                    )}
                    {edge.node.processed && (
                      <Card.Content extra>
                        <Icon name='eye' />
                        <Dropdown
                          text='Open in new tab'
                        >
                          <Dropdown.Menu>
                            <Dropdown.Header content='Choose variant' />
                              <Dropdown.Item
                                as='a'
                                onClick={() => {window.open(edge.node.variant.default.url)}}
                              >
                                Optimized (default)
                              </Dropdown.Item>
                              {edge.node.mime.indexOf('audio') < 0 && (
                                <Dropdown.Item
                                  as='a'
                                  onClick={() => {window.open(edge.node.variant.thumbnail.url)}}
                                >
                                  Thumbnail
                                </Dropdown.Item>
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Card.Content>
                    )}
                  </Card>
                )
              })}
            </Card.Group>
            {medias.total_pages > 1 && (
              <div className='pagination'>
                <Pagination
                  onPageChange={(e, { activePage }) => setPage(activePage)}
                  defaultActivePage={page}
                  totalPages={medias.total_pages}
                />
              </div>
            )}
          </Fragment>
        )}
      </Container>
    </div>
  )
}

export default Media
