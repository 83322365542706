import React, { Component } from 'react'
import session from '../../session'

// react router
import { Route, Switch, withRouter } from 'react-router-dom'

// scenes
import Home from '../Home'
import Organization from '../Organization'

// components
import Topbar from './components/Topbar'
import Footer from './components/Footer'
import { NoMatch, NetworkError, BadRequest } from './components/Error'

// apollo client
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from 'apollo-link-ws'
import { setContext } from 'apollo-link-context'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'

// styles
import './index.css'
import { GraphQLError, graphql } from 'graphql'

class App extends Component {
  constructor (args) {
    super(args)

    this.state = {
      error: false
    }

    // get the authentication token from local storage if it exists
    const token = session.get('token')

    // initialize apollo client
    let authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        }
      }
    })

    // graphql server uri
    let uri = 'http://localhost:1337/graphql'
    if (session.get('server')) uri = session.get('server') + '/graphql'

    let uploadLink = createUploadLink({
      uri,
      credentials: 'same-origin'
    })

    // websocket link
    let wsLink = new WebSocketLink({
      uri: uri.replace('http:', 'ws:').replace('https:', 'wss:'),
      options: {
        connectionParams: {
          headers: {
            authorization: token ? `Bearer ${token}` : ""
          }
        },
        reconnect: true
      }
    })

    let errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.find(graphQLError => {
          // console.log(graphQLError.extensions)
          if (graphQLError.extensions && graphQLError.extensions.code === 'UNAUTHENTICATED') {
            session.logout()
            this.props.history.push('/login', { expired: true })
            return true
          } else {
            return false
          }
        })
      }
      if (networkError && networkError.statusCode === 400) {
        this.setState({ error: BadRequest })
      } else if (networkError) {
        this.setState({ error: NetworkError })
      }
    })

    const link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return kind === 'OperationDefinition' && operation === 'subscription'
      },
      wsLink,
      ApolloLink.from([authLink, errorLink, uploadLink])
    )
    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache()
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }
  onRouteChanged() {
    this.setState({ error: false})
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }
  render() {
    let ErrorComponent = this.state.error
    return (
      <ApolloProvider client={this.client}>
        <div className='App'>
          <Topbar />

          {this.state.error ? (
            <ErrorComponent />
          ) : (
            <div className='route'>
              <Switch>
                <Route exact path='/' component={Home}/>
                <Route path='/orgs/:organization' component={Organization} />
                <Route component={NoMatch} />
              </Switch>
            </div>
          )}
          <Footer />
        </div>
      </ApolloProvider>
    )
  }
}

export default withRouter(App)
