import React from 'react'
import ReactDOM from 'react-dom'

import _ from 'lodash'

// styles
import './styles.css'

// semantic-ui
import {
  Container,
  Select
} from 'semantic-ui-react'

const AndOrSwitch = props => {
  // props
  const { handleChangeCondition, position, val } = props

  return (
    <Container className='and-or-container'>
      <Select
        value={val}
        compact
        onChange={(e, {value}) => handleChangeCondition({
          value,
          position
        })}
        options={
          [
            {
              key: 'and',
              value: 'and',
              text: 'AND'
            },
            {
              key: 'or',
              value: 'or',
              text: 'OR'
            }
          ]
        }
      />
    </Container>
  )
}

export default AndOrSwitch
