import React, { useEffect, useState, Fragment } from 'react'
import { NavLink } from 'react-router-dom'

// semantic-ui
import {
  Container,
  Segment,
  Dimmer,
  Loader,
  Image,
  Menu,
  Header,
  List,
  Icon,
  Message
} from 'semantic-ui-react'

// images
import shortParagraph from '../../../../../../images/blankslate/short-paragraph.png'

// styles
import './index.css'

function UserList(props) {
  // organization
  const [org, setOrg] = useState()
  // successfully edited/created
  let [success, setSuccess] = useState(false)
  const [successText, setSuccessText] = useState()
  // auto hide success message
  let autoHideSuccessTimeout
  useEffect(() => {
    if (success) {
      clearTimeout(autoHideSuccessTimeout)
      autoHideSuccessTimeout = setTimeout(() => setSuccess(false), 5 * 1000)
    }
  }, [success])
  // on props
  useEffect(() => {
    if (props.me && props.orgSlug) {
      let found = props.me.organizations.filter(or => {
        return or.slug === props.orgSlug
      })[0]
      setOrg(found)
    }
    setSuccessText(props && props.location && props.location.state && props.location.state.successText)
    setSuccess(props && props.location && props.location.state && props.location.state.created)
  }, [props])

  // rendering

  return (
    <Container className='UserList'>
      {!props.me || !props.orgSlug ? (
        <Segment>
          <div className='blankslate'>
            <Dimmer active inverted>
              <Loader inverted>Loading users</Loader>
            </Dimmer>
            <Image src={shortParagraph} width='100%' height='66' />
          </div>
        </Segment>
      ) : (
        <Fragment>
          <Header as='h2'>
            <Icon name='user' />
            <Header.Content>
              Users
              <Header.Subheader>Manage users &amp; roles</Header.Subheader>
            </Header.Content>
          </Header>
          <Message
            success
            onDismiss={e => setSuccess(false)}
            visible={success ? true : undefined}
            hidden={success ? undefined : true}
          >
            <Message.Header>Success!</Message.Header>
            <p>{successText}</p>
          </Message>

          <Menu attached='top'>
            <Menu.Item>
              Total users: {(org && org.users) ? org.users.length : 0}
            </Menu.Item>
            {props.me.role !== 'viewer' && (
              <Menu.Menu position='right'>
                <Menu.Item as={NavLink} to={`/orgs/${props.orgSlug}/users/create`}>
                  <Icon name='plus' />
                  Create new user
                </Menu.Item>
              </Menu.Menu>
            )}
          </Menu>
          <Segment attached='bottom'>
            {org && org.users && org.users.length > 0 ? (
              <List divided relaxed>
              {org.users.map((usr, i) => (
                <List.Item key={i}>
                  <Image avatar src={'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=' + usr.name} />
                  <List.Content>
                    <List.Header as={NavLink} to={`/orgs/${props.orgSlug}/users/${usr.id}`}>
                      <strong>{`${usr.name} `}</strong> {`(${usr.role})`}
                    </List.Header>
                    <List.Description>{`${usr.email}`}</List.Description>
                  </List.Content>
                </List.Item>
              ))}
              </List>
            ):(
              <p>No users yet for this organization.</p>
            )}
          </Segment>
        </Fragment>
      )}
    </Container>
  )
}

export default UserList
