import React, { Component } from 'react'
import session from '../../session'

// react router
import { withRouter } from 'react-router-dom'

// styles
import './index.css'

class Logout extends Component {
  componentDidMount () {
    session.logout()
    this.props.history.push('/login')
  }
  render() {
    return (
      <div className='Logout'>Logging you out</div>
    )
  }
}

export default withRouter(Logout)
