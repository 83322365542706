import React from 'react'
import ReactDOM from 'react-dom'

// components
import Group from '../Group'

const BuildControler = props => {
  const {
    jsonData,
    queryFields,
    onChange,
    onDelete,
    onConditionChange,
    addGroup,
    addRule,
    groupsLevel
  } = props

  return (
    <>
      {jsonData && (
        <Group
          queryLevel={jsonData}
          position={null}
          queryFields={queryFields}
          onChange={onChange}
          handleDelete={onDelete}
          handleChangeCondition={onConditionChange}
          addRule={addRule}
          addGroup={addGroup}
          groupsLevel={groupsLevel}
        />
      )}
    </>
  )
}

export default BuildControler
