import React, { Fragment, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Moment from 'react-moment'
import { orgRoute } from '../../../../../../utils'

// map
import LocationMap from '../../../../components/LocationMap'

// graphql
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

// error
import { NoMatch } from '../../../../../App/components/Error/index'

// semantic-ui
import { Header, Icon, List, Label, Segment, Menu, Dropdown } from 'semantic-ui-react'

// styles
import './index.css'

const platformIconDict = {
  win32: 'windows',
  linux: 'linux',
  darwin: 'apple'
}

const UPDATE_LOCATION = gql`
mutation UpdateLocation($input: UpdateLocationInput!) {
  update_location: update_location(input: $input) {
    id
  }
}`

const DELETE_LOCATION = gql`
mutation DeleteLocation($input: DeleteLocationInput!) {
  delete_location: delete_location(input: $input) {
    id
  }
}`

export default function LocationDetail (props) {
  let [me, setMe] = useState(props.me)

  let location = me.organization.locations.find(location => {
    return location.slug === props.match.params.location
  })

  // location map reference
  let map

  // listen me changes
  useEffect(() => {
    setMe(props.me)
  }, [props.me])

  // mutations
  const [updateLocation] = useMutation(UPDATE_LOCATION)
  const [deleteLocation] = useMutation(DELETE_LOCATION)

  if (!location) {
    return (
      <NoMatch />
    )
  }

  return (
    <div className='LocationDetail'>
      <Header as='h2'>
        <Icon name='map' />
        <Header.Content>
          {location.name}
          <Header.Subheader>
            Created <Moment fromNow ago>{location.created_at}</Moment> ago
          </Header.Subheader>
        </Header.Content>
      </Header>

      <Menu>
        <Dropdown item text={location.name}>
          <Dropdown.Menu style={{ minWidth: '200px' }}>
            <Dropdown.Header>Locations</Dropdown.Header>
            {me.organization.locations.map(l => (
              <Dropdown.Item
                key={l.id}
                active={l.id === location.id}
                onClick={() => {
                  let route = orgRoute(me.organization, [
                    'locations',
                    l.slug
                  ])
                  props.history.push(route)
                }}
              >
                {l.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Menu position='right'>
          <Menu.Item icon onClick={async e => {
            if (window.confirm('Are you sure you want to delete this location?')) {
              await deleteLocation({
                variables: {
                  input: {
                    id: location.id
                  }
                }
              })

              let path = orgRoute(props.me.organization, ['locations'])
              props.history.push(path)
            }
          }}>
            <Icon name='trash' />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      {/* device list */}
      {location.devices.length ? (
        <Fragment>
          <Header attached='top'>
            Device list
          </Header>
          <Segment attached='bottom'>
            <List divided relaxed>
              {location.devices.map((device, index) => (
                <List.Item key={'row-' + index}>
                  <List.Content floated='right'>
                    {device.online ? <Label color='green' horizontal>Online</Label> : <Label color='red' horizontal>Offline</Label>}
                  </List.Content>
                  {device.specs.platform && platformIconDict[device.specs.platform] ? (
                    <List.Icon name={platformIconDict[device.specs.platform]} />
                  ) : (
                    <List.Icon name='question' />
                  )}
                  <List.Content>
                    <List.Header as={NavLink} to={orgRoute(props.me.organization, [
                      'locations',
                      location.slug,
                      'devices',
                      device.id
                    ])}>
                      {device.name || ('#'+device.id.slice(0, 7))}
                    </List.Header>
                    <List.Description as='a'>Joined <Moment fromNow ago>{device.created_at}</Moment> ago</List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Fragment>
      ) : (
        <Segment className='blankslate'>
          <Header as='h4' icon>
            <Icon name='x' />
            No devices found!
            <Header.Subheader>Your devices will appear here after initial handshake.</Header.Subheader>
          </Header>
        </Segment>
      )}

      <Segment>
        <LocationMap location={location} />
      </Segment>
    </div>
  )
}
