import React, { useState, useEffect } from 'react'

// graphql
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

// scenes
import Media from '../../scenes/Media'

// semantic-ui
import { Icon, Input, Image, Button, Label, Modal } from 'semantic-ui-react'

// images
import whiteImage from '../../../../images/blankslate/white-image.png'

// styles
import './index.css'

// graphql queries
const QUERY = gql`
query Me($slug: Slug!, $media: ID!) {
  me {
    id
    email
    name
    organization(slug: $slug) {
      id
      slug
      name
      media (id: $media) {
        id
        name
        url
        thumbnail_url
        created_at
      }
    }
  }
}`

function MediaInput (props) {
  let [modal, setModal] = useState(false)
  let [media, setMedia] = useState(null)

  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      slug: props.organization.slug,
      media: props.value
    },
    skip: !props.value
  })

  // listen data change
  useEffect(() => {
    setMedia(data && data.me ? { ...data.me.organization.media } : null)
  }, [data])

  return (
    <div className='MediaInput'>
      <div className='Preview'>
        <Image
          onClick={e => setModal(true)}
          bordered
          src={media ? media.thumbnail_url : whiteImage}
        />
      </div>
      <div className='Form'>
        <small>Click on the thumbnail or use the button to select a media file.</small>
        {/* <input required className='validation' value={media ? media.id : ''} /> */}
        <Input
          required={props.required}
          readOnly={!props.required}
          fluid
          onClick={e => setModal(true)}
          value={media ? media.name : ''}
          iconPosition='left'
          labelPosition={props.required ? undefined : 'right'}
          placeholder='Click to choose a media file'
        >
          <Icon name='image' />
          <input />
          {media && !props.required && (
            <Label icon onClick={e => {
              setMedia(null)
              if (props.onChange) props.onChange(null)
            }}>
              <Icon fitted name='delete' />
            </Label>
          )}
        </Input>
        {props.required && (
          <small>This field is required, you must make a selection before proceeding.</small>
        )}
        <Button onClick={e => setModal(true)} type='button'>Select media</Button>
      </div>

      <Modal
        size='fullscreen'
        closeIcon
        open={modal}
        onClose={e => setModal(false)}
      >
        <Modal.Content>
          <Media
            mime={props.mime}
            organization={props.organization}
            onSelect={media => {
              setModal(false)
              setMedia({ ...media })
              if (props.onChange) props.onChange(media.id)
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default MediaInput
