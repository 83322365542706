import _ from 'lodash'
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import Moment from 'react-moment'
import { orgRoute } from '../../../../../../utils'

// components
import MediaInput from '../../../../components/MediaInput'
import MmWaveMap from './components/MmWaveMap'
import MediaThumb from '../../../../components/MediaThumb'

// graphql
import gql from 'graphql-tag'
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks'

// error
import { NoMatch } from '../../../../../App/components/Error/index'

// images
import shortParagraph from '../../../../../../images/blankslate/short-paragraph.png'

// semantic-ui
import { Header, Icon, List, Grid, Card, Label, Input, Segment, Form, Dropdown, Button, Dimmer, Loader, Image, Message, Checkbox, Menu } from 'semantic-ui-react'

// styles
import './index.css'

const QUERY = gql`
query Me($slug: Slug!, $device: ID!) {
  me {
    id
    email
    name
    organization(slug: $slug) {
      id
      slug
      name
      device(id: $device) {
        id
        online
        name
        specs
        modules
        config
        location_id
        connections {
          id
          ip
          created_at
          updated_at
          destroyed_at
        }
        meta
        created_at
      }
    }
  }
}`

const UPDATE_DEVICE = gql`
mutation UpdateDevice($input: UpdateDeviceInput!) {
  update_device: update_device(input: $input) {
    id
  }
}`

const DEVICE_UPDATE_SUBSCRIPTION = gql`
subscription DeviceUpdate($device: ID!) {
  device_update(device: $device)
}`

const DEVICE_ONLINE_SUBSCRIPTION = gql`
subscription DeviceOnline($device: ID) {
  device_online(device: $device) {
    online
  }
}`

const DEVICE_NOW_PLAYING_SUBSCRIPTION = gql`
subscription DeviceNowPlaying($device: ID!) {
  device_now_playing(device: $device)
}`

function DeviceDetail (props) {
  // find device
  let findDevice = me => {
    let found
    me.organization.locations.find(location => {
      return !!location.devices.find(d => {
        if (d.id === props.match.params.device) {
          found = d
          // ensure config values
          found.config = found.config || {}
          found.config.mmwave = found.config.mmwave || {}
          return true
        } else {
          return false
        }
      })
    })
    return found
  }

  useEffect(() => {
    if (props.me) {
      // me changed
      let dev = findDevice(props.me)
      // prevent no audio info for display devices
      if (dev.modules && dev.modules.display && dev.config && !dev.config.audio) {
        dev.config = { ...dev.config, audio: { list: [], enabled: false } }
      }
      setDevice({ ...dev })
      // set audio tracks
      if (dev.modules && dev.modules.display) {
        let medias = props.me.organization.medias.edges
        medias = medias.map(media => media.node)
        let audios = medias.filter(med => med.mime.indexOf('audio') > -1)
        setAudios([ ...audios ])
        // filter out tracks already on the list
        let tracks = audios.filter(track => {
          let found = false
          dev.config.audio.list.forEach(listItem => {
            if (listItem === track.id) found = true
          })
          return !found
        })
        setMaxAudioExceded(dev.config.audio.list.length >= maxAudios)
        setAvailableTracks(tracks)
      }
    }
  }, [props.me])

  // set options for dropdowns
  const setScOptions = me => {
    let schedules = me.organization.schedules
    return schedules.map(sc => {
      let opt = {
        key: sc.id,
        text: sc.name,
        value: sc.id
      }
      return opt
    })
  }
  // set selected media from props
  const setSelMediaFromProps = me => {
    let dev = findDevice(me)
    let mediaObj = {}
    if (dev.schedule_id) {
      let id = dev.schedule_id
      let schedules = setScOptions(me)
      let name = schedules.find(sc => sc.key === id).text
      mediaObj = { schedule: { id, name } }
    }
    return mediaObj
  }

  // state
  let [device, setDevice] = useState(findDevice(props.me))
  let [success, setSuccess] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState(setSelMediaFromProps(props.me) || {})
  const [audios, setAudios] = useState([])
  const [availableTracks, setAvailableTracks] = useState([])
  const [maxAudioExceded, setMaxAudioExceded] = useState(false)
  const maxAudios = 25
  const [onlineSub, setOnlineSub] = useState()
  const [nowPlayingSub, setNowPlayingSub] = useState()

  // dropdown options for display media select
  const schedulesOptions = setScOptions(props.me) || []

  // auto hide success message
  let autoHideSuccessTimeout
  useEffect(() => {
    if (success) {
      clearTimeout(autoHideSuccessTimeout)
      autoHideSuccessTimeout = setTimeout(() => setSuccess(false), 5 * 1000)
    }
  }, [success])

  // queries
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {
      slug: props.match.params.organization,
      device: props.match.params.device
    }
  })

  // device update subscription
  useSubscription(DEVICE_UPDATE_SUBSCRIPTION, {
    variables: { device: props.match.params.device },
    onSubscriptionData: ({ subscriptionData }) => {
      let diff = subscriptionData.data.device_update

      let merged = _.merge({}, device, diff)
      setDevice({ ...merged })
    }
  })

  // device online update subscription
  useSubscription(DEVICE_ONLINE_SUBSCRIPTION, {
    variables: { device: props.match.params.device },
    onSubscriptionData: ({ subscriptionData }) => {
      let subOnline = subscriptionData.data.device_online.online
      setOnlineSub(subOnline)
    }
  })

  // device now playing subscription
  useSubscription(DEVICE_NOW_PLAYING_SUBSCRIPTION, {
    variables: { device: props.match.params.device },
    onSubscriptionData: ({ subscriptionData }) => {
      let nowPlaying = subscriptionData.data.device_now_playing
      setNowPlayingSub(nowPlaying)
    }
  })

  // mutations
  const [updateDevice] = useMutation(UPDATE_DEVICE)

  // reference to canvas container width
  const [canvContWidth, setCanvContWidth] = useState()

  const canvasContainer = useCallback(node => {
    if (node !== null) {
      setCanvContWidth(node.offsetWidth)
    }
  })

  // reference to frames
  const [frames, setFrames] = useState()

  if (!loading && !device) {
    return (
      <NoMatch />
    )
  }

  if (loading) {
    return (
      <div className='DeviceDetail'>
        <Segment className='blankslate'>
          <Dimmer active inverted>
            <Loader inverted>Loading data</Loader>
          </Dimmer>

          <Image src={shortParagraph} width='100%' height='66' />
        </Segment>
      </div>
    )
  }

  return (
    <div className='DeviceDetail'>
      <Header as='h2'>
        <Icon name='computer' />
        <Header.Content>
          {device.name}
          <Header.Subheader>Configure and manage your device</Header.Subheader>
        </Header.Content>
      </Header>

      <Message
        success
        onDismiss={e => setSuccess(false)}
        visible={success ? true : undefined}
        hidden={success ? undefined : true}
      >
        <Message.Header>Success!</Message.Header>
        <p>
          Changes successfully saved!
        </p>
      </Message>

      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={6} computer={5}>
            <Card fluid>
              <MediaThumb
                modules={device.modules}
                online={device.online}
                medias={props.me.organization.medias.edges}
                subscription_online={onlineSub}
                subscription_now_playing={nowPlayingSub}
              />
              <Card.Content>
                <Card.Header>
                  {device.name || device.id.slice(-7)}
                </Card.Header>
                <Card.Meta>
                  <span className='date'>
                    Joined <Moment fromNow ago>{device.created_at}</Moment>ago
                    {device.online ? (
                      <Label color='green' size='tiny'>ONLINE</Label>
                    ) : (
                      <Label color='red' size='tiny'>OFFLINE</Label>
                    ) }
                  </span>
                </Card.Meta>
                {!device.online && (
                  <Card.Description>This device seems to be <strong>offline</strong>. You will have a limited set of options enabled.</Card.Description>
                )}
              </Card.Content>
              <Card.Content extra>
                <Icon name='camera' />
                Camera
                {device.modules.camera ? (
                  <Label circular color='green'>✓</Label>
                ) : (
                  <Label circular color='yellow'>x</Label>
                ) }
              </Card.Content>
              <Card.Content extra>
                <Icon name='wifi' />
                ESP8266
                {device.modules.esp8266 ? (
                  <Label circular color='green'>✓</Label>
                ) : (
                  <Label circular color='yellow'>x</Label>
                ) }
              </Card.Content>
              <Card.Content extra>
                <Icon name='rss' />
                Radar
                {(device.modules.mmwave && Object.keys(device.modules.mmwave).length > 0) ? (
                  <Label circular color='green'>✓</Label>
                ) : (
                  <Label circular color='yellow'>x</Label>
                ) }
              </Card.Content>
              <Card.Content extra>
                <Icon name='tv' />
                Display
                {device.modules.display ? (
                  <Label circular color='green'>✓</Label>
                ) : (
                  <Label circular color='yellow'>x</Label>
                ) }
              </Card.Content>
              <Card.Content extra>
                <Icon name='info circle' />
                Platform &amp; Arch
                <Label color='grey'>{device.specs.platform} - {device.specs.arch}</Label>
              </Card.Content>
              {device.meta.publicIp && (
                <Card.Content extra>
                  <Icon name='globe' />
                  {device.online ? (
                    'Public IP'
                  ) : (
                    'Last public IP'
                  ) }
                  <Label color='grey'>{device.meta.publicIp}</Label>
                </Card.Content>
              )}
            </Card>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={11}>
            <Form onSubmit={async e => {
              // check if location updated
              let idToSlug = {}
              let locationUpdated = false
              props.me.organization.locations.forEach(location => {
                idToSlug[location.id] = location.slug
                return !!location.devices.find(({ id, location_id }) => {
                  if (props.match.params.device === id && location_id !== location.id) {
                    locationUpdated = true
                    return true
                  } else {
                    return false
                  }
                })
              })

              // check mmwave config
              if (device.config.mmwave) {
                device.config.mmwave.mode = device.config.mmwave.mode || null
              }

              await updateDevice({
                variables: {
                  input: {
                    id: device.id,
                    name: device.name,
                    location_id: device.location_id,
                    config: device.config,
                    playlist_id: device.playlist_id,
                    schedule_id: device.schedule_id
                  }
                }
              })

              setSuccess(true)

              if (locationUpdated) {
                let path = orgRoute(props.me.organization, [
                  'locations',
                  idToSlug[device.location_id],
                  'devices',
                  device.id
                ])
                props.history.push(path)
              }
            }}>
              <Header attached='top'>
                Basic information
              </Header>
              <Segment attached='bottom'>
                <Form.Field>
                  <Form.Input
                    label='Name'
                    placeholder='Device name'
                    value={device.name}
                    onChange={(e, target) => {
                      device.name = target.value
                      setDevice({ ...device })
                    }}
                    required
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Location</label>
                  <Dropdown
                    required
                    selection
                    value={device.location_id}
                    onChange={(e, target) => {
                      device.location_id = target.value
                      setDevice({ ...device })
                    }}
                    options={props.me.organization.locations.map(location => ({
                      key: location.id,
                      text: location.name,
                      value: location.id,
                      image: { avatar: true, src: `https://ui-avatars.com/api/?background=fbbd08&color=fff&name=${location.name}` },
                    }))}
                  />
                </Form.Field>
                <Form.Field>
                  <Button primary type='submit'>Save</Button>
                </Form.Field>
              </Segment>

              {device.modules.mmwave && (
                <Fragment>
                  <Header attached='top'>
                    Radar configuration
                  </Header>
                  <Segment attached='bottom'>
                    <Form.Field>
                      <label>Operation mode</label>
                      <Dropdown
                        selection
                        clearable
                        placeholder='Disabled'
                        value={device.config.mmwave.mode}
                        onChange={(e, target) => {
                          device.config.mmwave.mode = target.value || null
                          setDevice({ ...device })
                        }}
                        options={[{
                          key: 'saturation',
                          text: 'Saturation',
                          value: 'saturation'
                        }, {
                          key: 'proximity',
                          text: 'Proximity',
                          value: 'proximity'
                        }]}
                      />
                    </Form.Field>
                    {device.config.mmwave.mode && (
                      <Form.Field>
                        <label>Media type</label>
                        <Checkbox label='Audio only' checked={device.config.mmwave.audioOnly || false} onChange={(e, target) => {
                            target = target || e.target
                            device.config.mmwave.audioOnly = target.checked
                            setDevice({ ...device })
                          }} />
                      </Form.Field>
                    )}
                    {device.config.mmwave.mode === 'saturation' && (
                      <Fragment>
                        <Form.Field required>
                          <label>Saturation limit</label>
                          <Input
                            required
                            type='number'
                            min={1}
                            max={50}
                            placeholder='Set maximum number of people allowed'
                            value={device.config.mmwave.saturationLimit || ''}
                            onChange={(e, target) => {
                              target = target || e.target
                              device.config.mmwave.saturationLimit = parseInt(target.value)
                              setDevice({ ...device })
                            }}
                          />
                          <small>Set maximum number of people allowed.</small>
                        </Form.Field>
                      </Fragment>
                    )}
                    {device.config.mmwave.mode === 'proximity' && (
                      <Fragment>
                        <Form.Field required>
                          <label>Distance threshold</label>
                          <Input
                            required
                            type='number'
                            step={0.1}
                            min={1}
                            max={4}
                            label='meters'
                            labelPosition='right'
                            placeholder='Distance threshold'
                            value={device.config.mmwave.proximityDistance || 5}
                            onChange={(e, target) => {
                              target = target || e.target
                              device.config.mmwave.proximityDistance = parseFloat(target.value)
                              setDevice({ ...device })
                            }}
                          />
                          <small>Set minimum threshold for social distance (in meters).</small>
                        </Form.Field>
                      </Fragment>
                    )}
                    {!!device.config.mmwave.mode && (
                      <Fragment>
                        <Form.Field required>
                          <label>Warning throttle</label>
                          <Input
                            required
                            type='number'
                            min={5}
                            max={3600}
                            label='seconds'
                            labelPosition='right'
                            placeholder='Warning throttle'
                            value={device.config.mmwave.warningThrottle || 60}
                            onChange={(e, target) => {
                              target = target || e.target
                              device.config.mmwave.warningThrottle = parseInt(target.value)
                              setDevice({ ...device })
                            }}
                          />
                          <small>Time interval between warnings (in seconds).</small>
                        </Form.Field>
                        <Form.Field required>
                          <label>Consecutive warnings</label>
                          <Input
                            required
                            type='number'
                            min={5}
                            max={500}
                            label='frames'
                            labelPosition='right'
                            placeholder='Consecutive warnings'
                            value={device.config.mmwave.consecutiveWarnings || 100}
                            onChange={(e, target) => {
                              target = target || e.target
                              device.config.mmwave.consecutiveWarnings = parseInt(target.value)
                              setDevice({ ...device })
                            }}
                          />
                          <small>Number of consecutive warnings before triggering alarm (measured in frames). On average there are <strong>20</strong> frames per second. To make a practical example: a value of <strong>100</strong> would be equivalent to roughly <strong>5</strong> seconds (100 / 20).</small>
                        </Form.Field>
                        <Form.Field required>
                          <label>Warning media</label>
                          <MediaInput
                            mime={device.config.mmwave.audioOnly ? 'audio/' : 'video/'}
                            organization={props.me.organization}
                            value={device.config.mmwave.warningMedia || ''}
                            onChange={val => {
                              device.config.mmwave.warningMedia = val
                              setDevice({ ...device })
                            }}
                            required
                          />
                        </Form.Field>
                        {!device.config.mmwave.audioOnly && (
                          <Form.Field required>
                            <label>Digital signage media</label>
                            <MediaInput
                              type='video/'
                              organization={props.me.organization}
                              value={device.config.mmwave.dsMedia || ''}
                              onChange={val => {
                                device.config.mmwave.dsMedia = val
                                setDevice({ ...device })
                              }}
                              required
                            />
                          </Form.Field>
                        )}
                      </Fragment>
                    )}
                    <Form.Field>
                      <Button primary type='submit'>Save</Button>
                    </Form.Field>
                  </Segment>
                </Fragment>
              )}

              {device.modules.mmwave && (
                <Fragment>
                  <Header attached='top'>
                    Radar advanced configuration #{device.modules.mmwave}
                  </Header>
                  <Segment attached='bottom'>
                    <Form.Field>
                      <Form.Input
                        label='Range bias &amp; RX antenna gain/phase offset'
                        placeholder='Device name'
                        value={device.config.mmwave.compRangeBiasAndRxChanPhase || '0.0 1 0 1 0 1 0 1 0 1 0 1 0 1 0 1 0 1 0 1 0 1 0 1 0'}
                        onChange={(e, target) => {
                          device.config.mmwave.compRangeBiasAndRxChanPhase = target.value
                          setDevice({ ...device })
                        }}
                        required
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label='Sensor height'
                        placeholder='Device name'
                        value={device.config.mmwave.height || 2.5}
                        onChange={(e, target) => {
                          device.config.mmwave.height = target.value
                          setDevice({ ...device })
                        }}
                        required
                      />
                    </Form.Field>
                    <Form.Field>
                      <Button primary type='submit'>Save</Button>
                    </Form.Field>
                  </Segment>
                </Fragment>
              )}

              {/* display */}
              {device.modules.display && (
                <Fragment>
                  {/* schedule select */}
                  <Fragment>
                    <Header attached='top'>
                      Display media
                    </Header>
                    <Segment attached='bottom'>
                      <Form.Field>
                        <label>Schedule</label>
                        <Dropdown
                          selection
                          search
                          clearable
                          placeholder='Select schedule'
                          defaultValue={(selectedMedia.schedule && selectedMedia.schedule.id) || null}
                          options={schedulesOptions}
                          onChange={(e, target) => {
                            let sc = schedulesOptions.find(s => s.value === target.value)
                            if (sc && sc.text && sc.text !== '') {
                              // found / not annuled
                              let name = sc.text
                              let schedule = {
                                id: target.value,
                                name
                              }
                              setSelectedMedia({ schedule })
                              device.schedule_id = schedule.id
                            } else {
                              // annuled
                              setSelectedMedia({})
                              device.schedule_id = null
                            }
                            setDevice({ ...device })
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Button primary type='submit'>Save</Button>
                      </Form.Field>
                    </Segment>
                  </Fragment>
                  {/* create audio list */}
                  <Fragment>
                    <Header attached='top'>
                      Play audio
                    </Header>
                    <Segment attached='bottom'>
                      <Form.Field>
                        <label>Audio playlist</label>
                        <Checkbox
                          label={device.config.audio.enabled ? 'on' : 'off'}
                          value={device.config.audio.enabled ? 'on' : 'off'}
                          checked={device.config.audio.enabled}
                          toggle
                          style={{marginBottom: '5px'}}
                          onChange={(e, {value}) => {
                            let dev = _.cloneDeep(device)
                            if (value === 'off') {
                              dev.config.audio.enabled = true
                            } else {
                              dev.config.audio.enabled = false
                            }
                            setDevice({ ...dev })
                          }}
                        />
                        <List relaxed divided selection verticalAlign='middle' style={{maxHeight: '150px', overflowY: 'scroll'}}>
                          {device.config.audio.list.map(plItem => (
                            <List.Item
                              key={plItem}
                              title='Remove'
                              value={plItem}
                              onClick={(e, {value}) => {
                                // remove track
                                let dev = _.cloneDeep(device)
                                dev.config.audio.list = dev.config.audio.list.filter(track => track !== value)
                                // set maximum tracks
                                setMaxAudioExceded(dev.config.audio.list.length >= maxAudios)
                                setDevice({ ...dev })
                                // add track to available list
                                //find name !!!
                                let name = audios.find(a => a.id === plItem).name
                                setAvailableTracks([ ...availableTracks, { id: plItem, name } ])
                              }}
                            >
                              { (audios && audios.find(a => a.id === plItem) && audios.find(a => a.id === plItem).name) ?
                                audios.find(a => a.id === plItem).name
                                :  <p style={{color: 'red'}}>Track removed!</p>
                              }
                            </List.Item>
                          ))}
                        </List>
                        <Dropdown
                          text={maxAudioExceded ? 'Maximum Number of Tracks Reached' : 'Add a Track to Audio Playlist'}
                          floating
                          labeled
                          button
                          className='icon'
                        >
                          {!maxAudioExceded ? (
                          <Dropdown.Menu style={{zIndex: '99999'}}>
                            <Dropdown.Header content='Available tracks' />
                            {availableTracks && availableTracks.map(track => (
                              <Dropdown.Item
                                key={track.id}
                                data={{id: track.id, name: track.name}}
                                onClick={(e, {data}) => {
                                  e.preventDefault()
                                  // add track to list
                                  let dev = _.cloneDeep(device)
                                  dev.config.audio.list.push(data.id)
                                  setDevice({ ...dev })
                                  // set maximum tracks
                                  setMaxAudioExceded(dev.config.audio.list.length >= maxAudios)
                                  // remove from available list
                                  let avTracks = _.cloneDeep(availableTracks)
                                  avTracks = avTracks.filter(tr => tr.id !== track.id)
                                  setAvailableTracks([ ...avTracks ])
                                }}
                              >
                                {track.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>)
                          : null
                          }
                        </Dropdown>
                      </Form.Field>
                      <Form.Field>
                        <Button primary type='submit'>Save</Button>
                      </Form.Field>
                    </Segment>
                  </Fragment>
                </Fragment>
              )}
            </Form>

            {/* device mmwave map */}
            {device.modules.mmwave && device.online &&
              (<Fragment>
                <Menu attached='top' className='topfix'>
                  <Menu.Item>Radar in real-time</Menu.Item>
                  <Menu.Item position='right'>frames: {frames}</Menu.Item>
                </Menu>
                <Segment attached='bottom'>
                  <div style={{width: '100%'}} ref={canvasContainer}>
                    <MmWaveMap device={device.id} size={canvContWidth} getFrames={setFrames} />
                  </div>
                </Segment>
              </Fragment>
            )}

            {/* device connections */}
            {(device.connections || []).length > 0 && (
              <Fragment>
                <Header attached='top'>Last connections</Header>
                <Segment attached='bottom'>
                  <List divided relaxed>
                    {device.connections.slice(0, 10).map(connection => (
                      <List.Item key={'connection-' + connection.id}>
                        <List.Content floated='right'>
                        <Label color={connection.destroyed_at ? 'red' : 'green'} horizontal>
                          {connection.destroyed_at ? 'disconnected' : 'active'}
                        </Label>
                        </List.Content>
                        {/* <Image avatar src={'https://ui-avatars.com/api/?background=fbbd08&color=fff&name=' + location.name} /> */}
                        <List.Content>
                          <List.Header>
                            {connection.ip}
                          </List.Header>
                          <List.Description as='a'>Created <Moment fromNow ago>{connection.created_at}</Moment> ago</List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Segment>
              </Fragment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default DeviceDetail
