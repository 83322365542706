import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// graphql
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

// images
import shortParagraph from '../../images/blankslate/short-paragraph.png'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

// styles
import './index.css'

class Home extends Component {
  componentWillUpdate () {
    if (!this.props.data.me) return

    if (this.props.data.me.organizations.length === 1) {}
  }
  render() {
    if (!this.props.data.loading && this.props.data.me) {
      return (
        <Semantic.Container className='Home'>
          <Semantic.Header as='h4' attached='top'>
            Your Organizations
          </Semantic.Header>
          <Semantic.Segment attached='bottom'>
          <Semantic.List divided verticalAlign='middle'>
            {this.props.data.me.organizations.map(organization => (
              <Semantic.List.Item key={'organization-'+organization.id}>
                <Semantic.List.Content floated='right'>
                  <Semantic.Label horizontal>
                    #{organization.id.slice(-7)}
                  </Semantic.Label>
                </Semantic.List.Content>
                <Semantic.Image avatar src={'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=' + organization.name} />
                <Semantic.List.Content>
                  <Semantic.List.Header as={Link} to={'/orgs/'+organization.slug}>{organization.name}</Semantic.List.Header>
                  <Semantic.List.Description>{organization.locations.length} locations and {organization.locations.length ? organization.locations.map(location => location.devices.length).reduce((a, b) => a + b) : 0} devices</Semantic.List.Description>
                </Semantic.List.Content>
              </Semantic.List.Item>
            ))}
          </Semantic.List>
          </Semantic.Segment>
        </Semantic.Container>
      )
    } else {
      return (
        <Semantic.Container className='Home'>
          <Semantic.Segment>
            <div className='blankslate'>
              <Semantic.Dimmer active inverted>
                <Semantic.Loader inverted>Loading organizations</Semantic.Loader>
              </Semantic.Dimmer>

              <Semantic.Image src={shortParagraph} width='100%' height='66' />
            </div>
          </Semantic.Segment>
        </Semantic.Container>
      )
    }
  }
}

// with data component
const HomeWithData = graphql(gql`query Me {
  me {
    id
    email
    name
    organizations {
      id
      slug
      name
      locations {
        id
        devices {
          id
        }
      }
    }
  }
}`, {
  options: {
    notifyOnNetworkStatusChange: true,
    variables: {}
  }
})(Home)

export default HomeWithData
