import React, { useState, useEffect } from 'react'
import _ from 'lodash'

// semantic-ui
import { Image, Label } from 'semantic-ui-react'

// videos
import noSignal from './videos/static.mp4'

// images
import onlineImg from './images/online.jpg'

// // subscriptions
// const DEVICE_ONLINE_SUBSCRIPTION = gql`
// subscription DeviceOnline($location: ID) {
//   device_online(location: $location) {
//     device_id
//     location_id
//     organization_id
//     online
//   }
// }`

// const DEVICE_NOW_PLAYING_SUBSCRIPTION = gql`
// subscription DeviceNowPlaying($device: ID!) {
//   device_now_playing(device: $device)
// }`

const MediaThumb = props => {
  //props
  let {
    online,
    modules,
    medias,
    subscription_online,
    subscription_now_playing
  } = props

  // states
  const [isOnline, setIsOnline] = useState(online)
  const [isDisplay, setIsDisplay] = useState((modules && modules.display && modules.display.width) ? true : false)
  const [media, setMedia] = useState(null)
  const [mediaChanged, setMediaChanged] = useState(null)
  const [mediaURL, setMediaURL] = useState(null)

  // subscription device online prop
  useEffect(() => {
    if (subscription_online || subscription_online === false) {
      setIsOnline(subscription_online)
    }
  }, [subscription_online])

  // subscription device now playing prop
  useEffect(() => {
    if (subscription_now_playing) setMediaChanged(subscription_now_playing)
  }, [subscription_now_playing])

  // handle media change
  useEffect(() => {
    if (media && media.lastPlayed && mediaChanged && mediaChanged.media && mediaChanged.media === media.lastPlayed) {
      // do nothing (playing the same)
    } else {
      if (mediaChanged && mediaChanged.media) {
        // media changed
        let updatedMedia = {
          id: mediaChanged.media,
          lastPlayed: mediaChanged.media
        }
        if (mediaChanged.position || mediaChanged.position === 0) {
          updatedMedia.position = mediaChanged.position
        }
        setMedia({ ...updatedMedia })
      } else {
        // stoped playing
        setMedia(null)
      }
    }
  }, [mediaChanged])

  // obtain URL from medias id
  useEffect(() => {
    let allMedias = medias.map(med => med.node)
    let thisMedia = allMedias.find(med => media && media.id ? med.id === media.id : false)
    setMediaURL(thisMedia ? thisMedia.url : null)
  }, [media])

  return (
    <>
      {isOnline ?
      (!isDisplay || (isDisplay && !media)) ? (
        // no media to display (device online image)
        <Image src={onlineImg} className='media-thumbs-image' />)
        : (media && media.position !== null && media.position !== undefined) ? (
          // playing video
          <video
            src={mediaURL + '/thumbnail-video#t=' + media.position}
            autoPlay
            muted
            loop
            controls={false}
            poster={onlineImg}
            className='media-thumbs-video'
          />
        )
        : (
          // playing image
          <Image src={mediaURL + '/thumbnail'} className='media-thumbs-image'/>
          )
      : (
        // offline
        <div className='ui media'>
          <video src={noSignal} autoPlay muted loop controls={false} />
          <div className='centered'>
            <Label basic>NO SIGNAL</Label>
          </div>
        </div>
      )}
    </>
  )
}

export default MediaThumb
