import React from 'react'
import ReactDOM from 'react-dom'

// styles
import './styles.css'

// semantic-ui
import {
  Button
} from 'semantic-ui-react'

const AddButtons = props => {
  const {
    position,
    addRule,
    addGroup,
    groupsLevel,
    level
  } = props

  let maxLevel = groupsLevel || 2

  return (
    <div className='add-group-rule-container'>
      <Button.Group>
        <Button
          onClick={() => addRule(position)}
        >
          + Rule
        </Button>
        {/* maximum level of 3 OR (if defined) groupsLevel must be bigger than current level */}
        {(level < 3 && (maxLevel ? maxLevel > level : false)) && (
          <Button
            onClick={() => addGroup(position)}
          >
            + Group
          </Button>
        )}
      </Button.Group>
    </div>
  )
}

export default AddButtons
