import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import _ from 'lodash'

// components
import BuildControler from './components/BuildControler'

// semantic-ui
import {
  Radio
} from 'semantic-ui-react'

// styles
import './style.css'

const QueryBuilder = props => {

  // properties
  const {
    on,
    onChange,
    queryFields,
    groupsLevel,
    jsonData
  } = props

  // states
  const [isOn, setIsOn] = useState(false)

  // on props
  useEffect(() => {
    if (on !== null && on !== 'undefined') {
      setIsOn(on)
    }
  }, [on])

  const handleOnSwitchChange = e => {
    onChange(e)
  }

  const handleOnChange = e => {
    let { query, position } = e
    // transform query
    query.field = query.header + '.' + query.field
    query = _.omit(query, ['header'])
    // transform entire query
    let newQuery = jsonData
    let pos = position.toString().split('.')
    // maximum 3 levels
    switch (pos.length) {
      case 1:
        newQuery.rules[pos[0]] = query
        break
      case 2:
        newQuery.rules[pos[0]].rules[pos[1]] = query
        break
      case 3:
        newQuery.rules[pos[0]].rules[pos[1]].rules[pos[2]] = query
        break
    }
    onChange(newQuery)
  }

  const handleDelete = position => {
    let newQuery = jsonData
    let pos = position.toString().split('.')

    // maximum 3 levels
    switch (pos.length) {
      case 1:
        if (newQuery.rules.length > 1) {
          newQuery.rules.splice(pos[0], 1)
        } else {
          newQuery = null
        }
        break
      case 2:
        if (newQuery.rules[pos[0]].rules.length > 1) {
          newQuery.rules[pos[0]].rules.splice(pos[1], 1)
        } else {
          newQuery.rules.splice(pos[0])
        }
        break
      case 3:
        if (newQuery.rules[pos[0]].rules[pos[1]].rules.length > 1) {
          newQuery.rules[pos[0]].rules[pos[1]].rules.splice(pos[2], 1)
        } else {
          newQuery.rules[pos[0]].rules.splice(pos[1])
        }
        break
    }
    onChange(newQuery)
  }

  const handleChangeCondition = cond => {
    let { value, position } = cond
    let pos = position.toString().split('.')
    let newQuery = jsonData
    if (pos.length === 1) {
      newQuery.condition = value
    }
    if (pos.length === 2) {
      newQuery.rules[pos[0]].condition = value
    }
    if (pos.length === 3) {
      newQuery.rules[pos[0]].rules[pos[1]].condition = value
    }
    onChange(newQuery)
  }

  const handleAddRule = e => {
    let pos = e.toString().split('.')
    let newQuery = jsonData
    let firstField = queryFields.groups[0]
    let newRule = {
      field: firstField.header.name + '.' + firstField.fields[0].name,
      operator: firstField.fields[0].operators[0].name,
      value: firstField.fields[0].defaultValue
    }
    switch (pos.length) {
      case 1:
        if (jsonData.rules) {
          newQuery.rules.splice(pos[0] + 1, 0, newRule)
        } else {
          // create start one
          newQuery = { rules: [{ ...newRule  }], condition: 'and' }
        }
        break
      case 2:
        newQuery.rules[pos[0]].rules.splice(pos[1] + 1, 0, newRule)
        break
      case 3:
        newQuery.rules[pos[0]].rules[pos[1]].rules.splice(pos[2] + 1, 0, newRule)
        break
    }
    onChange(newQuery)
  }

  const handleAddGroup = e => {
    let pos = e.toString().split('.')
    let newQuery = jsonData
    let firstField = queryFields.groups[0]
    let newRule = {
      field: firstField.header.name + '.' + firstField.fields[0].name,
      operator: firstField.fields[0].operators[0].name,
      value: firstField.fields[0].defaultValue
    }
    let newGroup = {
      condition: 'and',
      rules: [{ ...newRule }, { ...newRule }]
    }
    switch (pos.length) {
      case 1:
        if (jsonData.rules) {
          newQuery.rules.splice(pos[0] + 1, 0, newGroup)
        } else {
          // create start one
          newQuery = { rules: [{ ...newGroup }], condition: 'and' }
        }
        break
      case 2:
        newQuery.rules[pos[0]].rules.splice(pos[1] + 1, 0, newGroup)
        break
    }
    onChange(newQuery)
  }

  return (
    <>
      {/* on/off */}
      <Radio
        label={isOn ? 'on' : 'off'}
        toggle
        checked={isOn ? true : false}
        value={isOn ? 'on' : 'off'}
        onChange={(e, {value}) => {
          if (value === 'off') {
            // turning on
            setIsOn(true)
            handleOnSwitchChange({})
          } else {
            // turning off
            setIsOn(false)
            // delete query
            handleOnSwitchChange(false)
          }
        }}
      />
      {isOn && (
        <>
          {/* text representation */}

          {/* graphic builder */}
          <BuildControler
            jsonData={jsonData}
            groupsLevel={groupsLevel + 1}
            onChange={handleOnChange}
            queryFields={queryFields}
            onDelete={handleDelete}
            onConditionChange={handleChangeCondition}
            addRule={handleAddRule}
            addGroup={handleAddGroup}
          />

          {/* advanced JSON */}

        </>
      )}
    </>
  )
}

export default QueryBuilder
