import store from 'store'
import EventEmitter from 'events'

class Session extends EventEmitter {
  constructor () {
    super()

    let data = store.get('session')
    if (data) {
      this.data = data
    } else {
      this.data = null
    }
    this.listeners = []
    this.triggerChange()
  }
  get (prop) {
    if ((this.data || {}).hasOwnProperty(prop)) {
      return this.data[prop]
    } else {
      return null
    }
  }
  set (prop, val) {
    this.data = this.data || {}
    this.data[prop] = val
    return this.data
  }
  login (data) {
    this.data = data
    store.set('session', this.data)
    this.triggerChange()
  }
  logout () {
    this.data = null
    store.remove('session')
    this.triggerChange()
  }
  isAuthenticated () {
    return !!(this.data && this.data.id && this.data.email && this.data.token)
  }
  triggerChange () {
    this.listeners.forEach(listener => listener(this.isAuthenticated()))
  }
  onChange (fn) {
    this.listeners.push(fn)
  }
}

let session = new Session()

export default session



// import React, { Component } from 'react'
// import ReactDOM from 'react-dom'
// import App from './components/App'
// import Login from './components/Login'
// import store from 'store'
// import registerServiceWorker from './registerServiceWorker'

// // styles
// import 'semantic-ui-css/semantic.min.css'
// import './index.css'

// // authentication
// class Auth extends Component {
//   constructor (props) {
//     super(props)

//     this.state = { token: store.get('token') }

//     this.setToken = this.setToken.bind(this)
//   }
//   setToken (token) {
//     if (token) {
//       store.set('token', token)
//     } else {
//       store.remove('token')
//     }
//     this.setState({ token })
//   }
//   render () {
//     if (this.state.token) {
//       return <App setToken={this.setToken} />
//     } else {
//       return <Login setToken={this.setToken} />
//     }
//   }
// }

// ReactDOM.render(<Auth />, document.getElementById('root'))
// registerServiceWorker()
