import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'

// semantic-ui
import {
  Segment,
   Grid
} from 'semantic-ui-react'

// components
import Rule from '../Rule'
import AddButtons from '../AddButtons'
import RemoveButton from '../RemoveButton'
import AndOrSwitch from '../AndOrSwitch'


const Group = props => {
  // props
  const {
    queryLevel,
    position,
    queryFields,
    onChange,
    handleDelete,
    handleChangeCondition,
    addGroup,
    addRule,
    groupsLevel
  } = props

  const createPosition = (pos, i) => {
    return (pos !== null && pos !== 'undefined') ? pos + '.' + i : i
  }

  return (
    <Segment style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
      {queryLevel && queryLevel.rules && queryLevel.rules.map((q, i) => {
        let newPosition = createPosition(position, i)
        let positionLevel = newPosition.toString().split('.').length
        return (
          <Fragment key={i}>
            {(i !== 0) && (
              <AndOrSwitch
                position={newPosition}
                handleChangeCondition={handleChangeCondition}
                val={(queryLevel && queryLevel.condition) || 'and'}
              />
            )}
            <Grid columns='equal' style={{marginTop: '0', marginBottom: '0'}} verticalAlign='top'>
              <Grid.Row style={{padding: '0'}}>
                <Grid.Column style={{padding: '0 0.5em'}}>
                  {q.field ? (
                    <Rule
                      ruleQuery={q}
                      queryFields={queryFields}
                      position={newPosition}
                      updateRule={onChange}
                    />
                  ) : (
                    <Group
                      queryLevel={q}
                      position={newPosition}
                      key={i}
                      queryFields={queryFields}
                      onChange={onChange}
                      handleDelete={handleDelete}
                      handleChangeCondition={handleChangeCondition}
                      addRule={addRule}
                      addGroup={addGroup}
                      groupsLevel={groupsLevel}
                    />
                  )}
                </Grid.Column>
                <Grid.Column width={1} style={{textAlign: 'left'}}>
                  {/* remove btn */}
                  {/* hide 'remove' btn for only one rule in a group */}
                  {positionLevel === 1 || (positionLevel > 1 && queryLevel.rules.length > 1) ? (
                    <RemoveButton
                      handleDelete={() => handleDelete(newPosition)}
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
                    {/* add condition for add more groups based on level and max level */}
            {queryLevel && queryLevel.rules && (i === queryLevel.rules.length - 1) && (
              <AddButtons
                position={newPosition}
                addRule={addRule}
                addGroup={addGroup}
                groupsLevel={groupsLevel}
                level={positionLevel}
              />
            )}
          </Fragment>
        )
      })}
      {/* no rules - create new query */}
      {!queryLevel.rules && (
        <AddButtons
          position={-1}
          addRule={addRule}
          addGroup={addGroup}
          groupsLevel={groupsLevel}
          level={1}
        />
      )}
    </Segment>
  )
}

export default Group
