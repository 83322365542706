import React, { Component, Fragment, useState, useEffect } from 'react'
// import i18n from '../i18n'
import { orgRoute } from '../../../../utils'

// i18n support
// import { withNamespaces } from 'react-i18next'

// react router
import { useLocation, NavLink, useHistory } from 'react-router-dom'

// graphql
import gql from 'graphql-tag'
import { useQuery, useSubscription } from '@apollo/react-hooks'

// images
import logo from '../../../../images/logo.svg'
import shortParagraph from '../../../../images/blankslate/short-paragraph.png'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

// lodash
import _ from 'lodash'

// styles
import './index.css'

// Moment.globalMoment = moment
// const locales = ['en-US', 'it-IT']

const ME = gql`
query GetMe {
  me {
    id
    email
    name
    organizations {
      id
      slug
      name
      jobs (where: { state: "processing" }) {
        id
        queue
        state
      }
    }
  }
}
`
const JOB_PROGRESS = gql`
subscription($organization: ID!){
  job_progress(organization: $organization) {
    id
    queue
    state
    payload
    organization_id
  }
}
`

function JobItem (props) {
  let [jobs, setJobs] = useState(props.organization.jobs || [])

  useSubscription(JOB_PROGRESS, {
    variables: { organization: props.organization.id },
    onSubscriptionData: ({ subscriptionData }) => {
      let data = subscriptionData.data.job_progress
      // find existing job
      let found = jobs.find(job => job.id === data.id)
      if (found) {
        found.tick = data.tick
        found.ticks = data.ticks
        found.state = data.state
      } else {
        jobs.push(data)
      }
      jobs = jobs.filter(({ state }) => state === 'processing')
      setJobs(jobs)
    }
  })

  return (
    <Semantic.Menu.Item
      as={NavLink}
      to={orgRoute(props.organization, 'jobs')}
      exact
    >
      Jobs
      {jobs.length > 0 && (
        <Semantic.Label size='tiny' color='red'>
          {jobs.length}
        </Semantic.Label>
      )}
    </Semantic.Menu.Item>
  )
}

function Topbar ()  {

  let [locale, setLocale] = useState('en-US')
  let [me, setMe] = useState(null)
  let [organizations, setOrganizations] = useState(null)
  let [organization, setOrganization] = useState(null)
  let [actionsDataFromSubs, setActionsDataFromSubs] = useState(null)

  let location = useLocation()
  let history = useHistory()

  // load me
  const { loading, error, data } = useQuery(ME)

  useEffect(() => {
    let match = location.pathname.match(/^\/orgs\/([\w -]+)/)
    let found = null
    if (match && data) {
      // set current organization
      found = data.me.organizations.find(organization => organization.slug === match[1])
    }
    setOrganization(found)
  }, [location.pathname, data])

  useEffect(() => {
    if (data) {
      setMe(data.me)
      setOrganizations(data.me.organizations)
    }
  }, [data])

  // load subscriptions


  return (
    <div className='Topbar'>
      <Semantic.Menu fixed='top' stackable>
        <Semantic.Menu.Item className='image' header as={NavLink} to='/' exact>
          <Semantic.Image src={logo} />
        </Semantic.Menu.Item>
        <Semantic.Dropdown
          item
          text={organization ? organization.name : 'Organizations'}
        >
          <Semantic.Dropdown.Menu style={{ minWidth: '200px' }}>
            <Semantic.Dropdown.Header>Organizations</Semantic.Dropdown.Header>
            {loading && (
              <Semantic.Dropdown.Item>
                <div className='blankslate'>
                  <Semantic.Dimmer active inverted>
                    <Semantic.Loader inverted>Loading organizations</Semantic.Loader>
                  </Semantic.Dimmer>

                  <Semantic.Image
                    src={shortParagraph}
                    width='100%'
                    height='66'
                  />
                </div>
              </Semantic.Dropdown.Item>
            )}
            {organizations &&
              organizations.map(org => (
                <Semantic.Dropdown.Item
                  key={'organization-' + org.id}
                  onClick={e => {
                    let split = location.pathname.split('/')
                    let path = `/orgs/${org.slug}`
                    if (split[3]) path += `/${split[3]}`
                    history.push(path)
                  }}
                >
                  &nbsp;&nbsp;
                  <Semantic.Image
                    avatar
                    src={
                      'https://ui-avatars.com/api/?background=000000&color=fff&name=' + org.name
                    }
                  />
                  {org.name}
                </Semantic.Dropdown.Item>
              ))}
            {organizations &&
              organizations.length === 0 && (
                <Semantic.Dropdown.Item disabled>No organization found</Semantic.Dropdown.Item>
              )}
          </Semantic.Dropdown.Menu>
        </Semantic.Dropdown>

        {organization && [
          <Semantic.Menu.Item
            key='overview'
            className='organization'
            as={NavLink}
            to={orgRoute(organization)}
            exact
          >
            <Semantic.Icon name='dashboard' />
            <span className='text'>Overview</span>
          </Semantic.Menu.Item>,
          <Semantic.Menu.Item
            key='media'
            className='organization'
            as={NavLink}
            to={orgRoute(organization, 'medias')}
          >
            <Semantic.Icon name='image' />
            <span className='text'>Medias</span>
          </Semantic.Menu.Item>,
          <Semantic.Menu.Item
            key='playlist'
            className='organization'
            as={NavLink}
            to={orgRoute(organization, 'playlists')}
          >
            <Semantic.Icon name='ordered list' />
            <span className='text'>Playlists</span>
          </Semantic.Menu.Item>,
          <Semantic.Menu.Item
            key='schedule'
            className='organization'
            as={NavLink}
            to={orgRoute(organization, 'schedules')}
          >
            <Semantic.Icon name='clock' />
            <span className='text'>Schedules</span>
          </Semantic.Menu.Item>,
          <Semantic.Menu.Item
            key='location'
            className='organization'
            as={NavLink}
            to={orgRoute(organization, 'locations')}
          >
            <Semantic.Icon name='map' />
            <span className='text'>Locations</span>
          </Semantic.Menu.Item>,
          <Semantic.Menu.Item
            key='users'
            className='organization'
            as={NavLink}
            to={orgRoute(organization, 'users')}
          >
            <Semantic.Icon name='users' />
            <span className='text'>Users</span>
          </Semantic.Menu.Item>
        ]}

        <Semantic.Menu.Menu position='right' className='jobs-menu'>
          {me && organization && (
            <JobItem organization={organization} />
          )}
          {!me && (
            <Semantic.Menu.Item as={NavLink} to='/logout'>
              Logout
            </Semantic.Menu.Item>
          )}
          {me && (
            <Semantic.Dropdown
              item
              className='image'
              trigger={
                <Fragment>
                  <Semantic.Image
                    avatar
                    src={'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=' + me.name}
                  />
                  <Semantic.Responsive {...Semantic.Responsive.onlyMobile}>
                    {me.name}
                  </Semantic.Responsive>
                </Fragment>
              }
            >
              <Semantic.Dropdown.Menu>
                {me && me.organizations[0].slug && (
                  <Semantic.Dropdown.Item as={NavLink} to={`/orgs/${me.organizations[0].slug}/users/${me.id}`}>
                    <Semantic.Icon name='user' />
                    Profile
                  </Semantic.Dropdown.Item>
                )}
                <Semantic.Dropdown.Item onClick={() => history.push('/logout')}>
                  <Semantic.Icon name='sign out' />
                  Logout
                </Semantic.Dropdown.Item>
              </Semantic.Dropdown.Menu>
            </Semantic.Dropdown>
          )}
        </Semantic.Menu.Menu>
      </Semantic.Menu>
    </div>
  )
}

export default Topbar
