import React from 'react'
import ReactDOM from 'react-dom'

// semantic-ui
import {
  Select,
  Input
} from 'semantic-ui-react'

const ValueInput = props => {
  // props
  const {
    value,
    values,
    inputType,
    minValue,
    maxValue,
    multiple,
    onChange
  } = props

  switch (inputType) {
    case 'number':
      return (
        <Input
          style={{width: '100%'}}
          type='number'
          value={value}
          onChange={onChange}
          min={minValue}
          max={maxValue}
        />
      )
      break
    case 'select':
      return (
        <Select
          multiple={multiple || false}
          style={{width: '100%'}}
          options={values.map(val => {
            return {
              key: val.name,
              text: val.label,
              value: val.name
            }
          })}
          value={value}
          onChange={onChange}
        />
      )
      break
    default:
      return (<p>No support yet for this kind of input</p>)
      break
  }
}

export default ValueInput
