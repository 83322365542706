import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import Moment from 'react-moment'

// images
import shortParagraph from '../../../../images/blankslate/short-paragraph.png'

// semantic-ui
import { Container, Icon, Header, Segment, Dimmer, Loader, Image, List, Progress, Label } from 'semantic-ui-react'

// styles
import './index.css'

const JOBS_QUERY = gql`
query GetJobs($id: ID!) {
  me {
    id
    email
    name
    organization(id: $id) {
      id
      slug
      name
      jobs (limit: 100) {
        id
        queue
        state
        created_at
      }
    }
  }
}
`

const JOB_PROGRESS = gql`
subscription($organization: ID!){
  job_progress(organization: $organization) {
    id
    tick
    ticks
    state
    queue
    payload
    organization_id
  }
}`

function Job (props) {
  let [jobs, setJobs] = useState([])

  // load jobs query
  const { loading, error, refetch, data } = useQuery(JOBS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: props.organization.id
    }
  })

  useEffect(() => {
    if (data && data.me) setJobs([...data.me.organization.jobs])
  }, [data])

  useSubscription(JOB_PROGRESS, {
    variables: { organization: props.organization.id },
    onSubscriptionData: ({ subscriptionData }) => {
      let data = subscriptionData.data.job_progress
      // find existing job
      let found = jobs.find(job => job.id === data.id)
      if (found) {
        found.tick = data.tick
        found.ticks = data.ticks
        found.state = data.state
        setJobs([...jobs])
      } else {
        refetch()
      }
    }
  })

  // job state to semantic-ui color name
  let colorMap = {
    completed: 'green',
    failed: 'red',
    pending: 'grey',
    new: 'black'
  }

  // queue name to semantic-ui icon name
  let iconMap = {
    image: 'image',
    video: 'video',
    audio: 'sound',
    doctor: 'doctor'
  }

  return (
    <Container className='Job'>
      <Header as='h2'>
        <Icon name='bell' />
        <Header.Content>
          Jobs
          <Header.Subheader>See the status of your background jobs</Header.Subheader>
        </Header.Content>
      </Header>

      {/* data loading */}
      {loading && (
        <Segment>
          <div className='blankslate'>
            <Dimmer active inverted>
              <Loader inverted>Loading jobs</Loader>
            </Dimmer>

            <Image src={shortParagraph} width='100%' height='66' />
          </div>
        </Segment>
      )}

      {/* blankslate */}
      {!loading && jobs.length === 0 && (
        <Segment className='blankslate'>
          <Header as='h4' icon style={{ margin: 0 }}>
            <Icon name='x' />
            No jobs found
            <Header.Subheader>New jobs will appear here automatically</Header.Subheader>
          </Header>
        </Segment>
      )}

      {!loading && jobs.length > 0 && (
        <Segment>
          <List divided relaxed>
            {jobs.map(job => (
              <List.Item  key={`job-${job.id}`}>
                <List.Content floated='right'>
                  {job.state === 'processing' && job.tick ? (
                    <Progress style={{ minWidth: '150px' }} value={job.tick} total={job.ticks} autoSuccess>
                      <span>{job.tick}/{job.ticks}</span>
                    </Progress>
                  ) : (
                    <Label color={colorMap[job.state]}>{job.state}</Label>
                  )}
                </List.Content>
                <List.Icon name={iconMap[job.queue] || 'question circle'} size='large' verticalAlign='middle' />
                <List.Content>
                  <List.Header>{job.id}</List.Header>
                  <List.Description>
                    Created <Moment fromNow>{job.created_at}</Moment>
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Segment>
      )}

    </Container>
  )
}

export default Job
