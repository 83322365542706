import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { orgRoute } from '../../../../utils'
import gql from 'graphql-tag'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { moment, Moment } from '../../../../moment'
// set default time zone
moment.tz.setDefault('Europe/Rome', false)
import _ from 'lodash'
// react big scheduler and suppport for it
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import Scheduler, {SchedulerData, ViewTypes, DATE_FORMAT} from 'react-big-scheduler'
import 'react-big-scheduler/lib/css/style.css'
// calendar
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// semantic-ui
import {
  Container,
  Header,
  Icon,
  Segment,
  Loader,
  Dimmer,
  Image,
  Modal,
  Form,
  Select,
  Button,
  Menu,
  Input,
  Checkbox,
  List
} from 'semantic-ui-react'
// custom styles
import './ScheduleEdit.css'
// images
import shortParagraph from '../../../../images/blankslate/short-paragraph.png'

// graphql queries
const QUERY_SCHEDULE = gql`
query QuerySchedule($slug: Slug!, $schedule: ID!) {
  me {
    id
    organization(slug: $slug) {
      id
      name
      playlists {
        id
        name
      }
      schedule(id: $schedule) {
        id
        name
        meta
        items {
          id
          dtstart
          dtend
          rrule
          exdate
          playlist {
            id
            name
          }
        }
        created_at
      }
    }
  }
}`
// graphql mutations
const UPDATE_SCHEDULE = gql`
mutation UpdateSchedule($input: UpdateScheduleInput!) {
  update_schedule(input: $input) {
    id
  }
}
`
const DELETE_SCHEDULE = gql`
mutation DeleteSchedule($input: DeleteScheduleInput!) {
  delete_schedule(input: $input) {
    id
  }
}
`
const CREATE_SCHEDULE_ITEM = gql`
mutation CreateScheduleItem($input: CreateScheduleItemInput!) {
  create_schedule_item(input: $input) {
    id
  }
}
`
const UPDATE_SCHEDULE_ITEM = gql`
mutation UpdateScheduleItem($input: UpdateScheduleItemInput!) {
  update_schedule_item(input: $input) {
    id
  }
}
`
const DELETE_SCHEDULE_ITEM = gql`
mutation UpdateScheduleItem($input: DeleteScheduleItemInput!) {
  delete_schedule_item(input: $input) {
    id
  }
}
`

function Edit(props) {
  // element reference
  const segment = React.createRef()
  // modal - for editing
  const [customModal, setCustomModal] = useState(false)
  // states - big-scheduler framework
  const [date, setDate] = useState(moment().format(DATE_FORMAT))
  const [viewType, setViewType] = useState(ViewTypes.Day)
  const [thisSchedule, setThisSchedule] = useState({})
  const [scheduleItems, setScheduleItems] = useState([])
  const [selectedDefaultPl, setSelectedDefaultPl] = useState({id: null, name: ''})
  const [schedulerData, setSchedulerData] = useState()
  const [currentItem, setCurrentItem] = useState(null)
  const [isRrule, setIsRrule] = useState(false)
  const [activeRepeatItem, setActiveRepeatItem] = useState('Daily')
  const [playlistSelected, setPlaylistSelected] = useState(true)
  const [showAddDate, setShowAddDate] = useState(false)
  const [rrule, setRrule] = useState(false)

  const setExistingSchedulerData = () => {
    // set scheduler data
    setSchedulerData(new SchedulerData(date, viewType, false, false, {
      schedulerWidth: segment.current.offsetWidth - 1,
      nonAgendaSlotMinHeight: 40,
      resourceName: '',
      checkConflict: true,
      recurringEventsEnabled: true,
      views: [
        {viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false},
        {viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
        {viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false}
      ]
    }))
  }
  // schedule mutations
  const [updateSchedule, { error: updateScheduleError }] = useMutation(UPDATE_SCHEDULE)
  useEffect(() => {
    if (updateScheduleError) {
      // set to message popup!!!
      alert('Update schedule error!')
    }
  }, [updateScheduleError])
  const [deleteSchedule, { error: deleteScheduleError }] = useMutation(DELETE_SCHEDULE)
  useEffect(() => {
    if (deleteScheduleError) {
      // set to message popup!!!
      alert('Delete schedule error!')
    }
  }, [deleteScheduleError])
  const [createScheduleItem, { error: createScheduleItemError }] = useMutation(CREATE_SCHEDULE_ITEM)
  useEffect(() => {
    if (createScheduleItemError) {
      // set to message popup!!!
      alert('Create schedule item error!')
    }
  }, [createScheduleItemError])
  const [updateScheduleItem, { error: updateScheduleItemError }] = useMutation(UPDATE_SCHEDULE_ITEM)
  useEffect(() => {
    if (updateScheduleItemError) {
      // set to message popup!!!
      alert('Update schedule item error!')
    }
  }, [updateScheduleItemError])
  const [deleteScheduleItem, { error: deleteScheduleItemError }] = useMutation(DELETE_SCHEDULE_ITEM)
  useEffect(() => {
    if (deleteScheduleItemError) {
      // set to message popup!!!
      alert('Delete schedule item error!')
    }
  }, [deleteScheduleItemError])
  // query data
  const [getData, { loading, error, data, refetch }] = useLazyQuery(QUERY_SCHEDULE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        data &&
        data.me &&
        data.me.organization &&
        data.me.organization.schedule
      ) {
        setThisSchedule({ id: data.me.organization.schedule.id, name: data.me.organization.schedule.name })
        setExistingSchedulerData()
        let eventsData = data.me.organization.schedule.items
        setScheduleItems(eventsData)
        // if default schedule defined
        if (data.me.organization.schedule.meta && data.me.organization.schedule.meta.default_playlist) {

          let defaultPl = data.me.organization.playlists.find(pl => pl.id === data.me.organization.schedule.meta.default_playlist.id)
          if (defaultPl) {
            let { id, name } = defaultPl
            setSelectedDefaultPl({ id, name })
          } else {
            setSelectedDefaultPl({id: 'new', name: 'Undefined playlist!'})
          }
        } else {
          setSelectedDefaultPl({id: 'new', name: 'Undefined playlist!'})
        }
      }
    }
  })
  // set Events
  const setEventsData = (data) => {
    let events = []

    // **** custom events ****

    const randomColor = i => {
      // default color -> #5c85d6
      let colors = [
        '#003366',
        '#FF0000',
        '#0099cc',
        '#0066ff',
        '#006600',
        '#663300',
        '#cc3399',
        '#006699',
        '#999900',
        '#993300'
      ]
      let nmb = i
      nmb = i < colors.length ? i : 0
      return colors[nmb]
    }
    data.forEach((ev, i) => {
      let itemRrule = ev.rrule || ''
      // exdate
      let exDates = []
      if (ev.exdate && ev.exdate.length > 0) {
        // match to ev.dtstart
        let hour = moment(ev.dtstart).format('HH')
        let minute = moment(ev.dtstart).format('mm')
        let second = moment(ev.dtstart).format('ss')
        ev.exdate.forEach(ed => {
          let exd = moment(ed).format('YYYY-MM-DD')
          // convert exdate to match dtstart
          let modifiedExd = moment(exd).set({hour, minute, second})
          modifiedExd = moment.utc(modifiedExd).toDate()
          exDates.push(modifiedExd)
        })
      }
      events.push({
        id: ev.id,
        key: ev.id,
        start: moment(ev.dtstart).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(ev.dtend).format('YYYY-MM-DD HH:mm:ss'),
        resourceId: 'r1',
        title: ev.playlist && ev.playlist.name ? ev.playlist.name : 'No playlist!',
        bgColor: randomColor(i),
        movable: true,
        resizable: true,
        startResizable: true,
        endResizable: true,
        rrule: itemRrule,
        type: 3,
        exdates: exDates.length > 0 ? exDates : null
      })
    })
    schedulerData.setEvents(events)
  }
  const obtainData = () => {
    const { organization: slug, schedule } = props.match.params
    // obtain events data
    getData({ variables: { slug, schedule} })
  }
  // query schedule on props received
  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.organization && props.match.params.schedule) {
      obtainData()
    }
  }, [])

  useEffect(() => {
    if (schedulerData) {
      schedulerData.localeMoment.locale('it')
      // set resources
      let resources = [
        {
          id: 'r1',
          name: 'Custom playlists'
        }
      ]
      schedulerData.setResources(resources)
      schedulerData.setMinuteStep(30)
    }
  }, [schedulerData])

  useEffect(() => {
    if (scheduleItems && schedulerData) {
      setEventsData(scheduleItems)
    }
  }, [scheduleItems, schedulerData])

  useEffect(() => {
    // refresh scheduler
    setExistingSchedulerData()
  }, [date, viewType])

  // scheduler fn-s
  const prevClick = schedulerData => {
    schedulerData.prev()
    switch (viewType) {
      case 0:
        setDate(moment(date).subtract(1, 'days').format(DATE_FORMAT))
        break
      case 1:
        setDate(moment(date).subtract(1, 'weeks').format(DATE_FORMAT))
        break
      case 2:
        setDate(moment(date).subtract(1, 'months').format(DATE_FORMAT))
        break
    }
  }

  const nextClick = schedulerData => {
    schedulerData.next()
    switch (viewType) {
      case 0:
        setDate(moment(date).add(1, 'days').format(DATE_FORMAT))
        break
      case 1:
        setDate(moment(date).add(1, 'weeks').format(DATE_FORMAT))
        break
      case 2:
        setDate(moment(date).add(1, 'months').format(DATE_FORMAT))
        break
    }
  }
  const onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType)
    setViewType(view.viewType)
  }
  const onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date)
    setDate(date)
  }
  // edit fns
  const eventClicked = (schedulerData, event) => {
    doEditEvent(schedulerData, event)
  }
  const clickToEdit = (schedulerData, event) => {
    doEditEvent(schedulerData, event)
  }
  const doEditEvent = (schedulerData, event) => {
    if (event.type === 1) {
      setDefaultModal(true)
    } else if (event.type === 3) {
      // find current event
      let currEvent = scheduleItems.find(sc => sc.id === event.key)
      // set current item for edit
      setCurrentItem({ ...currEvent })
      // set rrule options on
      if (currEvent && currEvent.rrule) {
        setIsRrule(true)
        // destructure rrule to arr of objects
        let rrule = currEvent.rrule.split(';')
        let rruleArr = rrule.map(r => {
          let objKeysVals = r.split('=')
          let obj = {}
          obj[objKeysVals[0]] = objKeysVals[1]
          return obj
        })
        let rruleObj = {}
        rruleArr.forEach(r => {
          rruleObj[Object.keys(r)[0]] = Object.values(r)[0]
        })
        if (currEvent && currEvent.exdate) {
          rruleObj['EXDATE'] = currEvent.exdate
        } else {
          rruleObj['EXDATE'] = []
        }
        // set byday arr
        if (rruleObj['BYDAY']) {
          rruleObj['BYDAY'] = rruleObj['BYDAY'].split(',')
        }
        // set freq
        if (rruleObj['FREQ'] === 'WEEKLY') {
          setActiveRepeatItem('Weekly')
        } else if (rruleObj['FREQ'] === 'DAILY') {
          setActiveRepeatItem('Daily')
        }

        setRrule(rruleObj)
      } else {
        setIsRrule(false)
      }
      setCustomModal(true)
    }
  }
  const handleSetCurrItemPl = id => {
    let pl = data.me.organization.playlists.find(pl => pl.id === id)
    let playlist = { id, name: pl.name }
    setCurrentItem({ ...currentItem, playlist })
  }
  const handleSetCurrItemStartTime = date => {
    let mDate = moment(date)
    let mDtEnd = moment(currentItem.dtend)
    if (mDtEnd - mDate <= 0 || moment.duration(moment(mDtEnd).diff(moment(mDate))).asHours() > 23.999) {
      mDtEnd = moment(mDate).add(1, 'minutes')
      setCurrentItem({ ...currentItem, dtstart: mDate.toISOString(), dtend: mDtEnd.toISOString() })
    } else {
      setCurrentItem({ ...currentItem, dtstart: mDate.toISOString() })
    }
  }
  const handleSetCurrItemEndTime = date => {
    let mDate = moment(currentItem.dtstart)
    let mDtEnd = moment(date)
    if (mDtEnd - mDate <= 0 || moment.duration(moment(mDtEnd).diff(moment(mDate))).asHours() > 23.999) {
      mDtEnd = moment(mDate).add(1, 'minutes')
      setCurrentItem({ ...currentItem, dtstart: mDate.toISOString(), dtend: mDtEnd.toISOString() })
    } else {
      setCurrentItem({ ...currentItem, dtend: mDtEnd.toISOString() })
    }
  }
  // create/update schedule item
  const setCustomEventsHandler = async () => {
    if (currentItem.playlist && currentItem.playlist.id) {
      if (currentItem && currentItem.id) {
        // update schedule item in DB
        let { id, dtstart, dtend, rrule: Rrule, exdate, playlist } = currentItem
        let playlist_id = (playlist && playlist.id) ? playlist.id : null
        // set rrule
        if (!isRrule) {
          Rrule = null
        } else {
          if (activeRepeatItem === 'Daily') {
            Rrule = 'FREQ=DAILY;'
            Rrule += 'INTERVAL=' + (rrule['INTERVAL'] || 1)
          } else if (activeRepeatItem === 'Weekly' && rrule['BYDAY'] && rrule['BYDAY'].length > 0) {
            Rrule = 'FREQ=WEEKLY;'
            Rrule += 'BYDAY=' + rrule['BYDAY'].join(',')
          }
          if (rrule['EXDATE'] && rrule['EXDATE'].length > 0) {
            // set EXDATE
            let exDates = rrule['EXDATE'].map(d => moment(d).toISOString())
            exdate = exDates
          } else {
            exdate = null
          }
        }
        // update item in DB
        let input = { id, dtstart, dtend, rrule: Rrule, exdate, playlist_id }
        updateScheduleItem({ variables: { input } })
        // update states
        let scItems = scheduleItems.map(si => {
          return si.id === id ? { ...si, id, dtstart, dtend, rrule: Rrule, exdate, playlist: { ...si.playlist, ...playlist } } : si
        })
        setScheduleItems([ ...scItems ])
      } else {
        // create new item in DB
        let playlist_id = (currentItem.playlist && currentItem.playlist.id) ? currentItem.playlist.id : null
        let newItem = { ...currentItem }
        delete newItem.playlist
        // set rrule
        let exdate = null
        let Rrule = null
        if (isRrule) {
          if (activeRepeatItem === 'Daily') {
            Rrule = 'FREQ=DAILY;'
            Rrule += 'INTERVAL=' + (rrule['INTERVAL'] || 1)
          } else if (activeRepeatItem === 'Weekly' && rrule['BYDAY'] && rrule['BYDAY'].length > 0) {
            Rrule = 'FREQ=WEEKLY;'
            Rrule += 'BYDAY=' + rrule['BYDAY'].join(',')
          }
          if (rrule['EXDATE'] && rrule['EXDATE'].length > 0) {
            // set EXDATE
            let exDates = rrule['EXDATE'].map(d => moment(d).toISOString())
            exdate = exDates
          }
        }
        // create in db
        let input = {
          ...newItem,
          playlist_id,
          schedule_id: thisSchedule.id
        }
        if (Rrule) input.rrule = Rrule
        if (exdate) input.exdate = exdate
        createScheduleItem({ variables: { input }})
        obtainData()
      }
      setCustomModal(false)
      setPlaylistSelected(true)
      // reset rrule states
      setActiveRepeatItem('Daily')
      setIsRrule(false)
      setRrule(false)
      setShowAddDate(false)
    } else {
      // no playlist selected warning
      setPlaylistSelected(false)
    }
  }
  const handleDeleteCurrentItem = () => {
    // reset states
    setActiveRepeatItem('Daily')
    setIsRrule(false)
    setRrule(false)
    // check if exists in list
    let currItem = scheduleItems.find(si => si.id === currentItem.id)
    if (currItem) {
      // delete current item in DB
      deleteScheduleItem({ variables: { input: { id: currItem.id }} })
    }
    // update state
    let updatedSchedules = scheduleItems.filter(si => si.id !== currItem.id)
    setScheduleItems([ ...updatedSchedules ])
    setCustomModal(false)
  }
  useEffect(() => {
    // getting selected pl id if changed as selectedDefaultPl
    if (selectedDefaultPl && data) {
      // update default playlist in DB
      let meta = data.me.organization.schedule.meta
      let input = {
        id: data.me.organization.schedule.id,
        meta: {
          ...meta,
          default_playlist: selectedDefaultPl
        }
      }
      if (selectedDefaultPl.id !== 'new') updateSchedule({ variables: { input } })
    }
  }, [selectedDefaultPl])
  const updateDefaultPL = val => {
    // update current default schedule
    let defaultPl = data.me.organization.playlists.find(pl => pl.id === val)
    if (defaultPl) {
      let { id, name } = defaultPl
      setSelectedDefaultPl({ id, name })
    }
  }
  const handleScheduleNameChange = name => {
    // update state
    let oldSchedule = _.cloneDeep(thisSchedule)
    setThisSchedule({ ...oldSchedule, name })
    // update DB
    let input = {
      id: thisSchedule.id,
      name
    }
    updateSchedule({ variables: { input } })
  }
  const handleDeleteSchedule = async () => {
    if (window.confirm('Are you sure?\n\nThis will delete schedule!')) {
      let id = data.me.organization.schedule.id
      let input = { id }
      await deleteSchedule({ variables: { input } })
      // redirect to schedule page
      let path = orgRoute({ slug: props.match.params.organization }, ['schedules'])
      props.history.push(path)
    }
  }
  const handleAddExDate = date => {
    // let newExDate = moment(date).format('YYYY-MM-DD')
    let exDateList = rrule['EXDATE'] || []
    let newDate = moment(date).startOf('day').toISOString()
    let newDay = moment(newDate).format('YYYY-MM-DD')
    if (exDateList.length > 0) {
      // find if already has the same date
      let search = exDateList.find(ed => {
        let exd = moment(ed).format('YYYY-MM-DD')
        return exd === newDay
      })
      if (!search) exDateList.push(newDate)
    } else {
      exDateList.push(newDate)
    }
    setRrule({ ...rrule, 'EXDATE': [ ...exDateList ] })
    // ui wait
    setTimeout(() => {
      setShowAddDate(false)
    }, 500)
  }
  const handleByDayClick = shortName => {
    let newByDays = rrule['BYDAY'] || []
    if (rrule['BYDAY'] && rrule['BYDAY'].indexOf(shortName) >= 0) {
      // turn off day
      newByDays = newByDays.filter(d => d !== shortName)
    } else {
      // turn on day
      newByDays.push(shortName)
    }
    setRrule({ ...rrule, 'BYDAY': [ ...newByDays ] })
  }
  const handleRemoveFromExDateList = date => {
    let choosen = moment(date).format('YYYY-MM-DD')
    let newExDates = rrule['EXDATE'].filter(d => {
      let compare = moment(d).format('YYYY-MM-DD')
      return compare !== choosen
    })
    setRrule({ ...rrule, 'EXDATE': [ ...newExDates ] })
  }
  const handleIntervalChange = nmb => {
    setRrule({ ...rrule, 'INTERVAL': nmb })
  }

  // framework generic functions

  // create new event
  const createNewEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    // open custom modal and set defaults to selected region
    setCurrentItem({
      dtstart: start,
      dtend: end
    })
    setIsRrule(false)
    setCustomModal(true)
  }
  const conflictOccurred = () => {
    alert('Playlists can not overlap!')
  }
  const updateEventStart = (schedulerData, event, start) => {
    // set currentItem and open modal
    let cur = scheduleItems.find(si => si.id === event.key)
    let newStartDate = event.recurringEventStart ? moment(event.recurringEventStart) : moment(event.start)
    let newStart = moment(newStartDate.format('YYYY-MM-DD') + ' ' + moment(start).format('hh:mm:ss A')).format('YYYY-MM-DD HH:mm:ss')
    if (cur.rrule && cur.rrule.length > 0) {
      setIsRrule(true)
      // destructure rrule to arr of objects
      let rrule = cur.rrule.split(';')
      let rruleArr = rrule.map(r => {
        let objKeysVals = r.split('=')
        let obj = {}
        obj[objKeysVals[0]] = objKeysVals[1]
        return obj
      })
      let rruleObj = {}
      rruleArr.forEach(r => {
        rruleObj[Object.keys(r)[0]] = Object.values(r)[0]
      })
      if (cur.exdate && cur.exdate.length > 0) {
        rruleObj['EXDATE'] = cur.exdate
      } else {
        rruleObj['EXDATE'] = []
      }
      // set byday arr
      if (rruleObj['BYDAY']) {
        rruleObj['BYDAY'] = rruleObj['BYDAY'].split(',')
      }
      // set freq
      if (rruleObj['FREQ'] === 'WEEKLY') {
        setActiveRepeatItem('Weekly')
      } else if (rruleObj['FREQ'] === 'DAILY') {
        setActiveRepeatItem('Daily')
      }
      setRrule(rruleObj)
    }
    setCurrentItem({ ...cur, dtstart: newStart })
    setCustomModal(true)
  }
  const updateEventEnd = (schedulerData, event, end) => {
    // set currentItem and open modal
    let cur = scheduleItems.find(si => si.id === event.key)
    let newEndDate = event.recurringEventEnd ? moment(event.recurringEventEnd) : moment(event.end)
    let newEnd = moment(newEndDate.format('YYYY-MM-DD') + ' ' + moment(end).format('hh:mm:ss A')).format('YYYY-MM-DD HH:mm:ss')
    if (cur.rrule && cur.rrule.length > 0) {
      setIsRrule(true)
      // destructure rrule to arr of objects
      let rrule = cur.rrule.split(';')
      let rruleArr = rrule.map(r => {
        let objKeysVals = r.split('=')
        let obj = {}
        obj[objKeysVals[0]] = objKeysVals[1]
        return obj
      })
      let rruleObj = {}
      rruleArr.forEach(r => {
        rruleObj[Object.keys(r)[0]] = Object.values(r)[0]
      })
      if (cur.exdate && cur.exdate.length > 0) {
        rruleObj['EXDATE'] = cur.exdate
      } else {
        rruleObj['EXDATE'] = []
      }
      // set byday arr
      if (rruleObj['BYDAY']) {
        rruleObj['BYDAY'] = rruleObj['BYDAY'].split(',')
      }
      // set freq
      if (rruleObj['FREQ'] === 'WEEKLY') {
        setActiveRepeatItem('Weekly')
      } else if (rruleObj['FREQ'] === 'DAILY') {
        setActiveRepeatItem('Daily')
      }
      setRrule(rruleObj)
    }
    setCurrentItem({ ...cur, dtend: newEnd })
    setCustomModal(true)
  }
  const moveEvent = (a, event, c, d, start, end) => {
    // set currentItem and open modal
    let cur = scheduleItems.find(si => si.id === event.key)
    let newStartDate = event.recurringEventStart ? moment(event.recurringEventStart) : moment(event.start)
    let newStart = moment(newStartDate.format('YYYY-MM-DD') + ' ' + moment(start).format('hh:mm:ss A')).format('YYYY-MM-DD HH:mm:ss')
    let newEndDate = event.recurringEventEnd ? moment(event.recurringEventEnd) : moment(event.end)
    let newEnd = moment(newEndDate.format('YYYY-MM-DD') + ' ' + moment(end).format('hh:mm:ss A')).format('YYYY-MM-DD HH:mm:ss')
    if (cur.rrule && cur.rrule.length > 0) {
      setIsRrule(true)
      // destructure rrule to arr of objects
      let rrule = cur.rrule.split(';')
      let rruleArr = rrule.map(r => {
        let objKeysVals = r.split('=')
        let obj = {}
        obj[objKeysVals[0]] = objKeysVals[1]
        return obj
      })
      let rruleObj = {}
      rruleArr.forEach(r => {
        rruleObj[Object.keys(r)[0]] = Object.values(r)[0]
      })
      if (cur.exdate && cur.exdate.length > 0) {
        rruleObj['EXDATE'] = cur.exdate
      } else {
        rruleObj['EXDATE'] = []
      }
      // set byday arr
      if (rruleObj['BYDAY']) {
        rruleObj['BYDAY'] = rruleObj['BYDAY'].split(',')
      }
      // set freq
      if (rruleObj['FREQ'] === 'WEEKLY') {
        setActiveRepeatItem('Weekly')
      } else if (rruleObj['FREQ'] === 'DAILY') {
        setActiveRepeatItem('Daily')
      }
      setRrule(rruleObj)
    }
    setCurrentItem({ ...cur, dtstart: newStart, dtend: newEnd })
    setCustomModal(true)
  }
  // event style
  const eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
    let borderWidth = (isStart || isEnd) ? '4' : '0'
    let borderColor =  'rgba(0,139,236,1)', backgroundColor = bgColor
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event)
    if(!!event.type){
      borderColor = event.type == 1 ? '#80C5F6' : '#999999'
      if (event.type == 1) backgroundColor = '#80C5F6'
    }
    let divStyle = {
      borderLeft: borderWidth + 'px solid ' + borderColor,
      borderRight: borderWidth + 'px solid ' + borderColor,
      backgroundColor: backgroundColor,
      height: mustBeHeight
    }
    if(!!agendaMaxEventWidth) divStyle = {...divStyle, maxWidth: agendaMaxEventWidth}
    return <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{marginLeft: '4px', marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>{titleText}</span>
    </div>
  }
  useEffect(() => {
    // hide show add a date to exlist
    setShowAddDate(false)
  }, [isRrule])

  const customPlaylistsOptions = [
    { key: 'af', value: 'af', text: 'Afghanistan' },
    { key: 'ax', value: 'ax', text: 'Aland Islands' },
    { key: 'al', value: 'al', text: 'Albania' },
    { key: 'dz', value: 'dz', text: 'Algeria' },
    { key: 'as', value: 'as', text: 'American Samoa' },
    { key: 'ad', value: 'ad', text: 'Andorra' },
    { key: 'ao', value: 'ao', text: 'Angola' },
    { key: 'ai', value: 'ai', text: 'Anguilla' },
    { key: 'ag', value: 'ag', text: 'Antigua' },
    { key: 'ar', value: 'ar', text: 'Argentina' },
    { key: 'am', value: 'am', text: 'Armenia' },
    { key: 'aw', value: 'aw', text: 'Aruba' },
    { key: 'au', value: 'au', text: 'Australia' },
    { key: 'at', value: 'at', text: 'Austria' },
    { key: 'az', value: 'az', text: 'Azerbaijan' },
    { key: 'bs', value: 'bs', text: 'Bahamas' },
    { key: 'bh', value: 'bh', text: 'Bahrain' },
    { key: 'bd', value: 'bd', text: 'Bangladesh' },
    { key: 'bb', value: 'bb', text: 'Barbados' },
    { key: 'by', value: 'by', text: 'Belarus' },
    { key: 'be', value: 'be', text: 'Belgium' },
    { key: 'bz', value: 'bz', text: 'Belize' },
    { key: 'bj', value: 'bj', text: 'Benin' },
  ]

  return (
    <Container className='ScheduleEdit'>
      <Header as='h2'>
        <Icon name='clock' />
        <Header.Content>
          Schedule
          <Header.Subheader>{data && (<>{thisSchedule.name}</>)}</Header.Subheader>
        </Header.Content>
      </Header>
      <div className='editor' ref={segment}>
        {data && !loading ? (
          <>
            {/* header */}
            <Menu attached='top'>
              <Menu.Item as={NavLink} to={`/orgs/${props.match.params.organization}/schedules`} exact >
                <Icon name='arrow left' />
              </Menu.Item>
              <Menu.Item>
                <Input value={(thisSchedule && thisSchedule.name) || ''} onChange={e => handleScheduleNameChange(e.target.value)} />
              </Menu.Item>
              <Menu.Menu position='right'>
                <Menu.Item onClick={handleDeleteSchedule} icon>
                  <Icon name='trash' />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            {/* Default schedule */}
            <Menu attached='bottom'>
              <Menu.Item>
                <h4>Default playlist</h4>
              </Menu.Item>
              <Menu.Item>
                {selectedDefaultPl && selectedDefaultPl.id && (<Select
                  name='meta.playlist'
                  onChange={(e, { value }) => value && updateDefaultPL(value)}
                  search
                  placeholder='Select a playlist'
                  defaultValue={(selectedDefaultPl && selectedDefaultPl.id && selectedDefaultPl.id !== 'new') ? selectedDefaultPl.id : null}
                  options={data && data.me.organization.playlists.map(playlist => {
                    return {
                      key: 'playlist-' + playlist.id,
                      value: playlist.id,
                      text: playlist.name
                    }
                  })}
                />)}
              </Menu.Item>
            </Menu>
            {/* Custom schedule */}
            <Segment attached='bottom'>
              <h4>Custom playlists</h4>
              {schedulerData && (
                <Scheduler
                  schedulerData={schedulerData}
                  prevClick={prevClick}
                  nextClick={nextClick}
                  onSelectDate={onSelectDate}
                  onViewChange={onViewChange}
                  eventItemClick={eventClicked}
                  viewEventClick={clickToEdit}
                  viewEventText="Edit"
                  newEvent={createNewEvent}
                  eventItemTemplateResolver={eventItemTemplateResolver}
                  updateEventStart={updateEventStart}
                  updateEventEnd={updateEventEnd}
                  moveEvent={moveEvent}
                  conflictOccurred={conflictOccurred}
                />
              )}
            </Segment>
            {/* edit/create custom event modal */}
            {customModal && (
              <Modal
                size='tiny'
                open={customModal}
                closeIcon
                onClose={e => setCustomModal(false)}
              >
                <Modal.Content>
                  <Form onSubmit={() => {
                    setCustomEventsHandler()
                  }}>
                    {/* edit playlist - choose playlist */}
                    <Form.Field>
                      <label>Playlist:</label>
                      <Select
                        style={{margin: '0.5em 0'}}
                        name='meta.playlist'
                        onChange={(e, { value }) => value && handleSetCurrItemPl(value)}
                        search
                        error={!playlistSelected}
                        placeholder='Select a playlist'
                        defaultValue={(currentItem && currentItem.playlist && currentItem.playlist.id) ? currentItem.playlist.id : null}
                        options={data && data.me.organization.playlists.map(playlist => {
                          return {
                            key: 'playlist-' + playlist.id,
                            value: playlist.id,
                            text: playlist.name
                          }
                        })}
                      />
                    </Form.Field>
                    {/* edit start date / end date */}
                    <Form.Field>
                      <label>Select time range:</label>
                      <Form.Field className='inside-form'>
                        <label>From:</label>
                        <DatePicker
                          selected={moment(currentItem.dtstart).toDate()}
                          onChange={date => handleSetCurrItemStartTime(date)}
                          showTimeSelect
                          timeIntervals={10}
                          todayButton="Select Today"
                          timeCaption="From"
                          dateFormat="MMM dd, yyyy h:mm aa"
                        />
                        <br/>
                        <label className='to-margined'>To:</label>
                        <DatePicker
                          selected={moment(currentItem.dtend).toDate()}
                          onChange={date => handleSetCurrItemEndTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={1}
                          timeCaption="To"
                          dateFormat="h:mm aa"
                          minTime={moment(currentItem.dtstart).add(1, 'minutes').toDate()}
                          maxTime={moment(currentItem.dtstart).endOf('day').toDate()}
                        />
                      </Form.Field>
                    </Form.Field>
                    {/* edit rrule - default none */}
                    <Form.Field>
                      <label>Repeating rules:</label>
                      <Checkbox toggle label={isRrule ? 'on' : 'off'} checked={isRrule} onChange={() => setIsRrule(!isRrule)} />
                      {isRrule && (
                        <Form.Field className='inside-form' style={{marginTop: '1rem'}}>
                          <label>Repeat:</label>
                          <Menu pointing widths='2' className='choose-opt'>
                            <Menu.Item
                              name='Daily'
                              active={activeRepeatItem === 'Daily'}
                              onClick={e => setActiveRepeatItem('Daily')}
                            />
                            <Menu.Item
                              name='Weekly'
                              active={activeRepeatItem === 'Weekly'}
                              onClick={e => setActiveRepeatItem('Weekly')}
                            />
                          </Menu>
                          {activeRepeatItem === 'Daily' && (
                          <>
                            <div className='inline-table'>
                              <span className='inline'>Every </span>
                              <Input
                                className='inline'
                                type='number'
                                value={(rrule && rrule['INTERVAL']) || 1}
                                onChange={e => handleIntervalChange(e.target.value)} style={{width: '4rem'}}
                              />
                              <span className='inline'> day(s)</span>
                            </div>
                          </>
                          )}
                          {activeRepeatItem === 'Weekly' && (
                          <>
                            <label>Every:</label>
                            <Menu widths='7' className='choose-opt-weekday'>
                              <Menu.Item
                                as='a'
                                active={(rrule && rrule['BYDAY'] && rrule['BYDAY'].indexOf('MO') >= 0) || false}
                                onClick={e => handleByDayClick('MO')}
                              >
                                Mon
                              </Menu.Item>
                              <Menu.Item
                                as='a'
                                active={(rrule && rrule['BYDAY'] && rrule['BYDAY'].indexOf('TU') >= 0) || false}
                                onClick={e => handleByDayClick('TU')}
                              >
                                Tue
                              </Menu.Item>
                              <Menu.Item
                                as='a'
                                active={(rrule && rrule['BYDAY'] && rrule['BYDAY'].indexOf('WE') >= 0) || false}
                                onClick={e => handleByDayClick('WE')}
                              >
                                Wed
                              </Menu.Item>
                              <Menu.Item
                                as='a'
                                active={(rrule && rrule['BYDAY'] && rrule['BYDAY'].indexOf('TH') >= 0) || false}
                                onClick={e => handleByDayClick('TH')}
                              >
                                Thu
                              </Menu.Item>
                              <Menu.Item
                                as='a'
                                active={(rrule && rrule['BYDAY'] && rrule['BYDAY'].indexOf('FR') >= 0) || false}
                                onClick={e => handleByDayClick('FR')}
                              >
                                Fri
                              </Menu.Item>
                              <Menu.Item
                                as='a'
                                active={(rrule && rrule['BYDAY'] && rrule['BYDAY'].indexOf('SA') >= 0) || false}
                                onClick={e => handleByDayClick('SA')}
                              >
                                Sat
                              </Menu.Item>
                              <Menu.Item
                                as='a'
                                active={(rrule && rrule['BYDAY'] && rrule['BYDAY'].indexOf('SU') >= 0) || false}
                                onClick={e => handleByDayClick('SU')}
                              >
                                Sun
                              </Menu.Item>
                            </Menu>
                          </>
                          )}
                          <label style={{marginTop: '2rem'}}>Exclude list:</label>
                          <Button
                            content='Add a date to exclude list'
                            icon='plus'
                            size='small'
                            onClick={e => {
                              e.preventDefault()
                              setShowAddDate(!showAddDate)
                            }}
                          />
                          {showAddDate && (
                            <DatePicker
                              selected={new Date()}
                              onChange={date => handleAddExDate(date)}
                              todayButton="Select Today"
                              dateFormat="MMM dd, yyyy h:mm aa"
                              inline
                            />
                          )}
                          <Segment>
                            <List divided verticalAlign='middle'>
                              {rrule['EXDATE'] ? rrule['EXDATE'].sort((a,b) => moment(a).valueOf() - moment(b).valueOf()).map((ed, i) => (
                                <List.Item
                                  key={i}
                                  as='a'
                                  onClick={e => {handleRemoveFromExDateList(ed)}}
                                >
                                  {moment(ed).format('YYYY-MM-DD')}
                                </List.Item>
                              )) : null}
                            </List>
                          </Segment>
                        </Form.Field>)}
                    </Form.Field>
                    {currentItem && currentItem.id && (<Button floated='right' color='red' onClick={e => {
                      e.preventDefault()
                      handleDeleteCurrentItem()
                    }}>Delete this event</Button>)}
                    <Button onClick={e => {
                      e.preventDefault()
                      setCustomModal(false)
                      setCurrentItem(null)
                      setPlaylistSelected(true)
                      // reset rrule states
                      setActiveRepeatItem('Daily')
                      setIsRrule(false)
                      setRrule(false)
                    }}>Cancel</Button>
                    <Button primary type='submit'>Submit</Button>
                  </Form>
                </Modal.Content>
              </Modal>
            )}
          </>
        ) : (
          <Segment>
            <div className='blankslate'>
              <Dimmer active inverted>
                <Loader inverted>Loading schedules</Loader>
              </Dimmer>
              <Image src={shortParagraph} width='100%' height='66' />
            </div>
          </Segment>
        )}
      </div>

    </Container>
  )
}

export default DragDropContext(HTML5Backend)(Edit)
