import React from 'react'
import ReactDOM from 'react-dom'

import _ from 'lodash'

// styles
import './styles.css'

// semantic-ui
import {
  Icon
} from 'semantic-ui-react'

const RemoveButton = props => {
  // props
  const { handleDelete } = props

  return (
    <Icon
      className='trash-can'
      onClick={() => handleDelete()}
      name='trash alternate'
      size='large'
    />
  )
}

export default RemoveButton
