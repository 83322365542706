import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import session from '../../../../session'

// images
import notFound from './images/404.svg'
import networkError from './images/network.svg'
import badRequest from './images/bad-request.svg'

// styles
import './index.css'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

class NoMatch extends Component {
  render() {
    return (
      <Semantic.Container className='NoMatch'>
        <Semantic.Header as='h3' attached='top'>404 - Not Found</Semantic.Header>
        <Semantic.Segment attached='bottom' style={{ textAlign: 'center' }}>
          <Semantic.Image src={notFound} centered size='tiny' />
          We can't seem to find the page you're looking for.
          <br />
          <a className='linky' href='/'>Click here</a> to go back to the home page.
        </Semantic.Segment>
      </Semantic.Container>
    )
  }
}

// with router support
NoMatch = withRouter(NoMatch)

class NetworkError extends Component {
  refresh = () => {
    window.location.reload()
  }
  logout = () => {
    session.logout()
    window.location.reload()
  }
  render () {
    return (
      <Semantic.Container className='NetworkError'>
        <Semantic.Header as='h3' attached='top'>Network Error</Semantic.Header>
        <Semantic.Segment attached='bottom' style={{ textAlign: 'center' }}>
          <Semantic.Image src={networkError} centered size='tiny' />
          Server is not responding. <span className='linky' onClick={this.refresh}>Click here</span> to try again.
        </Semantic.Segment>
        <p style={{ textAlign: 'center' }}>
          <small>
            Still having problems?
            <br/>
            <span className='linky' onClick={this.logout}>Click</span> to clear your cache and reload the page.
          </small>
        </p>
      </Semantic.Container>
    )
  }
}

class BadRequestComponent extends Component {
  refresh = () => {
    console.log(this.props)
    // window.location.reload()
  }
  render () {
    return (
      <Semantic.Container className='BadRequest'>
        <Semantic.Header as='h3' attached='top'>Bad Request</Semantic.Header>
        <Semantic.Segment attached='bottom' style={{ textAlign: 'center' }}>
          <Semantic.Image src={badRequest} centered size='tiny' />
          The server was unable to process the request sent by the client due to invalid syntax. <span className='linky' onClick={this.refresh}>Click here</span> to try again.
        </Semantic.Segment>
        <p style={{ textAlign: 'center' }}>
          <small>
            Still having problems?
            <br/>
            <span className='linky' onClick={this.logout}>Click</span> to clear your cache and reload the page.
          </small>
        </p>
      </Semantic.Container>
    )
  }
}

const BadRequest = withRouter(BadRequestComponent)

export { NoMatch, NetworkError, BadRequest }
