import React, { Component } from 'react'
import session from '../../session'
import axios from 'axios'

// react router
import { withRouter } from 'react-router-dom'

// styles
import './index.css'

// images
import logo from '../../images/logo.svg'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

class Login extends Component {
  constructor (props) {
    super(props)

    this.servers = [{
      key: 'development',
      value: 'http://localhost:1337',
      text: 'Development - Local'
    }, {
      key: 'development-office',
      value: 'http://demo.beg.munogu.io',
      text: 'Development - Office Network'
    }, {
      key: 'development-home',
      value: 'http://192.168.1.3:1337',
      text: 'Development - Home Network'
    }, {
      key: 'zerotier-eymen',
      value: 'http://10.190.73.12:1337',
      text: 'ZeroTier - Eymen (10.190.73.12)'
    }, {
      key: 'production',
      value: 'https://kiosk-server.munogu.io',
      text: 'Production'
    }]

    // let server = this.servers.find(server => server.key === process.env.NODE_ENV) || this.servers[0]
    let server = 'https://server.kiosk.munogu.io'
    this.state = {
      ...props.location.state,
      loading: false,
      email: null,
      password: null,
      server,
      error: false,
    }
    session.set('server', this.state.server)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange (event, { name, value }) {
    this.setState({
      error: false,
      [name]: value
    })
    // set server
    if (name === 'server') session.set('server', value)
  }
  handleSubmit (event) {
    event.preventDefault()

    this.setState({ loading: true, error: false })

    return axios.post(this.state.server + '/login', {
      email: this.state.email,
      password: this.state.password
    }, {
      credentials: 'same-origin'
    }).then(async res => {
      if (res.status === 200) {
        this.setState({ loading: false, error: true })
        session.login({ ...res.data, server: this.state.server })
        this.props.history.push('/')
      } else {
        this.setState({ loading: false, error: true })
      }
    }).catch(err => {
      console.error('an error occured while logging in')
      console.error(err)
      this.setState({ loading: false, error: true })
    })
  }
  render() {
    return (
      <div className='Login'>
        <Semantic.Grid verticalAlign='middle' centered>
          <Semantic.Grid.Row>
            <Semantic.Grid.Column mobile={15} tablet={8} computer={6}>
              <Semantic.Header as='h2' textAlign='center'>
                <Semantic.Image circular src={logo} className='logo' />
                KIOSK
              </Semantic.Header>
              {
                this.state.error ? <Semantic.Message negative>
                  <Semantic.Message.Header>Login failed!</Semantic.Message.Header>
                  <p>Invalid email or password.</p>
                </Semantic.Message> : ''
              }
              {
                this.state.expired ? <Semantic.Message color='yellow'>
                  <Semantic.Message.Header>Session expired!</Semantic.Message.Header>
                  <p>Your session token has expired! Please login again to continue.</p>
                </Semantic.Message> : ''
              }
              <Semantic.Segment>
                {this.state.loading ? <Semantic.Dimmer active inverted>
                  <Semantic.Loader inverted>Logging in</Semantic.Loader>
                </Semantic.Dimmer> : ''}
                <Semantic.Form onSubmit={this.handleSubmit}>
                  <Semantic.Form.Field>
                    <Semantic.Form.Input name='email' type='email' label='Email' placeholder='Your email address' required onChange={this.handleChange} />
                  </Semantic.Form.Field>
                  <Semantic.Form.Field>
                    <Semantic.Form.Input name='password' type='password' label='Password' placeholder='Your password' required onChange={this.handleChange} />
                  </Semantic.Form.Field>
                  <Semantic.Form.Field required>
                    <Semantic.Form.Input name='server' type='text' label='Server' value={this.state.server} placeholder='Server endpoint' required onChange={this.handleChange} />
                  </Semantic.Form.Field>
                  <div className='clear'>
                    <Semantic.Button type='submit' floated='right'>Login</Semantic.Button>
                  </div>
                </Semantic.Form>
              </Semantic.Segment>
            </Semantic.Grid.Column>
          </Semantic.Grid.Row>
        </Semantic.Grid>
      </div>
    )
  }
}

export default withRouter(Login)
