import React, { Component } from 'react'
import { version } from '../../../../../package.json'

// styles
import './index.css'

class Footer extends Component {
  render () {
    return (
      <div className='Footer'>
        Kiosk Admin v{version} - <a href='https://munogu.com' target='_blank' rel='noopener noreferrer'>Munogu</a>
      </div>
    )
  }
}

export default Footer
