import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

// graphql
import gql from 'graphql-tag'
import { useQuery, useSubscription } from '@apollo/react-hooks'

// semantic-ui
import { Container, Segment, Loader, Dimmer, Image } from 'semantic-ui-react'

// images
import shortParagraph from '../../../../images/blankslate/short-paragraph.png'

// scenes
import LocationList from './scenes/LocationList'
import LocationDetail from './scenes/LocationDetail'
import DeviceDetail from './scenes/DeviceDetail'

// styles
import './index.css'

// graphql queries
const QUERY = gql`
query Me($slug: Slug!) {
  me {
    id
    email
    name
    organization(slug: $slug) {
      id
      slug
      name
      playlists {
        id
        name
      }
      schedules {
        id
        name
      }
      locations {
        id
        slug
        name
        coordinate {
          latitude
          longitude
        }
        created_at
        devices {
          id
          online
          name
          specs
          modules
          config
          location_id
          playlist_id
          schedule_id
          meta
          created_at
        }
      }
      medias {
        edges {
          node {
            id
            url
            name
            mime
          }
        }
      }
    }
  }
}`

const DEVICE_ONLINE_SUBSCRIPTION = gql`
subscription DeviceOnline($organization: ID) {
  device_online(organization: $organization) {
    device_id
    location_id
    organization_id
    online
  }
}`

function Location (props) {
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { slug: props.organization.slug },
    skip: false,
    fetchPolicy: 'network-only'
  })

  // state
  const [me, setMe] = useState(null)
  const [firstMount, setFirstMount] = useState(true)

  // listen data change
  const prevProps = props
  useEffect(() => {
    setMe(data ? data.me : null)
  }, [data])

  // listen route change
  useEffect(() => {
    if (firstMount) return setFirstMount(false)
    refetch({ variables: { slug: props.organization.slug } })
  }, [props.location.pathname])

  useSubscription(DEVICE_ONLINE_SUBSCRIPTION, {
    variables: { organization: props.organization.id },
    onSubscriptionData: ({ subscriptionData }) => {
      if (!me) return
      let deviceOnline = subscriptionData.data.device_online
      me.organization.locations.find(location => {
        let found = location.devices.find(device => {
          if (device.id === deviceOnline.device_id) {
            device.online = deviceOnline.online
            return true
          } else {
            return false
          }
        })
        return !!found
      })
      setMe({ ...me })
      refetch()
    }
  })

  return (
    <Container className='Location'>
      {!me && loading && (
        <Segment>
          <div className='blankslate'>
            <Dimmer active inverted>
              <Loader inverted>Loading locations</Loader>
            </Dimmer>

            <Image src={shortParagraph} width='100%' height='66' />
          </div>
        </Segment>
      )}

      {me && !loading && (
        <Switch>
          <Route
            path={`${props.match.path}/:location/devices/:device`}
            render={(p) => (<DeviceDetail me={me} {...p} />)}
          />
          <Route
            path={`${props.match.path}/:location`}
            render={(p) => (<LocationDetail me={me} {...p} />)}
          />
          <Route
            path={`${props.match.path}`}
            render={(p) => (<LocationList me={me} {...p} />)}
          />
        </Switch>
      )}
    </Container>
  )
}

export default Location
