import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import Moment from 'react-moment'
import { orgRoute, slugify } from '../../../../../../utils'

// semantic-ui
import { Header, Icon, Image, List, Label, Segment, Form, Menu, Modal } from 'semantic-ui-react'

// styles
import './index.css'

const CREATE_LOCATION = gql`
mutation CreateLocation($input: CreateLocationInput!) {
  create_location: create_location(input: $input) {
    id
    slug
    name
    devices {
      id
    }
  }
}`
// *** missing fields ***
// coordinate {
//   latitude
//   longitude
// }

function LocationList (props) {
  let [modal, setModal] = useState({
    open: false,
    name: '',
    slug: '',
    autoSlug: ''
  })

  // mutations
  const [createLocation] = useMutation(CREATE_LOCATION)

  return (
    <div className='LocationList'>
      <Header as='h2'>
        <Icon name='map' />
        <Header.Content>
          Locations
          <Header.Subheader>Manage your locations &amp; devices</Header.Subheader>
        </Header.Content>
      </Header>

      {/* create form */}
      <Modal className='LocationListModal' size='large' closeIcon open={modal.open} onClose={e => setModal({ ...modal, open: false })}>
        {/* <Modal.Header>Create new location</Modal.Header> */}
        <Modal.Content>
          <Modal.Description>
            <Form id='location-modal-form' onSubmit={async e => {
              let { data } = await createLocation({
                variables: {
                  input: {
                    slug: modal.slug || modal.autoSlug,
                    name: modal.name,
                    organization_id: props.me.organization.id,
                    // coordinate: {
                    //   latitude: modal.coordinate.latitude,
                    //   longitude: modal.coordinate.longitude
                    // }
                  }
                }
              })

              let path = orgRoute(props.me.organization, [
                'locations',
                data.create_location.slug
              ])
              props.history.push(path)
            }}>
              <Form.Group widths='equal'>
                <Form.Input
                  onChange={(e, target) => {
                    setModal({
                      ...modal,
                      name: target.value,
                      autoSlug: slugify(target.value)
                    })
                  }}
                  value={modal.name}
                  placeholder='How do you want to call this location?'
                  required
                  label='Name'
                />
                <Form.Input
                  onChange={(e, target) => {
                    setModal({
                      ...modal,
                      slug: target.value
                    })
                  }}
                  value={modal.slug || modal.autoSlug}
                  placeholder='Machine readable name'
                  required
                  label='Slug'
                />
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Form.Button primary form='location-modal-form' type='submit'>Create location</Form.Button>
        </Modal.Actions>
      </Modal>

      <Menu attached='top'>
        <Menu.Menu position='right'>
          <Menu.Item onClick={e => setModal({ ...modal, open: true })}>
            <Icon name='plus' />
            Create new location
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      {/* locations list */}
      {props.me.organization.locations.length > 0 && (
        <Segment attached='bottom'>
          <List divided relaxed>
            {props.me.organization.locations.map(location => (
              <List.Item key={'location-' + location.id}>
                <List.Content floated='right'>
                <Label horizontal>
                  {location.devices.filter(({ online }) => !!online).length}/{location.devices.length} {location.devices.length === 1 ? 'device' : 'devices'}
                </Label>
                </List.Content>
                <Image avatar src={'https://ui-avatars.com/api/?background=fbbd08&color=fff&name=' + location.name} />
                <List.Content>
                  <List.Header as={NavLink} to={orgRoute(props.me.organization, [
                    'locations',
                    location.slug
                  ])}>
                    {location.name}
                  </List.Header>
                  <List.Description as='a'>Created <Moment fromNow ago>{location.created_at}</Moment> ago</List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Segment>
      )}

      {/* no locations */}
      {props.me.organization.locations.length === 0 && (
        <Segment attached='bottom' className='blankslate'>
          <Header as='h4' icon>
            <Icon name='x' />
            No locations found
            <Header.Subheader>Create a new location to get started</Header.Subheader>
          </Header>
        </Segment>
      )}
    </div>
  )
}

export default LocationList
