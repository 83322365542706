import React, { useState, useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

// graphql
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

// semantic-ui
import {
  Container,
  Segment,
  Dimmer,
  Loader,
  Image
} from 'semantic-ui-react'

// images
import shortParagraph from '../../../../images/blankslate/short-paragraph.png'

// components
import { BadRequestComponent } from '../../../App/components/Error'

// scenes
import Upsert from './scenes/Upsert'
import List from './scenes/List'

// styles
import './index.css'

const ME = gql`{
  me {
    id
    name
    username
    email
    role
    meta
    organizations {
      id
      name
      slug
      locations {
        id
        name
      }
      users {
        id
        name
        username
        email
        role
        meta
        created_at
        organizations {
          id
          name
          locations {
            id
            name
          }
        }
      }
    }
  }
}`

function Users(props) {
  const { loading, error, data, refetch } = useQuery(ME, {
    fetchPolicy: 'network-only'
  })

  // state
  const [me, setMe] = useState(null)
  const [firstMount, setFirstMount] = useState(true)

  // listen data change
  const prevProps = props
  useEffect(() => {
    setMe(data ? data.me : null)
  }, [data])

  // listen route change
  useEffect(() => {
    if (firstMount) return setFirstMount(false)
    refetch()
  }, [props.location.pathname])

  return (
    <Container className='User'>
      {error && (
        <BadRequestComponent />
      )}

      {loading && (
        <Segment>
          <div className='blankslate'>
            <Dimmer active inverted>
              <Loader inverted>Loading user(s)</Loader>
            </Dimmer>
            <Image src={shortParagraph} width='100%' height='66' />
          </div>
        </Segment>
      )}

      {me && (
        <Switch>
          {/* list users */}
          <Route
            exact
            path={`/orgs/${props.match.params.organization}/users`}
            render={(p) => (<List {...p} me={me} orgSlug={props.organization.slug} />)}
          />
          {/* upsert user */}
          <Route
            path={`/orgs/${props.match.params.organization}/users/:id`}
            render={(p) => (<Upsert {...p} me={me} path={props.location.pathname.split('/').slice(-1)[0]} orgSlug={props.organization.slug} />)}
          />
        </Switch>
      )}
    </Container>
  )
}

export default withRouter(Users)
