export function orgRoute (org, parts) {
  if (Array.isArray(parts) === false && parts) {
    parts = [parts]
  } else if (Array.isArray(parts) === false) {
    parts = []
  }
  parts = ['orgs', org.slug].concat(parts)
  return '/' + parts.join('/')
}

export const timezones = [{
  key: 'america-los-angeles',
  value: 'America/Los_Angeles',
  text: 'America/Los Angeles'
}, {
  key: 'america-new-york',
  value: 'America/New_York',
  text: 'America/New York'
}, {
  key: 'europe-london',
  value: 'Europe/London',
  text: 'Europe/London'
}, {
  key: 'europe-paris',
  value: 'Europe/Paris',
  text: 'Europe/Paris'
}, {
  key: 'europe-rome',
  value: 'Europe/Rome',
  text: 'Europe/Rome'
}, {
  key: 'europe-belgrade',
  value: 'Europe/Belgrade',
  text: 'Europe/Belgrade'
}, {
  key: 'europe-sofia',
  value: 'Europe/Sofia',
  text: 'Europe/Sofia'
}, {
  key: 'europe-istanbul',
  value: 'Europe/Istanbul',
  text: 'Europe/Istanbul'
}]

export function slugify (text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '')
}
