import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Moment from 'react-moment'
import { orgRoute } from '../../../../utils'

// graphql
import { graphql } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import gql from 'graphql-tag'

// images
import shortParagraph from '../../../../images/blankslate/short-paragraph.png'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

// styles
import './Playlist.css'

class Playlist extends Component {
  constructor (params) {
    super(params)

    this.state = {
      initial: true,
      modal: {
        open: false,
        name: ''
      }
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.inputModal = this.inputModal.bind(this)
    this.submitModal = this.submitModal.bind(this)
  }
  componentDidMount () {
    this.props.data.refetch()
  }
  openModal () {
    let modal = Object.assign({}, this.state.modal, { open: true })
    this.setState({ modal })
  }
  closeModal () {
    let modal = Object.assign({}, this.state.modal, { open: false })
    this.setState({ modal })
  }
  inputModal (event) {
    let modal = Object.assign({}, this.state.modal, { name: event.target.value })
    this.setState({ modal })
  }
  submitModal () {
    return this.props.create_playlist({
      variables: {
        input: {
          name: this.state.modal.name,
          organization_id: this.props.data.me.organization.id
        }
      }
    }).then(({ data }) => {
      let modal = Object.assign({}, this.state.modal, { name: '' })
      this.setState({ modal })
      this.props.data.refetch()
      this.closeModal()

      let pathname = orgRoute(this.props.data.me.organization, [
        'playlists',
        data.create_playlist.id
      ])
      this.props.history.push(pathname)
    })
  }
  render() {
    return (
      <Semantic.Container className='Playlist'>
        <Semantic.Header as='h2'>
          <Semantic.Icon name='ordered list' />
          <Semantic.Header.Content>
            Playlists
            <Semantic.Header.Subheader>Categorize your content with playlists</Semantic.Header.Subheader>
          </Semantic.Header.Content>
        </Semantic.Header>

        {this.props.data.me && (
          <Semantic.Menu attached='top'>
            <Semantic.Menu.Menu position='right'>
              <Semantic.Menu.Item onClick={this.openModal}>
                <Semantic.Icon name='plus' />
                Create new playlist
              </Semantic.Menu.Item>
            </Semantic.Menu.Menu>
          </Semantic.Menu>
        )}

        {this.props.data.me && this.props.data.me.organization.playlists.length > 0 && (
          <Semantic.Segment attached>
            <Semantic.List divided relaxed>
              {this.props.data.me.organization.playlists.map((playlist, index) => (
                <Semantic.List.Item key={'row-' + index} onClick={e => this.props.history.push(orgRoute(this.props.data.me.organization, ['playlists', playlist.id]))}>
                  <Semantic.List.Content floated='right'>
                    <Semantic.Label>
                      <Semantic.Icon name='clock outline' />
                      {playlist.duration ? (playlist.duration / 1000).toFixed(1) : 0} sec
                    </Semantic.Label>
                  </Semantic.List.Content>
                  <Semantic.Image avatar src={'https://ui-avatars.com/api/?background=fbbd08&color=fff&name=' + playlist.count} />
                  <Semantic.List.Content>
                    <Semantic.List.Header as={NavLink} to={orgRoute(this.props.data.me.organization, ['playlists', playlist.id])}>
                      {playlist.name}
                    </Semantic.List.Header>
                    <Semantic.List.Description as='a'>Created <Moment fromNow ago>{playlist.created_at}</Moment> ago</Semantic.List.Description>
                  </Semantic.List.Content>
                </Semantic.List.Item>
              ))}
            </Semantic.List>
          </Semantic.Segment>
        )}

        {this.props.data.me && this.props.data.me.organization.playlists.length === 0 && (
          <Semantic.Segment className='blankslate' attached>
            <Semantic.Header as='h4' icon style={{ margin: 0 }}>
              <Semantic.Icon name='x' />
              No results found
              <Semantic.Header.Subheader>Drag your media files anywhere on the page to upload</Semantic.Header.Subheader>
            </Semantic.Header>
          </Semantic.Segment>
        )}

        {this.props.data.loading && !this.props.data.me && (
          <Semantic.Segment>
            <div className='blankslate'>
              <Semantic.Dimmer active inverted>
                <Semantic.Loader inverted>Loading playlists</Semantic.Loader>
              </Semantic.Dimmer>

              <Semantic.Image src={shortParagraph} width='100%' height='66' />
            </div>
          </Semantic.Segment>
        )}

        <Semantic.Modal size='tiny' open={this.state.modal.open} onClose={this.closeModal} closeIcon>
          <Semantic.Modal.Content>
            <Semantic.Modal.Description>
              <Semantic.Form id='playlist-modal-form' onSubmit={this.submitModal}>
                <Semantic.Form.Field>
                  <label>Playlist name</label>
                  <input autoFocus placeholder='Summer megahit mix 1997' value={this.state.modal.name} onChange={this.inputModal} required />
                  <p>Give it a descriptive name</p>
                </Semantic.Form.Field>
              </Semantic.Form>
            </Semantic.Modal.Description>
          </Semantic.Modal.Content>
          <Semantic.Modal.Actions>
            <Semantic.Button primary form='playlist-modal-form' type='submit'>Get started</Semantic.Button>
          </Semantic.Modal.Actions>
        </Semantic.Modal>
      </Semantic.Container>
    )
  }
}

// with data component
const PlaylistWithData = compose(
  graphql(gql`query Playlist($slug: Slug!) {
    me {
      id
      email
      name
      organization(slug: $slug) {
        id
        slug
        name
        playlists {
          id
          name
          count
          duration
          created_at
        }
      }
    }
  }`, {
    options: ownProps => {
      return {
        notifyOnNetworkStatusChange: true,
        variables: {
          slug: ownProps.match.params.organization
        }
      }
    }
  }),
  graphql(gql`mutation CreatePlaylist($input: CreatePlaylistInput!) {
    create_playlist: create_playlist(input: $input) {
      id
    }
  }`, {
    name: 'create_playlist',
    options: ownProps => {
      return {
        notifyOnNetworkStatusChange: true,
        forceFetch: true
      }
    }
  })
)(Playlist)

export default PlaylistWithData
