import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { SketchPicker } from 'react-color'
import _ from 'lodash'
import numeral from 'numeral'
import QRCode from 'qrcode.react'

import QueryBuilder from './components/QueryBuilder'

import Media from '../Media'
import Video from '../../../../components/Video'
import { orgRoute } from '../../../../utils'

// graphql
import { graphql } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import gql from 'graphql-tag'

// images
import shortParagraph from '../../../../images/blankslate/short-paragraph.png'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

// styles
import './PlaylistEdit.css'

// aspect ratio options
const aspectRatios = [ '1:1', '4:3', '16:10', '16:9', '3:4', '10:16', '9:16' ]
class AspectRatio extends Component {
  constructor (args) {
    super(args)

    this.width = this.props.width || 100
    this.height = this.props.height || 100
    this.state = { parent: null }
  }
  componentDidMount () {
    // set parent node, we will need this
    // later on for dimension exceeds check
    let parent = ReactDOM.findDOMNode(this).parentNode
    this.setState({ parent })
  }
  render () {
    let ratio = this.props.ratio || (16 / 9)
    return (
      <div className='aspect-ratio' style={{
        width: (ratio >= 1 ? this.width : this.height * ratio),
        height: (ratio >= 1 ? this.width / ratio : this.height),
        margin: '0 auto',
        overflow: 'hidden',
        backgroundColor: this.props.backgroundColor || '#fff'
      }}>
        {this.props.children}
      </div>
    )
  }
}

// conditional fields
const queryFields = {
  groups: [

    {
      header: {
        name: 'age',
        label: 'Age'
      },
      fields: [
        {
          name: 'children',
          label: 'Children',
          operators: [
            { name: '>', label: '>' },
            { name: '=', label: '=' },
            { name: '<', label: '<' }
          ],
          inputType: 'number',
          minValue: 0,
          defaultValue: 1
        },
        {
          name: 'teenagers',
          label: 'Teenagers',
          operators: [
            { name: '>', label: '>' },
            { name: '=', label: '=' },
            { name: '<', label: '<' }
          ],
          inputType: 'number',
          minValue: 0,
          defaultValue: 1
        },
        {
          name: 'young-adults',
          label: 'Young Adults',
          operators: [
            { name: '>', label: '>' },
            { name: '=', label: '=' },
            { name: '<', label: '<' }
          ],
          inputType: 'number',
          minValue: 0,
          defaultValue: 1
        },
        {
          name: 'adults',
          label: 'Adults',
          operators: [
            { name: '>', label: '>' },
            { name: '=', label: '=' },
            { name: '<', label: '<' }
          ],
          inputType: 'number',
          minValue: 0,
          defaultValue: 1
        },
        {
          name: 'elderly',
          label: 'Elderly',
          operators: [
            { name: '>', label: '>' },
            { name: '=', label: '=' },
            { name: '<', label: '<' }
          ],
          inputType: 'number',
          minValue: 0,
          defaultValue: 1
        }
      ]
    },

    {
      header: {
        name: 'gender',
        label: 'Gender'
      },
      fields: [
        {
          name: 'male',
          label: 'Male',
          operators: [
            { name: '>', label: '>' },
            { name: '=', label: '=' },
            { name: '<', label: '<' }
          ],
          inputType: 'number',
          minValue: 0,
          defaultValue: 1
        },
        {
          name: 'female',
          label: 'Female',
          operators: [
            { name: '>', label: '>' },
            { name: '=', label: '=' },
            { name: '<', label: '<' }
          ],
          inputType: 'number',
          minValue: 0,
          defaultValue: 1
        }
      ]
    },

    {
      header: {
        name: 'time',
        label: 'Time'
      },
      fields: [
        {
          name: 'day-of-week',
          label: 'Day of Week',
          operators: [
            { name: '=', label: 'is' },
            { name: '!=', label: 'is not' },
            { name: '=[]', label: 'is one of the', multipleSelect: true },
            { name: '!=[]', label: 'is not one of the', multipleSelect: true }
          ],
          inputType: 'select',
          values: [
            { name: 'monday', label: 'Monday' },
            { name: 'tuesday', label: 'Tuesday' },
            { name: 'wednesday', label: 'Wednesday' },
            { name: 'thurday', label: 'Thursday' },
            { name: 'friday', label: 'Friday' },
            { name: 'saturday', label: 'Saturday' },
            { name: 'sunday', label: 'Sunday' }
          ],
          defaultValue: 'monday',
        },
        {
          name: 'hour-of-day',
          label: 'Hour of day',
          operators: [
            { name: '>', label: '>' },
            { name: '=', label: '=' },
            { name: '<', label: '<' }
          ],
          inputType: 'number',
          minValue: 0,
          maxValue: 23,
          defaultValue: 12
        }
      ]
    }

  ]
}


class Editor extends Component {
  constructor (args) {
    super(args)

    this.state = {
      me: JSON.parse(JSON.stringify(this.props.data.me)),
      selected: null,
      mediaModal: false,
      defaultRatio: false,
      limitReached: false,
      imageModal: false
    }
    // changes are saved after a timeout to avoid
    // making requests for every single change
    this.playlistChangeTimeout = null
    this.qrCodeValueTimeout = null

    // limit playlist number of items
    this.playlistLimitation = 10
  }
  componentDidMount () {
    if (this.state.me) {
      this.props.data.refetch().then(() => {
        let me = JSON.parse(JSON.stringify(this.props.data.me))
        this.setState({ me })
      })
      let state = this.state
      // set default aspect ratio based on most of the devices
      if (!(this.props.data.me.organization.playlist && this.props.data.me.organization.playlist.meta && this.props.data.me.organization.playlist.meta.ratio)) {
        const devices = (this.props.data.me.organization && this.props.data.me.organization.devices) || []
        // recalculate aspect ratios
        let ratios = aspectRatios.map(rat => {
          let ratArr = rat.split(':')
          ratArr = ratArr.map(r => parseInt(r))
          return ratArr[0] / ratArr[1]
        })
        let awailableRatios = ratios.map(r => 0)
        devices.forEach(device => {
          if (device.modules && device.modules.display) {
            let { width, height } = device.modules.display
            let curRatio = width / height
            // aproximate equality
            ratios.forEach((rat, i) => {
              if (curRatio < (rat + 0.001) && curRatio > (rat - 0.001)) {
                awailableRatios[i]++
              }
            })
          }
        })
        const indexOfMax = arr => {
          if (arr.length === 0) {
            return -1
          }
          let max = arr[0]
          let maxIndex = 0
          arr.forEach((el, i) => {
            if (el > max) {
              maxIndex = i
              max = el
            }
          })
          return {maxIndex, max}
        }
        let result = indexOfMax(awailableRatios)
        if (result && result.max > 0) {
          // set default ratio state
          state = { ...this.state, defaultRatio: ratios[result.maxIndex] }
          this.setState({ ...state })
          // write to db
          this.playlistChangeHandler(false, {name: 'meta.ratio', value: ratios[result.maxIndex]})
        }
      }
      // calc limit playlist
      this.handleLimit((this.props.data.me.organization.playlist.items ? this.props.data.me.organization.playlist.items.length : 0, state))
    }
  }
  handleLimit = (listLength, state) => {
    this.setState(
      {
        ...state,
        limitReached: listLength >= this.playlistLimitation
      }
    )
  }
  playlistChangeHandler = (e, { name, value }) => {
    // update state
    let me = this.state.me
    const playlist = me.organization.playlist
    if (e && e.target.name) {
      _.set(playlist, e.target.name, e.target.value)
    } else if (name) {
      _.set(playlist, name, value)
    }
    this.setState({ me })
    // graphql update request
    clearTimeout(this.playlistChangeTimeout)
    this.playlistChangeTimeout = setTimeout(() => {
      this.props.update_playlist({
        variables: {
          input: {
            id: playlist.id,
            name: playlist.name,
            meta: playlist.meta
          }
        }
      })
    }, 750)
  }
  deletePlaylist = e => {
    if (e) e.preventDefault()
    if (!window.confirm('Are you sure?')) return
    this.props.delete_playlist({
      variables: {
        input: { id: this.state.me.organization.playlist.id }
      }
    }).catch(err => {
      console.error(err)
    }).then(() => {
      let pathname = orgRoute(this.props.data.me.organization, 'playlists')
      this.props.history.push(pathname)
    })
  }
  playlistItemChangeHandler = (e, { name, value }) => {
    // update state
    const selected = this.state.selected
    if (e && e.target && e.target.name) {
      _.set(selected, e.target.name, e.target.value)
    } else if (name) {
      _.set(selected, name, value)
    }
    this.setState({ selected })
    let { id, duration, media_id, order, meta } = selected
    // reduce meta.image
    if (meta && meta.image) {
      let { id: imgId, displaySize: imgSize, position: imgPos } = meta.image
      meta.image = {
        id: imgId,
        displaySize: imgSize,
        position: imgPos
      }
    }
    // prevent deleting media_id
    let input = {
      id,
      duration,
      order,
      meta
    }
    if (media_id) input.media_id = media_id.id
    if (id) return this.props.update_playlist_item({
      variables: {
        input
      }
    })
  }
  selectPlaylistItem = selected => {
    if (this.state.selected && this.state.selected === selected) {
      this.setState({ selected: null })
    } else {
      this.setState({ selected })
    }
  }
  addPlaylistItem = e => {
    // set state
    let me = this.state.me
    let items = me.organization.playlist.items
    // create playlist item
    let item = {
      order: items.length,
      duration: 5000,
      meta: {
        backgroundColor: '#fff',
        objectFit: 'cover'
      },
      playlist_id: this.state.me.organization.playlist.id,
      created_at: new Date()
    }
    items.push(item)
    this.setState({ me, selected: item })
    // handle playlist limit
    let newState = { ...this.state, me, selected: item }
    this.handleLimit(items.length, newState)
    // graphql mutation
    const playlist_id = me.organization.playlist.id
    return this.props.create_playlist_item({
      variables: {
        input: {
          duration: item.duration,
          media_id: item.media ? item.media.id : null,
          order: item.order,
          meta: item.meta,
          playlist_id
        }
      }
    }).then(({ data }) => {
      item.id = data.create_playlist_item.id
      this.setState({ me })
    })
  }
  deletePlaylistItem = id => {
    if (!window.confirm('Are you sure?')) return
    // remove playlist item from array
    let index = this.state.me.organization.playlist.items.findIndex(item => {
      return item.id === id
    })
    if (index === -1) return
    let me = this.state.me
    let items = me.organization.playlist.items
    items.splice(index, 1)
    // set selected
    let selected = null
    if (items[index]) {
      selected = items[index]
    } else if (items[index - 1]) {
      selected = items[index - 1]
    }
    this.setState({ selected, me })
    // handle playlist limit
    let newState = { ...this.state, selected, me }
    this.handleLimit(items.length, newState)
    // remove playlist item from server
    return this.props.delete_playlist_item({
      variables: {
        input: { id }
      }
    }).catch(err => {
      console.error(err)
      alert('An error occured!')
    })
  }
  mediaSelectHandler = media => {
    let selected = this.state.selected
    if (selected) {
      selected.media = media
      selected.duration = media.duration || 5000
    }
    this.setState({ mediaModal: false, selected })
    return this.props.update_playlist_item({
      variables: {
        input: {
          id: selected.id,
          duration: selected.duration,
          media_id: selected.media ? selected.media.id : null
        }
      }
    })
  }

  imageSelectHandler = image => {
    let selected = this.state.selected
    let { id, position, displaySize } = selected.meta.image
    if (selected) {
      selected.meta.image = { ...selected.meta.image, ...image }
    }
    this.setState({ imageModal: false, selected })
    return this.props.update_playlist_item({
      variables: {
        input: {
          id: selected.id,
          meta: { ...selected.meta, image: { id, position, displaySize } }
        }
      }
    })
  }

  handleQueryChange = e => {
    if (!e) {
      // switched off - delete meta.query
      let reducedMeta = _.omit(this.state.selected.meta, ['query'])
      this.playlistItemChangeHandler(null, { name: 'meta', value: reducedMeta })
    } else {
      // query changed
      this.playlistItemChangeHandler(null, { name: 'meta.query', value: e })
    }
  }

  render () {
    // aditional image data
    let aditionalImageInfo = false
    if (this.state.selected && this.state.selected.meta && this.state.selected.meta.image && this.state.selected.meta.image.id) {
      let imageId = this.state.selected.meta.image.id
      let medias = this.props.data.me.organization.medias.edges.map(edge => edge.node)
      let media = medias.find(med => med.id === imageId)
      aditionalImageInfo = media
    }
    return (
      <div className='editor'>
        <Semantic.Menu color='blue' className='top' stackable>
          <Semantic.Menu.Item>
            <Semantic.Input type='text' icon='font' iconPosition='left' name='name' value={this.state.me.organization.playlist.name} onChange={this.playlistChangeHandler} />
          </Semantic.Menu.Item>
          <Semantic.Menu.Item>
            <Semantic.Icon name='tv' />
            <Semantic.Dropdown
              scrolling
              name='meta.ratio'
              value={this.state.me.organization.playlist.meta.ratio || this.state.defaultRatio || 16/9}
              onChange={this.playlistChangeHandler}
              options={aspectRatios.map(text => {
                let [w, h] = text.split(':')
                let value = w / h
                return {
                  key: 'ratio' + text,
                  text,
                  value
                }
              })}
            />
          </Semantic.Menu.Item>
          {!this.state.limitReached ? (
            <Semantic.Menu.Item onClick={this.addPlaylistItem}>
              <Semantic.Icon name='plus' />
              Add new item
            </Semantic.Menu.Item>)
          : (
            <Semantic.Menu.Item>
              <Semantic.Icon name='list' />
              Playlist limit reached
            </Semantic.Menu.Item>)
          }
          <Semantic.Menu.Menu position='right'>
            <Semantic.Menu.Item onClick={this.deletePlaylist} style={{ color: '#ec1c24' }}>
              <Semantic.Icon name='trash alternate' />
              <Semantic.Responsive {...Semantic.Responsive.onlyTablet}>
                Delete playlist
              </Semantic.Responsive>
            </Semantic.Menu.Item>
          </Semantic.Menu.Menu>
        </Semantic.Menu>

        {/* SIDEBAR LEFT PREVIEW */}
        <div className='main'>
          <div className='sidebar'>
            <Semantic.Menu vertical inverted attached fluid>
              {this.state.me.organization.playlist.items.map((item, index) => (
                <Semantic.Menu.Item active={this.state.selected === item} key={'item-' + item.id} onClick={e => this.selectPlaylistItem(item)}>
                  <AspectRatio backgroundColor={item.meta.backgroundColor || '#fff'} ratio={this.state.me.organization.playlist.meta.ratio}>
                    {item.media && (
                      <Semantic.Image
                        fluid
                        src={item.media.thumbnail_url}
                        style={{ objectFit: item.meta.objectFit || 'cover' }}
                        label={(item.meta && item.meta.query && item.meta.query.rules && item.meta.query.rules.length > 0) ?
                          {color: 'yellow', corner: 'left', icon: 'filter', size: 'mini'}
                          : null
                        }
                      />
                    )}
                  </AspectRatio>
                </Semantic.Menu.Item>
              ))}
            </Semantic.Menu>
          </div>

          {this.state.selected && (
            <div className='properties'>
              <Semantic.Form as={Semantic.Segment} basic>
                <Semantic.Form.Field>
                  <label>Image/Video file</label>
                  <p style={{ marginBottom: '.28rem' }}>
                    {this.state.selected.media ? (
                      _.truncate(this.state.selected.media.name)
                    ) : 'No file selected'}
                  </p>
                  <Semantic.Button fluid size='small' onClick={e => this.setState({ mediaModal: true }) }>
                    <Semantic.Icon name='image' />
                    Choose media
                  </Semantic.Button>
                </Semantic.Form.Field>
                <Semantic.Form.Field>
                  <label>Object containment</label>
                  <Semantic.Select name='meta.objectFit' value={this.state.selected.meta.objectFit || 'cover'} onChange={this.playlistItemChangeHandler} options={[{
                    key: 'contain',
                    value: 'contain',
                    text: 'Contain'
                  }, {
                    key: 'cover',
                    value: 'cover',
                    text: 'Cover'
                  }]} />
                </Semantic.Form.Field>
                {this.state.selected.media && this.state.selected.media.mime.indexOf('image') === 0 && (
                  <Semantic.Form.Field>
                    <label>
                      Duration: {numeral((this.state.selected.duration || 5000) / 1000).format('0.0')} seconds
                    </label>
                    <input type='range' min='2500' max='120000' step='500' value={this.state.selected.duration} onChange={e => {
                      let selected = this.state.selected
                      selected.duration = e.target.value ? parseInt(e.target.value) : 50000
                      this.setState({ selected })

                      clearTimeout(selected.durationChangeTimeout)
                      selected.durationChangeTimeout = setTimeout(() => {
                        this.playlistItemChangeHandler(null, { name: 'duration', value: selected.duration })
                      }, 500)
                    }} />
                  </Semantic.Form.Field>
                )}

                {this.state.selected.meta && (
                <Semantic.Form.Field>
                  <label>Transition</label>
                  <Semantic.Select
                    name='meta.transition'
                    value={this.state.selected.meta.transition || 'slide'}
                    onChange={(e, {value}) => {
                      let selected = this.state.selected
                      selected.meta.transition = value
                      // also enable transition speed if not already set
                      if (!selected.meta.transitionspeed) selected.meta.transitionspeed = 1
                      this.setState({ selected })
                      this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                    }}
                    options={[{
                      key: 'slide-left',
                      value: 'slide-left',
                      text: 'Slide left'
                    }, {
                      key: 'slide-right',
                      value: 'slide-right',
                      text: 'Slide right'
                    }, {
                      key: 'slide-down',
                      value: 'slide-down',
                      text: 'Slide down'
                    }, {
                      key: 'slide-up',
                      value: 'slide-up',
                      text: 'Slide up'
                    }, {
                      key: 'fade',
                      value: 'fade',
                      text: 'Fade'
                    }]}
                  />
                </Semantic.Form.Field>)}

                {this.state.selected.meta && (
                <Semantic.Form.Field>
                  <label>Transition speed</label>
                  <Semantic.Button.Group>
                    <Semantic.Button
                      primary={(this.state.selected.meta && this.state.selected.meta.transitionspeed === 0) || false}
                      onClick={() => {
                        let selected = this.state.selected
                        selected.meta.transitionspeed = 0
                        this.setState({ selected })
                        this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                      }}
                    >
                      slow
                    </Semantic.Button>
                    <Semantic.Button
                      primary={(this.state.selected.meta.transitionspeed && this.state.selected.meta.transitionspeed === 1) || false}
                      onClick={() => {
                        let selected = this.state.selected
                        selected.meta.transitionspeed = 1
                        this.setState({ selected })
                        this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                      }}
                    >
                      medium
                    </Semantic.Button>
                    <Semantic.Button
                      primary={(this.state.selected.meta.transitionspeed && this.state.selected.meta.transitionspeed === 2) || false}
                      onClick={() => {
                        let selected = this.state.selected
                        selected.meta.transitionspeed = 2
                        this.setState({ selected })
                        this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                      }}
                    >
                      fast
                    </Semantic.Button>
                  </Semantic.Button.Group>
                </Semantic.Form.Field>)}

                {/* Corner Image */}

                {this.state.selected.meta && (
                  <Semantic.Form.Field>
                    <label>Cover Image</label>
                    Display Cover Image:<br/>
                    <Semantic.Radio
                      label={this.state.selected.meta.image ? 'on' : 'off'}
                      toggle
                      checked={this.state.selected.meta.image ? true : false}
                      value={this.state.selected.meta.image ? 'on' : 'off'}
                      onChange={(e, {value}) => {
                        let selected = this.state.selected
                        if (value === 'off') {
                          selected.meta.image = {
                            position: 'top-center',
                            displaySize: 10
                          }
                        } else {
                          selected.meta = _.omit(selected.meta, ['image'])
                        }
                        this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                        this.setState({ selected })
                      }}
                    />
                    {this.state.selected.meta.image && (
                      <>
                      <p style={{ marginBottom: '.28rem', marginTop: '1rem' }}>
                        {this.state.selected.meta
                        && this.state.selected.meta.image
                        && this.state.selected.meta.image.id
                        && aditionalImageInfo
                        && aditionalImageInfo.name ? (
                          aditionalImageInfo.name
                        ) : 'No file selected'}
                      </p>
                      <Semantic.Button fluid size='small' onClick={e => this.setState({ imageModal: true }) }>
                        <Semantic.Icon name='image' />
                        Choose image
                      </Semantic.Button>
                        <label>Image position</label>
                      <Semantic.Select
                        name='meta.image.position'
                        value={this.state.selected.meta.image.position || 'top-middle'}
                        onChange={this.playlistItemChangeHandler}
                        options={[{
                          key: 'top-left',
                          value: 'top-left',
                          text: 'Top Left'
                        }, {
                          key: 'top-center',
                          value: 'top-center',
                          text: 'Top Center'
                        }, {
                          key: 'top-right',
                          value: 'top-right',
                          text: 'Top Right'
                        }, {
                          key: 'center',
                          value: 'center',
                          text: 'Center'
                        }, {
                          key: 'bottom-left',
                          value: 'bottom-left',
                          text: 'Bottom Left'
                        }, {
                          key: 'bottom-center',
                          value: 'bottom-center',
                          text: 'Bottom Center'
                        }, {
                          key: 'bottom-right',
                          value: 'bottom-right',
                          text: 'Bottom Right'
                        }]}
                      />
                      <br/>Size: <strong>{this.state.selected.meta.image.displaySize}</strong><br/>
                      <input
                        type='range'
                        min='5'
                        max='50'
                        step='1'
                        value={(this.state.selected.meta.image && this.state.selected.meta.image.displaySize) || '10' }
                        onChange={e => {
                          let selected = this.state.selected
                          selected.meta.image.displaySize = parseInt(e.target.value)
                          this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                          this.setState({ selected })
                        }}
                      />
                    </>)}
                  </Semantic.Form.Field>
                )}

                {/* QR CODE */}

                {this.state.selected.meta && (
                <Semantic.Form.Field>
                  <label>QR Code</label>
                  Display QR Code:<br/>
                  <Semantic.Radio
                    label={this.state.selected.meta.qr ? 'on' : 'off'}
                    toggle
                    checked={this.state.selected.meta.qr ? true : false}
                    value={this.state.selected.meta.qr ? 'on' : 'off'}
                    onChange={(e, {value}) => {
                      let selected = this.state.selected
                      if (value === 'off') {
                        selected.meta.qr = {
                          position: 'top-left',
                          value: 'https://example.com',
                          padded: false,
                          size: 5
                        }
                      } else {
                        selected.meta = _.omit(selected.meta, ['qr'])
                      }
                      this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                      this.setState({ selected })
                    }}
                  />
                  {this.state.selected.meta && this.state.selected.meta.qr && (
                    <>
                    <br/>Orientation:<br/>
                    <Semantic.Grid columns={2} className='select-qr-orientation'>
                      <Semantic.Grid.Row>
                        <Semantic.Grid.Column>
                          <Semantic.Radio
                            label='Top Left'
                            checked={
                              (this.state.selected.meta.qr && this.state.selected.meta.qr.position) ?
                                this.state.selected.meta.qr.position === 'top-left'
                                : true
                            }
                            onChange={(e, {value}) => {
                              let selected = this.state.selected
                              selected.meta.qr.position = 'top-left'
                              this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                              this.setState({ selected })
                            }}
                          />
                        </Semantic.Grid.Column>
                        <Semantic.Grid.Column>
                          <Semantic.Radio
                            label='Top Right'
                            checked={(this.state.selected.meta.qr && this.state.selected.meta.qr.position === 'top-right') || false}
                            onChange={(e, {value}) => {
                              let selected = this.state.selected
                              selected.meta.qr.position = 'top-right'
                              this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                              this.setState({ selected })
                            }}
                          />
                        </Semantic.Grid.Column>
                      </Semantic.Grid.Row>
                      <Semantic.Grid.Row>
                        <Semantic.Grid.Column>
                          <Semantic.Radio
                            label='Bottom Left'
                            checked={(this.state.selected.meta.qr && this.state.selected.meta.qr.position === 'bottom-left') || false}
                            onChange={(e, {value}) => {
                              let selected = this.state.selected
                              selected.meta.qr.position = 'bottom-left'
                              this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                              this.setState({ selected })
                            }}
                          />
                        </Semantic.Grid.Column>
                        <Semantic.Grid.Column>
                          <Semantic.Radio
                            label='Bottom Right'
                            checked={(this.state.selected.meta.qr && this.state.selected.meta.qr.position === 'bottom-right') || false}
                            onChange={(e, {value}) => {
                              let selected = this.state.selected
                              selected.meta.qr.position = 'bottom-right'
                              this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                              this.setState({ selected })
                            }}
                          />
                        </Semantic.Grid.Column>
                      </Semantic.Grid.Row>
                    </Semantic.Grid>
                    Value:<br/>
                    <Semantic.Input
                      value={this.state.selected.meta.qr.value}
                      onChange={(e, {value}) => {
                        let selected = this.state.selected
                        selected.meta.qr.value = value
                        clearTimeout(this.qrCodeValueTimeout)
                        this.qrCodeValueTimeout = setTimeout(() => {
                          // write out timeout
                          this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                        }, 400)
                        this.setState({ selected })
                      }}
                    />
                    QR padding:<br/>
                    <Semantic.Radio
                      label={this.state.selected.meta.qr.padded ? 'on' : 'off'}
                      toggle
                      checked={this.state.selected.meta.qr.padded}
                      value={this.state.selected.meta.qr.padded ? 'padded' : 'not-padded'}
                      onChange={(e, {value}) => {
                        let selected = this.state.selected
                        if (value === 'not-padded') {
                          selected.meta.qr.padded = true
                        } else {
                          selected.meta.qr.padded = false
                        }
                        this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                        this.setState({ selected })
                      }}
                    />
                    <br/>Size: <strong>{this.state.selected.meta.qr.size}</strong><br/>
                    <input
                      type='range'
                      min='1'
                      max='50'
                      step='1'
                      value={(this.state.selected.meta.qr && this.state.selected.meta.qr.size) || '5' }
                      onChange={e => {
                        let selected = this.state.selected
                        selected.meta.qr.size = parseInt(e.target.value)
                        this.playlistItemChangeHandler(null, { name: 'meta', value: selected.meta })
                        this.setState({ selected })
                      }}
                    />
                  </>)}
                </Semantic.Form.Field>)}

                <Semantic.Form.Field>
                  <label>Display conditions</label>
                  <QueryBuilder
                    on={!!this.state.selected.meta.query}
                    onChange={this.handleQueryChange}
                    queryFields={queryFields}
                    groupsLevel={1}   // 0, 1, 2 -> default = 1
                    jsonData={this.state.selected.meta.query}
                  />
                </Semantic.Form.Field>
                <Semantic.Form.Field>
                  <Semantic.Button color='red' basic fluid size='small' onClick={e => this.deletePlaylistItem(this.state.selected.id) }>
                    Delete item
                  </Semantic.Button>
                </Semantic.Form.Field>
              </Semantic.Form>
            </div>
          )}

          {this.state.selected && (
            <div className='canvas'>
              <AspectRatio width={360} height={200} backgroundColor={this.state.selected.meta.backgroundColor || '#fff'} ratio={this.state.me.organization.playlist.meta.ratio} style={{position: 'relative'}}>
                {this.state.selected.media && this.state.selected.media.mime.indexOf('image') === 0 && (
                  <Semantic.Image fluid src={this.state.selected.media.url} style={{ objectFit: this.state.selected.meta.objectFit || 'cover' }} />
                )}
                {this.state.selected.media && this.state.selected.media.mime.indexOf('video') === 0 && (
                  <Video width='100%' height='100%' loop={false} src={this.state.selected.media.url} style={{ objectFit: this.state.selected.meta.objectFit || 'cover' }} />
                  )}
                {this.state.selected.meta.qr && (
                <QRCode
                  value={this.state.selected.meta.qr.value}
                  className={this.state.selected.meta.qr.position}
                  level='H'
                  size={(360 * this.state.selected.meta.qr.size) / 100}
                  includeMargin={this.state.selected.meta.qr.padded}
                />)}
                {this.state.selected.meta.image
                && this.state.selected.meta.image.id
                && aditionalImageInfo
                && aditionalImageInfo.url
                && (
                  <img
                    className={'cover-image ' + this.state.selected.meta.image.position}
                    src={aditionalImageInfo.url}
                    style={{width: this.state.selected.meta.image.displaySize + '%', height: 'auto'}}
                    alt=''
                  />
                )}
              </AspectRatio>
            </div>
          )}

          {!this.state.selected && (
            <Semantic.Segment className='blankslate' basic>
              <Semantic.Header as='h4' icon style={{ margin: 0 }}>
                <Semantic.Icon name='info' />
                No item selected
                <Semantic.Header.Subheader>Select an existing item or create a new one to get started</Semantic.Header.Subheader>
              </Semantic.Header>
            </Semantic.Segment>
          )}
        </div>

        <Semantic.Modal size='fullscreen' open={this.state.mediaModal} onClose={e => this.setState({ mediaModal: false })} closeIcon style={{ marginTop: 0 }}>
          <Semantic.Modal.Content>
            <Media {...this.props} organization={{slug: this.props.data.variables.slug}} onSelect={this.mediaSelectHandler} mimeTypes={['video', 'image']} />
          </Semantic.Modal.Content>
        </Semantic.Modal>

        <Semantic.Modal size='fullscreen' open={this.state.imageModal} onClose={e => this.setState({ imageModal: false })} closeIcon style={{ marginTop: 0 }}>
          <Semantic.Modal.Content>
            <Media {...this.props} organization={{slug: this.props.data.variables.slug}} onSelect={this.imageSelectHandler} mimeTypes={['image']} />
          </Semantic.Modal.Content>
        </Semantic.Modal>

      </div>
    )
  }
}

class PlaylistEdit extends Component {
  render() {
    if (this.props.data.me) {
      return (
        <Semantic.Container fluid className='PlaylistEdit'>
          <Editor {...this.props} />
        </Semantic.Container>
      )
    } else {
      return (
        <Semantic.Container className='PlaylistEdit loading'>
          <Semantic.Segment>
            <div className='blankslate'>
              <Semantic.Dimmer active inverted>
                <Semantic.Loader inverted>Loading playlist #{this.props.match.params.playlist.slice(-7)}</Semantic.Loader>
              </Semantic.Dimmer>
              <Semantic.Image src={shortParagraph} width='100%' height='66' />
            </div>
          </Semantic.Segment>
        </Semantic.Container>
      )
    }
  }
}

// with data component
const PlaylistEditWithData = compose(
  graphql(gql`query Playlist($slug: Slug!, $playlist: ID!) {
    me {
      id
      email
      name
      organization(slug: $slug) {
        id
        slug
        name
        medias {
          edges {
            node {
              id
              url
              name
            }
          }
        }
        devices {
          id
          name
          modules
        }
        playlist (id: $playlist) {
          id
          name
          meta
          created_at
          items {
            id
            name
            duration
            order
            media {
              id
              url
              thumbnail_url
              name
              width
              height
              size
              duration
              mime
              created_at
            }
            meta
            created_at
          }
        }
      }
    }
  }`, {
    options: ownProps => {
      return {
        notifyOnNetworkStatusChange: true,
        variables: {
          slug: ownProps.match.params.organization,
          playlist: ownProps.match.params.playlist
        }
      }
    }
  }),
  graphql(gql`mutation UpdatePlaylist($input: UpdatePlaylistInput!) {
    update_playlist: update_playlist(input: $input) {
      id
    }
  }`, {
    name: 'update_playlist',
    options: ownProps => {
      return {
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  graphql(gql`mutation DeletePlaylist($input: DeletePlaylistInput!) {
    delete_playlist: delete_playlist(input: $input) {
      id
    }
  }`, {
    name: 'delete_playlist',
    options: ownProps => {
      return {
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  graphql(gql`mutation CreatePlaylistItem($input: CreatePlaylistItemInput!) {
    create_playlist_item: create_playlist_item(input: $input) {
      id
    }
  }`, {
    name: 'create_playlist_item',
    options: ownProps => {
      return {
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  graphql(gql`mutation UpdatePlaylistItem($input: UpdatePlaylistItemInput!) {
    update_playlist_item: update_playlist_item(input: $input) {
      id
    }
  }`, {
    name: 'update_playlist_item',
    options: ownProps => {
      return {
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  graphql(gql`mutation DeletePlaylistItem($input: DeletePlaylistItemInput!) {
    delete_playlist_item: delete_playlist_item(input: $input) {
      id
    }
  }`, {
    name: 'delete_playlist_item',
    options: ownProps => {
      return {
        notifyOnNetworkStatusChange: true
      }
    }
  })
)(PlaylistEdit)

export default PlaylistEditWithData
