import React, { useState, useEffect } from 'react'

// graphql
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

// map
import { LatLngBounds } from 'leaflet'
import { Map, Popup, TileLayer } from 'react-leaflet'
import EnhancedMarker from 'react-leaflet-enhanced-marker'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'leaflet-control-geocoder'

// semantic-ui
import { Header, Icon, Button } from 'semantic-ui-react'

// styles
import './index.css'

const UPDATE_LOCATION = gql`
mutation UpdateLocation($input: UpdateLocationInput!) {
  update_location: update_location(input: $input) {
    id
  }
}`

function LocationMap (props) {
  let center = [45.463611, 9.188333]

  let [locations, setLocations] = useState([])
  let [markers, setMarkers] = useState([])
  let [bounds, setBounds] = useState(null)

  // listen locations changes
  useEffect(() => {
    let locations = []
    if (props.location) {
      locations = [props.location]
    } else if (props.locations) {
      locations = props.locations
    }
    setLocations([...locations])

    let markers = locations.filter(location => {
      return !!location.coordinate
    }).map(location => ({
      id: location.id,
      name: location.name,
      description: `${location.devices.length} devices`,
      draggable: false,
      coordinate: [
        location.coordinate.latitude,
        location.coordinate.longitude
      ]
    }))
    if (markers.length) {
      let coordinates = markers.map(({ coordinate }) => coordinate)
      let bounds = new LatLngBounds(coordinates)
      setMarkers(markers)
      setBounds(bounds)
    } else if (props.location) {
      // set default location
      setMarkers([{
        id: props.location.id,
        name: props.location.name,
        description: `${props.location.devices.length} devices`,
        draggable: false,
        coordinate: [45.4642, 9.1900]
      }])
      setBounds(undefined)
    } else {
      setMarkers([])
      setBounds(undefined)
    }
  }, [props])

  // mutations
  const [updateLocation] = useMutation(UPDATE_LOCATION)

  return (
    <div className='Map'>
      <Map
        center={center}
        bounds={bounds}
        boundsOptions={{ padding: [20, 20] }}
        zoom={7}
        whenReady={map => {
          // console.log('map is ready', map)
        }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | <a href="https://munogu.com">Munogu</a>'
          url='https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png'
        />
        {markers.length > 0 && (
          <MarkerClusterGroup>
            {markers.map(marker => (
              <EnhancedMarker
                key={marker.id}
                draggable={!!marker.draggable}
                onDragend={e => {
                  let {lat: latitude, lng: longitude} = e.target.getLatLng()
                  let location = locations.find(location => {
                    return location.id === marker.id
                  })
                  if (location) {
                    location.coordinate = {
                      latitude,
                      longitude
                    }
                    // setLocations([...locations])
                    updateLocation({
                      variables: {
                        input: {
                          id: location.id,
                          coordinate: location.coordinate
                        }
                      }
                    })
                  }
                }}
                icon={
                  <div className='LocationMarkerIcon'>
                    <Icon size='big' name='map marker alternate' />
                  </div>
                }
                position={marker.coordinate}
              >
                <Popup>
                  <Header>
                    <Header.Content>
                      {marker.name}
                      <Header.Subheader>{marker.description}</Header.Subheader>
                    </Header.Content>
                  </Header>
                  {marker.draggable ? (
                    <Button basic primary size='small' onClick={e => {
                      marker.draggable = false
                      setMarkers([...markers])
                    }}>Lock</Button>
                  ) : (
                    <Button basic primary size='small' onClick={e => {
                      marker.draggable = true
                      setMarkers([...markers])
                    }}>Set draggable</Button>
                  )}
                </Popup>
              </EnhancedMarker>
            ))}
          </MarkerClusterGroup>
        )}
      </Map>
    </div>
  )
}

export default LocationMap
