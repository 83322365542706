import React, { useEffect, useState, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

// graphql
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

// delete user
const DELETE = gql`mutation delete_user (
  $id: String!
) {
  delete_user (input: {
    id: $id
  }) {
    id
  }
}`
// create new user
const CREATE = gql`mutation createuser (
  $username: String,
  $email: String!,
  $name: String!,
  $password: String,
  $role: String!,
  $organization_ids: [ID]
) {
  create_user (input: {
    username: $username,
    email: $email,
    name: $name,
    password: $password,
    role: $role,
    organization_ids: $organization_ids
  }) {
    id
  }
}`
// update user
const UPDATE = gql`mutation update_user (
  $id: ID!
  $username: String,
  $email: String,
  $name: String,
  $password: String,
  $role: String,
  $organization_ids: [ID]
) {
  update_user (input: {
    id: $id,
    username: $username,
    email: $email,
    name: $name,
    password: $password,
    role: $role,
    organization_ids: $organization_ids
  }) {
    id
  }
}`

// semantic-ui
import {
  Container,
  Segment,
  Dimmer,
  Loader,
  Image,
  Menu,
  Header,
  Form,
  Table,
  Button,
  Dropdown,
  Icon,
  Message
} from 'semantic-ui-react'

// images
import shortParagraph from '../../../../../../images/blankslate/short-paragraph.png'

// components
import { NoMatch } from '../../../../../App/components/Error'

// styles
import './index.css'

function Upsert (props) {
  // user as a state
  const [user, setUser] = useState()
  // user that is edited as a state
  const [thisUser, setThisUser] = useState({ name: '', username: '', email: '', role: 'viewer', meta: {}, organizations: []})
  // cancel error popup on any change
  useEffect(() => {
    setFail(false)
  }, [thisUser])
  // path as a state
  const [path, setPath] = useState()
  // mode -> view, edit & create
  const [mode, setMode] = useState('view')
  // toggle password fields
  const [passFields, setPassFields] = useState(false)
  // handle toggle password fields
  const handleTogglePasswordFields = () => {
    let currSet = passFields
    setPassFields(!currSet)
  }
  // input type toggle -> password/text
  const [inputType, setInputType] = useState('password')
  const [inputTypeIcon, setInputTypeIcon] = useState('eye slash')
  // handle toggle input types
  const handleToggleInputType = () => {
    // toggle type and eye icon
    if (inputType === 'password') {
      setInputType('text')
      setInputTypeIcon('eye')
    } else {
      setInputType('password')
      setInputTypeIcon('eye slash')
    }
  }
  // edit/create password
  const [password, setPassword] = useState('')
  const [passWarning, setPassWarning] = useState(false)
  const [passSeted, setPassSeted] = useState(false)
  // password input field
  const handlePasswordChange = value => {
    value.length < 8 && !passWarning && setPassWarning(true)
    value.length >= 8 && passWarning && setPassWarning(false)
    setPassword(value)
    setPassSeted(false)
  }
  // password repeat field
  const [doublePassWarning, setDoublePassWarning] = useState(true)
  const handleCheckDoublePassword = value => {
    setDoublePassWarning(value !== password)
  }
  // reset new password fields to default fn
  const resetPassFields = () => {
    setPassFields(false)
    setInputType('password')
    setInputTypeIcon('eye slash')
    setPassWarning(false)
    setDoublePassWarning(true)
  }
  // cancel password change
  const handleCancelPassChange = () => {
    resetPassFields()
    setPassword('')
    setThisUser({ ...thisUser, password: '' })
    setFail(false)
  }
  // confirm password change
  // password confirmed -> passSeted
  const handleConfirmPassword = () => {
    if (!passWarning && !doublePassWarning) {
      resetPassFields(true)
      setPassSeted(true)
      setThisUser({ ...thisUser, password })
      setFail(false)
    }
  }
  // create new user
  const [create_user, { error: mutationError }] = useMutation(CREATE)
  useEffect(() => {
    if (mutationError) {
      setFail(true)
      setFailMessage(mutationError.message.toString())
    }
  }, [mutationError])
  // update/edit user
  const [update_user, { error: editError }] = useMutation(UPDATE)
  useEffect(() => {
    if (editError) {
      setFail(true)
      setFailMessage(editError.message.toString())
    }
  }, [editError])
  // delete user
  const [delete_user, { error: deleteError }] = useMutation(DELETE)
  useEffect(() => {
    if (deleteError) {
      setFail(true)
      setFailMessage(deleteError.message.toString())
    }
  }, [deleteError])
  const handleDeleteUser = async () => {
    let { id } = thisUser
    let urSure = confirm('This action will delete this user!\n\rAre You sure?')
    if (urSure) {
      await delete_user({ variables: { id } }).then(resp => {
        if (resp && resp.data) {
          setSuccess(true)
          setThisUser(false)
          props.history.push(`/orgs/${props.orgSlug}/users`, { created: true, successText: 'User successfully deleted!' })
        }
      })
    }
  }
  // successfully edited/created
  let [success, setSuccess] = useState(false)
  // failed updating/editing
  let [fail, setFail] = useState(false)
  let [failMessage, setFailMessage] = useState('')
  // auto hide success message
  let autoHideSuccessTimeout
  useEffect(() => {
    if (success) {
      clearTimeout(autoHideSuccessTimeout)
      autoHideSuccessTimeout = setTimeout(() => setSuccess(false), 5 * 1000)
    }
  }, [success])
  // auto hide fail message
  let autoHideFailTimeout
  useEffect(() => {
    if (fail) {
      clearTimeout(autoHideFailTimeout)
      autoHideFailTimeout = setTimeout(() => setFail(false), 5 * 1000)
    }
  }, [fail])

  // add organization to list
  const handleOrgChange = (e, target) => {
    let organizations = target.value.map(id => {
      let org = user.organizations.find(org => org.id === id)
      return org
    })
    if (mode !== 'view') setThisUser({ ...thisUser, organizations })
  }

  useEffect(() => {
    // set user and path on have user props
    if (props.me && props.path) {
      setUser(props.me)
      setPath(props.path)
      setPassSeted(false)
      // create mode
      if (props.path === 'create') {
        if (props.me.role !== 'viewer') {
          setMode('create')
          setPassFields(true)
          setThisUser({ id: '', name: '', email: '', username: '', role: 'viewer', meta: {}, organizations: [] })
        }
      } else {
        // edit / view mode
        setMode(props.me.role === 'admin' ? 'edit' : 'view')
        // set passFields
        setPassFields(false)
        // set 'thisUser'
        if (props.path === props.me.id) {
          // editing user is the logged in user
          let { id, name, email, username, role, meta } = props.me
          let userData = { id, name, email, username, role, meta, organizations: [] }
          // filter out users that belongs to orgs
          props.me.organizations.forEach((org, i) => {
            let { id, name, locations } = org
            userData.organizations[i] = { id, name, locations }
          })
          setThisUser({ ...userData })
        } else {
          // find in the list of users
          let found = false
          props.me.organizations.forEach(org => {
            org.users.forEach(us => {
              if (us.id === props.path) {
                found = us
              }
            })
          })
          if (found) setThisUser({ ...found })
        }
      }
    }
  }, [props])


  // render
  if (!thisUser || (!thisUser.id && path !== 'create')) return (
    <NoMatch />
  )

  return (
    <Container className='UserUpsert'>
      {(!path || !user) && (
        <Segment>
          <div className='blankslate'>
            <Dimmer active inverted>
              <Loader inverted>Loading profile ...</Loader>
            </Dimmer>
            <Image src={shortParagraph} width='100%' height='66' />
          </div>
        </Segment>
      )}

      {path && user && (
        <Fragment>
          <Header as='h2'>
            <Icon name='user' />
            {thisUser.created_at ? (
              <Header.Content>
                {thisUser.name}
                <Header.Subheader>{thisUser.username} - {thisUser.email}</Header.Subheader>
              </Header.Content>
            ) : (
              <Header.Content>
                {thisUser.name || 'New User'}
                <Header.Subheader>Creating a new user</Header.Subheader>
              </Header.Content>
            )}
          </Header>

          <Message
            success
            onDismiss={e => setSuccess(false)}
            visible={success ? true : undefined}
            hidden={success ? undefined : true}
          >
            <Message.Header>Success!</Message.Header>
            <p>
              {mode === 'edit' ? 'Changes successfully saved!' : 'User successfully created!'}
            </p>
          </Message>
          <Message
            negative
            onDismiss={e => setFail(false)}
            visible={fail ? true : undefined}
            hidden={fail ? undefined : true}
          >
            <Message.Header>Error!</Message.Header>
            <p>
              {failMessage}
            </p>
          </Message>

          <Menu attached='top'>
            <Menu.Item as={NavLink} to={`/orgs/${props.orgSlug}/users`} exact>
              <Icon name='arrow left' />
              Turn back to users list
            </Menu.Item>
            {(user.role === 'admin' || (user.role === 'user' && (!thisUser.meta || !thisUser.meta.last_login))) && (
            <Menu.Menu position='right'>
              <Menu.Item onClick={handleDeleteUser} icon>
                <Icon name='trash' />
              </Menu.Item>
            </Menu.Menu>
            )}
          </Menu>

          <Segment attached='bottom'>
            <Form
              onSubmit={async e => {
                e.preventDefault()
                const { username, email, name, role } = thisUser
                let organization_ids = thisUser.organizations.map(org => org.id)
                let variables = {
                  username,
                  email,
                  name,
                  role,
                  organization_ids
                }
                if (mode === 'create') {
                  if (password && passSeted) {
                    variables.password = password
                    await create_user({ variables })

                    props.history.push(`/orgs/${props.orgSlug}/users`, { created: true, successText: 'User successfully created!' })
                  } else {
                    setFail(true)
                    setFailMessage('No password confirmed!')
                  }
                }
                if (mode === 'edit') {
                  variables.id = thisUser.id
                  if (password && passSeted) variables.password = password
                  if (password && !passSeted) {
                    setFail(true)
                    setFailMessage('Password changed but not confirmed!')
                  } else {
                    await update_user({ variables }).then(resp => {
                      if (resp && resp.data) {
                        setSuccess(true)
                      }
                    })
                  }
                }
              }}
            >
              <Form.Group widths='equal'>
                <Form.Field>
                  <Form.Input
                    name='name'
                    label='Name:'
                    type='text'
                    value={thisUser.name}
                    required={mode !== 'view'}
                    onChange={(e, target) => {
                      if (mode !== 'view') setThisUser({ ...thisUser, name: target.value })
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name='email'
                    label='Email:'
                    type='email'
                    value={thisUser.email}
                    required={mode !== 'view'}
                    onChange={(e, target) => {
                      if (mode !== 'view') setThisUser({ ...thisUser, email: target.value })
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field>
                  <Form.Input
                    autoComplete='no'
                    name='username'
                    label='User Name:'
                    type='text'
                    value={thisUser.username || ''}
                    required={mode !== 'view'}
                    onChange={(e, target) => {
                      if (mode !== 'view') setThisUser({ ...thisUser, username: target.value })
                    }}
                  />
                </Form.Field>
                <Form.Field required={mode !== 'view'}>
                  <label>Role:</label>
                  <Dropdown
                    required={mode !== 'view'}
                    selection
                    value={thisUser.role || 'viewer'}
                    onChange={(e, target) => {
                      if (mode !== 'view') setThisUser({ ...thisUser, role: target.value })
                    }}
                    options={
                      user.role === 'admin' ? [{key: 'admin', text: 'admin', value: 'admin'}, {key: 'user', text: 'user', value: 'user'}, {key: 'viewer', text: 'viewer', value: 'viewer'}]
                      : user.role === 'user' ? [{key: 'admin', text: 'admin', value: 'admin', disabled: true}, {key: 'user', text: 'user', value: 'user'}, {key: 'viewer', text: 'viewer', value: 'viewer'}]
                      : [{key: 'admin', text: 'admin', value: 'admin', disabled: true}, {key: 'user', text: 'user', value: 'user', disabled: true}, {key: 'viewer', text: 'viewer', value: 'viewer'}]
                    }
                  />
                </Form.Field>
              </Form.Group>

              {/* show organization select only for non-admins */}
              {thisUser.role !== 'admin' && (
                <Form.Field required={true}>
                  <label>Organizations</label>
                  <Dropdown
                    fluid
                    multiple
                    selection
                    value={thisUser.organizations.map(org => org.id)}
                    options={props.me.organizations.map(org => {
                      return {
                        key: org.id,
                        text: org.name,
                        value: org.id,
                        image: { avatar: true, src: `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${org.name}` },
                      }
                    })}
                    onChange={handleOrgChange}
                  />
                  {mode !== 'view' && (<span>Specify organization(s) that this user will belong to</span>)}
                </Form.Field>
              )}

              {mode !== 'view' && (<Form.Field>
                <label>Password</label>
                {/* switch between password field with change psw btn and change password fields */}
                {/* change password btn */}
                { (user.role === 'admin' || (user.role === 'user' && mode === 'create')) && !passFields && (
                  <Button className='floated-btn' onClick={handleTogglePasswordFields}>Click here to update password</Button>
                )}
              </Form.Field>)}
              {(mode !== 'view' && <Form.Field>
                { passFields && mode !== 'view' && (
                  <Table celled collapsing>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <Form.Input
                            name='password'
                            autoComplete='new-password'
                            label='New Password:'
                            type={inputType}
                            placeholder=''
                            required
                            value={password || ''}
                            onChange={(e, target) => {
                              handlePasswordChange(target.value)
                            }}
                          />
                          <strong
                            style={{
                              color: 'red',
                              visibility: passWarning ? 'visible' : 'hidden'
                            }}
                          >
                            * Password needs to have at least 8 characters!
                          </strong>
                          <br/>
                          <Form.Input
                            name='repeat'
                            label='Repeat Password:'
                            type={inputType}
                            placeholder=''
                            required
                            onChange={(e, target) => {
                              handleCheckDoublePassword(target.value)
                            }}
                          />
                          <strong
                            style={{
                              color: 'red',
                              visibility: doublePassWarning ? 'visible' : 'hidden'
                            }}
                          >
                            * Repeat must match new password!
                          </strong>
                        </Table.Cell>
                        <Table.Cell>
                          <Icon
                            name={inputTypeIcon}
                            size='big'
                            style={{margin: '0 1rem', cursor: 'pointer'}}
                            onClick={handleToggleInputType}
                          />
                          { mode === 'edit' && (
                            <Button className='floated-btn' onClick={handleCancelPassChange}>Cancel</Button>
                          )}
                          <Button className='floated-btn' color='blue' onClick={handleConfirmPassword}>Confirm</Button>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                )}
              </Form.Field>)}
              {mode !== 'view' && user.role !== 'viewer' && (
              <Form.Field>
                <Button primary type='submit'>
                  {mode !== 'create' ? 'Save Changes' : 'Create User'}
                </Button>
              </Form.Field>
              )}
            </Form>
          </Segment>
        </Fragment>
      )}
    </Container>
  )
}

export default Upsert
