import React, { Component } from 'react'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

// styles
import './index.css'

class Video extends Component {
  constructor (args) {
    super(args)
    this.state = {
      paused: true
    }
    // video player element reference
    this.video = null
  }
  componentDidMount () {
    this.setState({ paused: this.video.paused })
  }
  componentDidUpdate (prevProps, prevState) {
    if (!prevState.paused) this.setState({ paused: true })
  }
  render () {
    return (
      <div className='Video' style={{
        width: this.props.width || 'auto',
        height: this.props.height || 'auto'
      }}>
        <video
          ref={elem => this.video = elem}
          onPlay={e => {
            e.preventDefault()
            this.setState({ paused: false })
          }}
          onPause={e => {
            e.preventDefault()
            this.setState({ paused: true })
          }}
          onEnded={e => {
            e.preventDefault()
            this.setState({ paused: true })
          }}
          {...this.props}
        />
        <div className='controls'>
          <button type='button' onClick={e => {
            e.preventDefault()
            this.video.paused ? this.video.play() : this.video.pause()
          }}>
            {this.state.paused ? (
              <Semantic.Icon name='play' />
            ) : (
              <Semantic.Icon name='pause' />
            )}
          </button>
        </div>
      </div>
    )
  }
}

export default Video
