import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import Konva from 'konva'
import { Stage, Layer, Circle, Line } from 'react-konva'

// graphql
import gql from 'graphql-tag'
import { useQuery, useSubscription } from '@apollo/react-hooks'

const DETECTION = gql`
subscription($device: ID!){
  device_mmwave_frame(device: $device)
}
`

function MmWaveMap(props) {

  // starting dots
  const [dots, setDots] = useState([])

  // graph lines
  const [xLines, setXlines] = useState([])
  const [yLines, setYlines] = useState([])

  // converting x & y to px coords
  const [size, setSize] = useState(0)
  const convertXcoord = x => parseInt((x + 5) * (size / 10))
  const convertYcoord = y => parseInt((10 - y) * (size / 10))
  // convert size of human
  const convertDotSize = partsOfSquare => parseInt((size / 10) / (partsOfSquare * 2))

  useEffect(() => {
    // draw table
    if (props && props.size) {
      let xMax = props.size
      let yMax = props.size
      let step = props.size / 10
      // x lines - (horizontal lines)
      let xArr = []
      let i = step
      do {
        let stepX = parseInt(i)
        xArr.push({
          x: 0,
          y: stepX,
          points: [0, 0, xMax, 0]
        })
        i += step
      } while (i <= props.size - step)
      setXlines(xArr)
      // y lines - (vertical lines)
      let yArr = []
      let j = step
      do {
        let stepY = parseInt(j)
        yArr.push({
          x: stepY,
          y: 0,
          points: [0, 0, 0, yMax]
        })
        j += step
      } while (j <= props.size - step)
      setYlines(yArr)
      // setting coordinates for dots
      setSize(props.size)
    }
  }, [props])

  // subscribe to mmwave trackers
  useSubscription(DETECTION, {
    variables: {device: props.device},
    onSubscriptionData: (subsData) => {
      let data = subsData.subscriptionData.data.device_mmwave_frame.frame.targets
      let dotsArr = []
      data.forEach(el => {
        dotsArr.push({
          posX: el.posX,
          posY: el.posY
        })
      })
      props.getFrames(subsData.subscriptionData.data.device_mmwave_frame.frame.frame)
      setDots(dotsArr)
    }
  })

  return (
    <Fragment>
      <Stage width={props.size} height={props.size}>
        <Layer>
          {/* Dots */}
          { dots.map((dot, i) => (
            <Circle
              key={i}
              x={convertXcoord(dot.posX)}
              y={convertYcoord(dot.posY)}
              radius={convertDotSize(3)}
              fill='red'
            />)) }
          {/* X lines */}
          {xLines.map((l, i) => (
            <Line
              key={i}
              x={l.x}
              y={l.y}
              points={l.points}
              stroke='black'
            />
          ))}
          {/* Y lines */}
          {yLines.map((l, i) => (
            <Line
              key={i}
              x={l.x}
              y={l.y}
              points={l.points}
              stroke='black'
            />
          ))}
          </Layer>
      </Stage>
    </Fragment>
  )
}

export default MmWaveMap
