import React, { Fragment, useState } from 'react'
import { orgRoute } from '../../../../utils'
import { NavLink, Link } from 'react-router-dom'
import { Moment } from '../../../../moment'
import _ from 'lodash'

// map
import LocationMap from '../../components/LocationMap'

// devices thumbnails
import MediaThumb from '../../components/MediaThumb'

// graphql
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/react-hooks'

//images
import femaleAvatar from '../../../../images/avatars/female.svg'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

const DEVICE_ONLINE_SUBSCRIPTION = gql`
subscription DeviceOnline($organization: ID) {
  device_online(organization: $organization) {
    online
    device_id
  }
}`

const DEVICE_NOW_PLAYING_SUBSCRIPTION = gql`
subscription DeviceNowPlaying($organization: ID!) {
  device_now_playing(organization: $organization)
}`

const FeedEvent = props => {
  const text = () => {
    let organization = props.data.me.organization
    let activity = props.activity
    let template
    switch (activity.type) {
      case 'online.device':
        template = (
          <span>
            Device &nbsp;
            <Semantic.Feed.User
              as={NavLink}
              to={orgRoute(organization, ['devices', activity.device.id])}
            >
              {activity.device.name || '#' + activity.device.id.slice(-7)}
            </Semantic.Feed.User>
            &nbsp;is online.
          </span>
        )
        break
      case 'offline.device':
        template = (
          <span>
            Device &nbsp;
            <Semantic.Feed.User
              as={NavLink}
              to={orgRoute(organization, ['devices', activity.device.id])}
            >
              {activity.device.name || '#' + activity.device.id.slice(-7)}
            </Semantic.Feed.User>
            &nbsp;got disconnected.
          </span>
        )
        break
      case 'create.media':
        template = (
          <span>
            <Semantic.Feed.User>{_.capitalize(activity.user.name)}</Semantic.Feed.User>
            &nbsp;created a new media item&nbsp;
            <NavLink to={orgRoute(organization, ['medias'])}>{activity.media.name}</NavLink>.
          </span>
        )
        break
      case 'create.playlist':
        template = (
          <span>
            <Semantic.Feed.User>{_.capitalize(activity.user.name)}</Semantic.Feed.User>
            &nbsp;created a new playlist&nbsp;
            <NavLink to={orgRoute(organization, ['playlists', activity.playlist.id])}>
              {activity.playlist.name}
            </NavLink>
            .
          </span>
        )
        break
      case 'create.schedule':
        template = (
          <span>
            <Semantic.Feed.User>{_.capitalize(activity.user.name)}</Semantic.Feed.User>
            &nbsp;created a new schedule&nbsp;
            <NavLink to={orgRoute(organization, ['schedules', activity.schedule.id])}>
              {activity.schedule.name}
            </NavLink>
            .
          </span>
        )
        break
      case 'create.rule':
        template = (
          <span>
            <Semantic.Feed.User>{_.capitalize(activity.user.name)}</Semantic.Feed.User>
            &nbsp;created a new rule.
          </span>
        )
        break
      case 'create.location':
        template = (
          <span>
            <Semantic.Feed.User>{_.capitalize(activity.user.name)}</Semantic.Feed.User>
            &nbsp;created a new location.&nbsp;
            {activity.location && (
              <NavLink to={orgRoute(organization, ['locations', activity.location.slug])}>
                {activity.location.name}
              </NavLink>
            )}
          </span>
        )
        break
      default:
        template = `Activity: ${activity.type} ${activity.user ? 'by ' + activity.user.name : ''}`
    }
    return template
  }
  let activity = props.activity

  return (
    <Semantic.Feed.Event>
      <Semantic.Feed.Label>
        {activity.user && (
          <Semantic.Image src={'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=' + activity.user.name} />
        )}
        {activity.device && (
          <Semantic.Image
            avatar
            src={platforms[(activity.device.meta.platform || 'unknown')]}
          />
        )}
      </Semantic.Feed.Label>
      <Semantic.Feed.Content>
        <Semantic.Feed.Date>
          <Moment locale={'en-US'} fromNow>
            {activity.created_at}
          </Moment>{' '}
        </Semantic.Feed.Date>
        <Semantic.Feed.Summary>{text()}</Semantic.Feed.Summary>
        {activity.type === 'create.media' && (
          <Semantic.Feed.Extra images>
            <Link to={orgRoute(props.data.me.organization, ['medias'])}>
              <Semantic.Image src={activity.media.thumbnail_url} size="mini" bordered rounded />
            </Link>
          </Semantic.Feed.Extra>
        )}
      </Semantic.Feed.Content>
    </Semantic.Feed.Event>
  )
}

const FeedEventI18n = FeedEvent

const Overview = props => {
  // stats
  const [onlineSub, setOnlineSub] = useState()
  const [nowPlayingSub, setNowPlayingSub] = useState()

  const diskUsageStat = val => {
    let units = [
      'bytes',
      'kilobytes',
      'megabytes',
      'gigabytes',
      'terabytes',
      'petabytes',
      'exabytes',
      'zettabytes',
      'yottabytes'
    ]
    let unit = units[0]

    if (val > 0) {
      let e = Math.floor(Math.log(val) / Math.log(1000))
      e = Math.min(e, units.length - 1)
      unit = units[e]
      val = val / Math.pow(1000, e)
    }

    return (
      <Semantic.Statistic>
        <Semantic.Statistic.Value>
          {val.toFixed(2)}
        </Semantic.Statistic.Value>
        <Semantic.Statistic.Label>{unit} used</Semantic.Statistic.Label>
      </Semantic.Statistic>
    )
  }
  let devices = props.organization.devices
  let medias = props.organization.medias.edges

  // device online update subscription
  useSubscription(DEVICE_ONLINE_SUBSCRIPTION, {
    variables: { organization: props.data.me.organization.id },
    onSubscriptionData: ({ subscriptionData }) => {
      let subOnline = subscriptionData.data.device_online
      setOnlineSub(subOnline)
    }
  })

  // device now playing subscription
  useSubscription(DEVICE_NOW_PLAYING_SUBSCRIPTION, {
    variables: { organization: props.data.me.organization.id },
    onSubscriptionData: ({ subscriptionData }) => {
      let nowPlaying = subscriptionData.data.device_now_playing
      setNowPlayingSub(nowPlaying)
    }
  })

  return (
    <Semantic.Container className="Overview">
      <Semantic.Segment>
        <Semantic.Statistic.Group widths="four" className="stackable">
          <Semantic.Statistic>
            <Semantic.Statistic.Value>{props.organization.locations.length}</Semantic.Statistic.Value>
            <Semantic.Statistic.Label>Locations</Semantic.Statistic.Label>
          </Semantic.Statistic>
          {diskUsageStat(props.organization.media_size)}
          <Semantic.Statistic>
            <Semantic.Statistic.Value>
              <Semantic.Icon name="computer" />
              &nbsp;
              {props.organization.locations.length
                ? props.organization.locations
                    .map(location => {
                      return location.devices.length
                    })
                    .reduce((accumulator, currentValue) => {
                      return accumulator + currentValue
                    })
                : 0}
            </Semantic.Statistic.Value>
            <Semantic.Statistic.Label>Devices</Semantic.Statistic.Label>
          </Semantic.Statistic>
          <Semantic.Statistic>
            <Semantic.Statistic.Value>
              <Semantic.Image src={femaleAvatar} className="circular inline" />
              &nbsp;
              {props.organization.users.length}
            </Semantic.Statistic.Value>
            <Semantic.Statistic.Label>Team Members</Semantic.Statistic.Label>
          </Semantic.Statistic>
        </Semantic.Statistic.Group>
      </Semantic.Segment>

      <Fragment>
        <Semantic.Header attached='top'>
          Location Map
        </Semantic.Header>
        <Semantic.Segment attached='bottom'>
          <LocationMap locations={props.organization.locations} />
        </Semantic.Segment>
      </Fragment>

      <Semantic.Header attached='top' as='h4'>
        Device list
      </Semantic.Header>
      {devices && devices.length > 0 ? (
        <Semantic.Segment attached>
          <Semantic.Grid className='media-thumbs' padded>
            {devices.map(device => (
              <Semantic.Grid.Column  key={device.id} mobile={16} tablet={6} computer={5}>
                <h4>{device.name}</h4>
                <MediaThumb
                  subscription_online={(onlineSub && onlineSub.device_id === device.id) ? onlineSub.online : null}
                  subscription_now_playing={(nowPlayingSub && nowPlayingSub.device_id === device.id) ? nowPlayingSub : null}
                  modules={device.modules}
                  online={device.online}
                  medias={medias}
                />
              </Semantic.Grid.Column>
            ))}
          </Semantic.Grid>
        </Semantic.Segment>
      ) : (
        <Semantic.Segment attached className="blankslate">
          <Semantic.Header as="h4" icon style={{ margin: 0 }}>
            <Semantic.Icon name="x" />
            No devices found
          </Semantic.Header>
        </Semantic.Segment>
      ) }

      <Semantic.Header as="h4" attached="top">
        Activity feed
      </Semantic.Header>
      {props.data.me.organization.latest_activity.length > 0 && (
        <Semantic.Segment attached>
          <Semantic.Feed>
            {props.data.me.organization.latest_activity.map(activity => (
              <FeedEventI18n
                data={props.data}
                activity={activity}
                key={'activity-' + activity.id}
              />
            ))}
          </Semantic.Feed>
        </Semantic.Segment>
      )}
      {props.data.me.organization.latest_activity.length === 0 && (
        <Semantic.Segment attached className="blankslate">
          <Semantic.Header as="h4" icon style={{ margin: 0 }}>
            <Semantic.Icon name="x" />
            No activity found
          </Semantic.Header>
        </Semantic.Segment>
      )}
    </Semantic.Container>
  )
}

export default Overview
