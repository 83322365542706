import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { orgRoute } from '../../../../utils'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

// styles
import './index.css'

class Breadcrumb extends Component {
  render () {
    let sections = []
    let segments = this.props.location.pathname.split('/')
    if (this.props.organization) {
      sections.push((
        <Semantic.Breadcrumb.Divider key='divider-org' />
      ))
      sections.push((
        <Semantic.Breadcrumb.Section key='section-org' link as={Link} to={orgRoute(this.props.organization)}>{this.props.organization.name}</Semantic.Breadcrumb.Section>
      ))
    }
    if (this.props.organization && segments.length === 4) {
      sections.push((
        <Semantic.Breadcrumb.Divider key='divider-sub-org' />
      ))
      sections.push((
        <Semantic.Breadcrumb.Section key='section-sub-org' active>{segments[3]}</Semantic.Breadcrumb.Section>
      ))
    }
    if (this.props.organization && segments.length >= 5) {
      sections.push((
        <Semantic.Breadcrumb.Divider key='divider-sub-org-divider' />
      ))
      sections.push((
        <Semantic.Breadcrumb.Section key='section-sub-org' link as={Link} to={orgRoute(this.props.organization, segments[3])}>{segments[3]}</Semantic.Breadcrumb.Section>
      ))
      sections.push((
        <Semantic.Breadcrumb.Divider key='divider-sub-id' />
      ))
      sections.push((
        <Semantic.Breadcrumb.Section key='section-sub-id' active={segments.length <= 5} link={segments.length > 5} as={segments.length > 5 ? Link : undefined} to={segments.length > 5 ? orgRoute(this.props.organization, [segments[3], segments[4]]) : undefined}>
          {(segments[4].length === 36 && segments[4].match(/^[0-9A-F-]*$/i)) ? (
            `#${segments[4].slice(-7)}`
          ) : (
            `${segments[4].replace(/-/g, ' ')}`
          )}
        </Semantic.Breadcrumb.Section>
      ))
    }
    if (this.props.organization && segments.length === 7) {
      sections.push((
        <Semantic.Breadcrumb.Divider key='divider-sub1' />
      ))
      sections.push((
        <Semantic.Breadcrumb.Section key='divider-sub2' active>{segments[5]}</Semantic.Breadcrumb.Section>
      ))
      sections.push((
        <Semantic.Breadcrumb.Divider key='divider-sub3' />
      ))
      sections.push((
        <Semantic.Breadcrumb.Section key='divider-sub4' active>#{segments[6].slice(-7)}</Semantic.Breadcrumb.Section>
      ))
    }
    return (
      <div className='Breadcrumb'>
        <Semantic.Breadcrumb>
          <Semantic.Breadcrumb.Section link as={Link} to='/'>Home</Semantic.Breadcrumb.Section>
          {sections}
        </Semantic.Breadcrumb>
      </div>
    )
  }
}

export default withRouter(Breadcrumb)
