import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

// styles
import './styles.css'

// semantic-ui
import {
  Dropdown
} from 'semantic-ui-react'

// components
import ValueInput from './ValueInput'

const Rule = props => {
  const {
    ruleQuery,
    queryFields,
    position,
    updateRule
  } = props

  // headers and fields opts
  const headers = queryFields.groups.map(group => group.header)
  const fields = queryFields.groups.map(group => group.fields)
  const headersOpts = headers.map(h => {
    return {
      key: h.name,
      text: h.label,
      value: h.name
    }
  })

  const findOtherOptsBasedOnCurrHeader = headerVal => {
    let arrNmb
    queryFields.groups.forEach((qf, i) => {
      if (qf.header.name === headerVal) arrNmb = i
    })
    return {
      opts: fields[arrNmb],
      nmb: arrNmb
    }
  }

  // states
  const [currValues, setCurrValues] = useState({
    header:
      ruleQuery.field.split('.')[0]
      || headers[0].name,
    field:
      ruleQuery.field.split('.')[1]
      || fields[0][0].name,
    operator:
      ruleQuery.operator
      || fields[0][0].operators.map(o => { return {key: o.name, text: o.label, value: o.name} })[0],
    value: (ruleQuery && ruleQuery.value) || null
  })
  const [otherOpts, setOtherOpts] = useState(findOtherOptsBasedOnCurrHeader(currValues.header))

  useEffect(() => {
    // not rendering fix
    if (
      ruleQuery.field !== currValues.header + '.' + currValues.field
      || ruleQuery.operator !== currValues.operator
      || ruleQuery.value !== currValues.value
      ) {
        let headers = ruleQuery.field.split('.')
        // set fields opts
        let newOtherOpts = findOtherOptsBasedOnCurrHeader(headers[0])
        setOtherOpts(newOtherOpts)
        // set operator and value
        let { operator, value } = ruleQuery
        setCurrValues({
          header: headers[0],
          field: headers[1],
          operator,
          value
        })
    }
  }, [ruleQuery])

  const updateQuery = query => {
    return {
      query,
      position
    }
  }

  const valueExists = val => {
    return (val !== null && val !== 'undefined') ? val : null
  }

  // handlers
  // on change group manualy
  const handleChangeGroup = (e, { value }) => {
    // set fields opts
    let newOtherOpts = findOtherOptsBasedOnCurrHeader(value)
    setOtherOpts(newOtherOpts)

    let operatorData = newOtherOpts.opts[0].operators[0]
    let operator = operatorData.name
    let valueData = newOtherOpts.opts.find(opt => opt.name === newOtherOpts.opts[0].name).defaultValue
    // set parse numbers
    if (newOtherOpts.opts[0].inputType === 'number') valueData = parseInt(valueData)
    // if switching to multiple -> set value to empty array
    if (operatorData.multipleSelect) valueData = []
    // update
    let updateData = {
      header: value,
      field: newOtherOpts.opts[0].name,
      operator,
      value: valueData
    }
    // update header
    setCurrValues({ ...updateData })
    // update Rule
    updateRule(updateQuery({ ...updateData }))
  }

  // on manual field change
  const handleChangeField = (e, { value }) => {
    let fieldData = otherOpts.opts.find(o => o.name === value)
    let operatorData = fieldData.operators[0]
    let operator = operatorData.name
    let valueData = fieldData.defaultValue
    // set parse numbers
    if (fieldData.inputType === 'number') valueData = parseInt(valueData)
    // if switching to multiple -> set value to empty array
    if (operatorData.multipleSelect) valueData = []
    // update values
    setCurrValues({
      ...currValues,
      field: value,
      operator,
      value: valueData
    })
    // update Rule
    updateRule(updateQuery({
      header: currValues.header,
      field: value,
      operator,
      value: valueData
    }))
  }
  // on manual operator change
  const handleChangeOperator = (e, { value }) => {
    let opts = otherOpts.opts.find(o => o.name === currValues.field)
    let operatorData = opts.operators.find(o => o.name === value)
    let valueData = currValues.value
    // parse number
    if (opts.inputType === 'number') valueData = parseInt(valueData)
    // if switching to multiple -> set value to empty array
    if (operatorData.multipleSelect) valueData = []
    // if switching from multiple -> set value to default
    if (!operatorData.multipleSelect && Array.isArray(valueData)) valueData = opts.defaultValue
    // change operator value
    setCurrValues({
      ...currValues,
      operator: value,
      value: valueData
    })
    // update Rule
    updateRule(updateQuery({
      header: currValues.header,
      field: currValues.field,
      operator: value,
      value: valueData
    }))
  }
  // on change value
  const handleValueChange = (e, { value }) => {
    setCurrValues({
      ...currValues,
      value: value
    })
    // update Rule
    updateRule(updateQuery({
      header: currValues.header,
      field: currValues.field,
      operator: currValues.operator,
      value: value
    }))
  }

  return (
    <div className='rule-container'>
      {/* group select */}
      <Dropdown
        selection
        value={currValues.header}
        onChange={handleChangeGroup}
        options={headersOpts}
      />
      {/* field select */}
      <Dropdown
        selection
        value={currValues.field}
        onChange={handleChangeField}
        options={otherOpts.opts.map(opt => { return {key: opt.name, value: opt.name, text: opt.label} })}
      />
      {/* operator select */}
      <Dropdown
        selection
        value={currValues.operator}
        onChange={handleChangeOperator}
        options={
          otherOpts.opts.find(opt => opt.name === currValues.field) ?
          otherOpts.opts.find(opt => opt.name === currValues.field).operators.map(o => {
            return {
              key: o.name,
              value: o.name,
              text: o.label
            }
          })
          : null}
      />
      {/* value */}
      <ValueInput
        inputType={
          otherOpts.opts.find(opt => opt.name === currValues.field) ?
            otherOpts.opts.find(opt => opt.name === currValues.field).inputType
            : null
          }
        value={currValues.value}
        values={
          otherOpts.opts.find(opt => opt.name === currValues.field)
          && otherOpts.opts.find(opt => opt.name === currValues.field).values
          || null
        }
        minValue={
          valueExists(otherOpts.opts.find(opt => opt.name === currValues.field)
          && otherOpts.opts.find(opt => opt.name === currValues.field).minValue)
        }
        maxValue={
          valueExists(otherOpts.opts.find(opt => opt.name === currValues.field)
          && otherOpts.opts.find(opt => opt.name === currValues.field).maxValue)
        }
        defaultValue={
          otherOpts.opts.find(opt => opt.name === currValues.field)
          && otherOpts.opts.find(opt => opt.name === currValues.field).defaultValue
        }
        multiple={
          otherOpts.opts.find(opt => opt.name === currValues.field)
          && otherOpts.opts.find(opt => opt.name === currValues.field).operators.find(opt => opt.name === currValues.operator)
          && otherOpts.opts.find(opt => opt.name === currValues.field).operators.find(opt => opt.name === currValues.operator).multipleSelect
        }
        onChange={handleValueChange}
      />
    </div>
  )
}

export default Rule
