import React, { Component } from 'react'
import { Route, NavLink } from 'react-router-dom'
import _ from 'lodash'
import { orgRoute } from '../../utils'

// scenes
import Media from './scenes/Media'
import Schedule from './scenes/Schedule'
import ScheduleEdit from './scenes/Schedule/Edit'
import Playlist from './scenes/Playlist'
import PlaylistEdit from './scenes/Playlist/Edit'
import Location from './scenes/Location'
import Job from './scenes/Job'
import Users from './scenes/Users'
import Overview from './scenes/Overview'

// components
import Breadcrumb from './components/Breadcrumb'

// graphql
import { graphql } from 'react-apollo'
import { flowRight as compose } from 'lodash'
import gql from 'graphql-tag'

// images
import shortParagraph from '../../images/blankslate/short-paragraph.png'
import win32 from '../../images/platforms/win32.svg'
import unknown from '../../images/platforms/unknown.svg'
import linux from '../../images/platforms/linux.svg'
import darwin from '../../images/platforms/darwin.svg'

// semantic-ui
import * as Semantic from 'semantic-ui-react'

// styles
import './index.css'

// shortcuts
const platforms = { win32, unknown, linux, darwin }

class EnhancedMarkerIcon extends Component {
  render () {
    const markerStyle = {
      textAlign: 'center'
    }
    const iconStyle = {
      color: 'rgba(234, 32, 39, 1.0)',
      fontSize: '3rem'
    }
    return (
      <div style={markerStyle}>
        <Semantic.Icon size='big' name='map marker alternate' style={iconStyle} />
      </div>
    )
  }
}

class Organization extends Component {
  render () {
    if (!this.props.data.loading && this.props.data.me) {
      let user = this.props.data.me
      return (
        <div className="Organization">
          {this.props.menu && (
            <Semantic.Menu color="blue" stackable className="top" style={{ borderTop: 0 }}>
              <Semantic.Menu.Item as={NavLink} to={orgRoute(user.organization)} exact>
                <Semantic.Icon name="dashboard" />
                Overview
              </Semantic.Menu.Item>
              <Semantic.Menu.Item as={NavLink} to={orgRoute(user.organization, 'playlists')}>
                <Semantic.Icon name="ordered list" />
                Playlists
              </Semantic.Menu.Item>
              <Semantic.Menu.Item as={NavLink} to={orgRoute(user.organization, 'medias')}>
                <Semantic.Icon name="image" />
                Medias
              </Semantic.Menu.Item>
              <Semantic.Menu.Item disabled>
                <Semantic.Icon name="html5" />
                Templates
              </Semantic.Menu.Item>
              <Semantic.Menu.Item disabled>
                <Semantic.Icon name="calendar outline" />
                Schedules
              </Semantic.Menu.Item>
              <Semantic.Menu.Item as={NavLink} to={orgRoute(user.organization, 'devices')}>
                <Semantic.Icon name="computer" />
                Devices
              </Semantic.Menu.Item>
            </Semantic.Menu>
          )}

          <Breadcrumb organization={user.organization} />

          <Route
            exact
            path={this.props.match.path}
            render={() => (
              <Overview {...this.props} organization={user.organization} />
            )}
          />
          <Route path={this.props.match.path + '/templates'} component={Media} />
          <Route exact path={this.props.match.path + '/playlists'} component={Playlist} />
          <Route path={this.props.match.path + '/playlists/:playlist'} component={PlaylistEdit} />
          <Route exact path={this.props.match.path + '/schedules'} component={Schedule} />
          <Route path={this.props.match.path + '/schedules/:schedule'} component={ScheduleEdit} />

          <Route
            path={this.props.match.path + '/medias'}
            render={props => (
              <Media {...props} organization={user.organization} />
            )}
          />

          <Route
            path={this.props.match.path + '/locations'}
            render={props => (
              <Location {...props} organization={user.organization} />
            )}
          />

          <Route
            path={this.props.match.path + '/jobs'}
            render={props => (
              <Job {...props} organization={user.organization} />
            )}
          />

          <Route
            path={this.props.match.path + '/users'}
            render={props => (
              <Users {...props} organization={user.organization} />
            )}
          />
        </div>
      )
    } else {
      return (
        <Semantic.Container className="Organization">
          <Semantic.Segment>
            <div className="blankslate">
              <Semantic.Dimmer active inverted>
                <Semantic.Loader inverted>Loading organization</Semantic.Loader>
              </Semantic.Dimmer>

              <Semantic.Image src={shortParagraph} width="100%" height="66" />
            </div>
          </Semantic.Segment>
        </Semantic.Container>
      )
    }
  }
}

// with data component
const OrganizationWithData = compose(
  graphql(
    gql`
      query Organization($slug: Slug!) {
        me {
          id
          email
          name
          organization(slug: $slug) {
            id
            slug
            name
            media_size
            devices {
              id
              name
              online
              modules
              config
              location_id
            }
            medias {
              edges {
                node {
                  id
                  url
                  name
                  mime
                }
              }
            }
            latest_activity {
              id
              type
              user {
                id
                name
              }
              device {
                id
                name
                location {
                  id
                }
                meta
              }
              media {
                id
                name
                thumbnail_url
              }
              playlist {
                id
                name
              }
              location {
                id
                slug
                name
              }
              schedule {
                id
                name
              }
              meta
              created_at
            }
            users {
              id
            }
            locations {
              id
              name
              coordinate {
                latitude
                longitude
              }
              devices {
                id
                name
                online
                meta
                created_at
              }
            }
          }
        }
      }
    `,
    {
      options: ownProps => {
        return {
          notifyOnNetworkStatusChange: true,
          variables: {
            slug: ownProps.match.params.organization
          }
        }
      }
    }
  )
)(Organization)

export default OrganizationWithData
